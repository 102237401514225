<div [id]="data.code" class="neo-carousel shopyan-section" [class.build-mode]="buildMode">
  <div class="neo-section-layout neo-carousel-layout large">

    <div class="carousel-container">

      <ngx-slick-carousel [config]="slideConfig" *ngIf="displayCarousel">
        <ng-container *ngFor="let block of data.blocks; let index = index;">
          <ng-container *ngIf="!block.hidden" ngxSlickItem>
            <a class="carousel-item"
               *ngIf="getLink(getBlockFieldValueByIndex(index, 'item-url')); else noLink"
               [href]="getLink(getBlockFieldValueByIndex(index, 'item-url'))"
               [style.height]="getFieldsGroupValue('neo-carousel-layout','carousel-height')+'px'"
               [class.default-bg]="(!mobile && !getBlockFieldValueByIndex(index, 'image'))
                     || (mobile && !getBlockFieldValueByIndex(index, 'mobile-image') && !getBlockFieldValueByIndex(index, 'image'))">
              <div class="carousel-content"
                   *ngIf="getBlockFieldValueByIndex(index,'display-content')"
                   [style.justify-content]="getBlockFieldValueByIndex(index, 'justify-content')"
                   [style.align-items]="getBlockFieldValueByIndex(index, 'align-items')">
                <div class="ci-inf animated tdShrinkIn"
                     (click)="$event.stopPropagation()"
                     [style.background-color]="getBlockFieldValueByIndex(index, 'background-color')"
                     [class.transparent]="getBlockFieldValueByIndex(index,'background-transparent')"
                     [style.text-align]="getBlockFieldValueByIndex(index, 'text-align')"
                     [style.width]="getBlockFieldValueByIndex(index, 'content-width')+'px'"
                     [style.border-radius]="getBlockFieldValueByIndex(index, 'border-radius')+'px'">

                  <div class="ci-title" *ngIf="getBlockFieldValueByIndex(index, 'title')"
                       [style.font-size]="getBlockFieldValueByIndex(index, 'title-size')+'px'"
                       [style.color]="getBlockFieldValueByIndex(index, 'title-color')">
                    {{getBlockFieldValueByIndex(index, 'title')}}
                  </div>
                  <div class="ci-sub-title" *ngIf="getBlockFieldValueByIndex(index, 'desc')"
                       [style.margin-top]="getBlockFieldValueByIndex(index, 'desc-margin-top')+'px'">
                    <p [style.color]="getBlockFieldValueByIndex(index, 'desc-color')">
                      {{getBlockFieldValueByIndex(index, 'desc')}}
                  </div>

                  <a [href]="getLink(getBlockFieldValueByIndex(index, 'btn-url'))" class="neo-btn view-btn"
                     *ngIf="getBlockFieldValueByIndex(index, 'btn-label')"
                     [style.color]="getBlockFieldValueByIndex(index, 'btn-color')"
                     [style.background-color]="getBlockFieldValueByIndex(index, 'btn-bg')"
                     [style.margin-top]="getBlockFieldValueByIndex(index, 'btn-margin-top')+'px'">
                    <span>{{getBlockFieldValueByIndex(index, 'btn-label')}}</span>
                  </a>
                </div>
              </div>
              <img alt="store"
                   *ngIf="getBlockFieldValueByIndex(index, 'image') && (!mobile || !getBlockFieldValueByIndex(index, 'mobile-image'))"
                   [src]="urlStoreMedia(getBlockFieldValueByIndex(index, 'image'))"
                   [style.object-fit]="getBlockFieldValueByIndex(index,'img-fit')"/>

              <img alt="store" *ngIf="getBlockFieldValueByIndex(index, 'mobile-image') && mobile"
                   [src]="urlStoreMedia(getBlockFieldValueByIndex(index, 'mobile-image'))"
                   [style.object-fit]="getBlockFieldValueByIndex(index,'mobile-img-fit')"/>

            </a>

            <ng-template #noLink>
              <div class="carousel-item"
                   [style.height]="getFieldsGroupValue('neo-carousel-layout','carousel-height')+'px'"
                   [class.default-bg]="(!mobile && !getBlockFieldValueByIndex(index, 'image'))
                     || (mobile && !getBlockFieldValueByIndex(index, 'mobile-image') && !getBlockFieldValueByIndex(index, 'image'))">
                <div class="carousel-content"
                     *ngIf="getBlockFieldValueByIndex(index,'display-content')"
                     [style.justify-content]="getBlockFieldValueByIndex(index, 'justify-content')"
                     [style.align-items]="getBlockFieldValueByIndex(index, 'align-items')">
                  <div class="ci-inf animated tdShrinkIn"
                       (click)="$event.stopPropagation()"
                       [style.background-color]="getBlockFieldValueByIndex(index, 'background-color')"
                       [class.transparent]="getBlockFieldValueByIndex(index,'background-transparent')"
                       [style.text-align]="getBlockFieldValueByIndex(index, 'text-align')"
                       [style.width]="getBlockFieldValueByIndex(index, 'content-width')+'px'"
                       [style.border-radius]="getBlockFieldValueByIndex(index, 'border-radius')+'px'">

                    <div class="ci-title" *ngIf="getBlockFieldValueByIndex(index, 'title')"
                         [style.font-size]="getBlockFieldValueByIndex(index, 'title-size')+'px'"
                         [style.color]="getBlockFieldValueByIndex(index, 'title-color')">
                      {{getBlockFieldValueByIndex(index, 'title')}}
                    </div>
                    <div class="ci-sub-title" *ngIf="getBlockFieldValueByIndex(index, 'desc')"
                         [style.margin-top]="getBlockFieldValueByIndex(index, 'desc-margin-top')+'px'">
                      <p [style.color]="getBlockFieldValueByIndex(index, 'desc-color')">
                        {{getBlockFieldValueByIndex(index, 'desc')}}
                    </div>

                    <a [href]="getLink(getBlockFieldValueByIndex(index, 'btn-url'))" class="neo-btn view-btn"
                       *ngIf="getBlockFieldValueByIndex(index, 'btn-label')"
                       [style.color]="getBlockFieldValueByIndex(index, 'btn-color')"
                       [style.background-color]="getBlockFieldValueByIndex(index, 'btn-bg')"
                       [style.margin-top]="getBlockFieldValueByIndex(index, 'btn-margin-top')+'px'">
                      <span>{{getBlockFieldValueByIndex(index, 'btn-label')}}</span>
                    </a>
                  </div>
                </div>
                <img alt="store"
                     *ngIf="getBlockFieldValueByIndex(index, 'image') && (!mobile || !getBlockFieldValueByIndex(index, 'mobile-image'))"
                     [src]="urlStoreMedia(getBlockFieldValueByIndex(index, 'image'))"
                     [style.object-fit]="getBlockFieldValueByIndex(index,'img-fit')"/>

                <img alt="store" *ngIf="getBlockFieldValueByIndex(index, 'mobile-image') && mobile"
                     [src]="urlStoreMedia(getBlockFieldValueByIndex(index, 'mobile-image'))"
                     [style.object-fit]="getBlockFieldValueByIndex(index,'mobile-img-fit')"/>

              </div>
            </ng-template>

          </ng-container>
        </ng-container>
      </ngx-slick-carousel>

    </div>

  </div>
</div>




