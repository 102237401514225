import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CommonService, ShopyanAddressFormComponent, ShopyanToastrService} from '@app/_shared';
import {FormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'lib-neo-address-form',
  templateUrl: './neo-address-form.component.html',
  styleUrls: ['./neo-address-form.component.scss']
})
export class NeoAddressFormComponent extends ShopyanAddressFormComponent implements OnInit, OnDestroy {

  constructor(formBuilder: FormBuilder,
              toastrService: ShopyanToastrService,
              commonService: CommonService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<NeoAddressFormComponent>) {
    super(formBuilder, toastrService, commonService);
  }

  ngOnInit(): void {
    this.address = this.data;
    this.init();
  }


  closeModal(data?: any): void {
    this.dialogRef.close(data);
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
