import {Component} from '@angular/core';
import {AuthService, StorageService, UserForm} from "@app/_shared";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  template: ''
})
export abstract class ShopyanRegisterComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  passwordVisible = false;
  registrationFormGroup: FormGroup;
  errorMsg: string;
  loading: boolean;

  protected constructor(private translate: TranslateService,
                        private formBuilder: FormBuilder,
                        private authService: AuthService,
                        private storageService: StorageService,
                        private router: Router) {
    super();
  }

  init(): void {
    if (!this.buildMode && (!this.authService.isAuthEnabled() || this.authService.isAuthenticated())) {
      this.router.navigate(['']).then();
    }

    this.registrationFormGroup = this.formBuilder.group({
      firstName: [null, [Validators.required, Validators.maxLength(35)]],
      lastName: [null, [Validators.maxLength(35)]],
      email: [null, [Validators.required, Validators.maxLength(255), Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
    });
  }

  submitRegistrationForm() {
    this.registrationFormGroup.markAllAsTouched();
    if (this.registrationFormGroup.valid) {

      const user = {} as UserForm;
      Object.assign(user, this.registrationFormGroup.value);
      this.loading = true;
      this.subscription.add(this.authService.signup(user).subscribe({
        next: response => {
          this.authService.updateConnectedUser(response.customer);
          this.authService.setToken(response.token);
          this.loading = false;
          this.router.navigate(['']).then();
        }, error: error => {
          if (error.error && error.error.errors) {
            this.errorMsg = error.error.errors[0];
          }
          if (!this.errorMsg) {
            this.errorMsg = this.translate.instant("COMMON.MESSAGE.OOPS")
          }
          this.loading = false;
        }
      }));

    }
  }

  /**
   * show or hide password
   */
  togglePassword(): void {
    this.passwordVisible = !this.passwordVisible;
  }

}
