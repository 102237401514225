<div id="internal-error" [dir]="direction">
  <div class="page-container">
    <div class="lp">
      <div>
        <div class="oops">{{'ERROR_PAGE.INTERNAL_ERROR.SORRY' | translate}}</div>
        <div class="error-ttl">{{'ERROR_PAGE.INTERNAL_ERROR.TITLE' | translate}}</div>
        <div class="error-desc">
          {{'ERROR_PAGE.INTERNAL_ERROR.DESC' | translate}}
        </div>
        <div class="actions">
          <a href="#" class="neo-btn">{{'COMMON.GO_BACK' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="rp">
    </div>
  </div>
</div>
