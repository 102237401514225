<app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
<div [id]="data.code" class="neo-you-may-products shopyan-section"
     [class.build-mode]="buildMode"
     [style.background]="getFieldValue('background')"
     *ngIf="products && products.length > 0">

  <div class="neo-section-layout v-padding">

    <div class="neo-section-header">
      <div class="sh-title"> {{ 'YOU_MAY.TITLE' | translate }}</div>
    </div>

    <div id="products" #productLayout >
      <ngx-slick-carousel [config]="slideConfig" *ngIf="displayCarousel">
        <ng-container *ngFor="let item of products">
          <div class="product-item" ngxSlickItem [style.height]="productHeight+'px'" >
            <lib-neo-product-card
              [storeId]="storeID"
              [product]="item"
              [fields]="data.values || []" ></lib-neo-product-card>
          </div>
        </ng-container>
      </ngx-slick-carousel>
    </div>

  </div>
</div>
