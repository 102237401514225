<div [id]="data.code" class="neo-order-summary shopyan-section" [class.build-mode]="buildMode">
  <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>

  <lib-neo-order-summary-shared [hideContinueShopping]="getFieldValue('hide-continue-shopping')==true" *ngIf="order" [order]="order" [storeId]="storeID"></lib-neo-order-summary-shared>

  <div id="build-mode" *ngIf="buildMode">
    <div class="ic"></div>
    <span class="big-ttl">{{ 'ORDER_SUMMARY.TITLE' | translate }}</span>
    <span>{{ 'ORDER_SUMMARY.DESC' | translate }}</span>
  </div>

</div>
