<div class="neo-collage-item">
  <ng-container *ngIf="product">
    <a href="/product/{{product.slug}}" class="collage-item">
      <div class="collage-label"><span>{{product.name}}</span></div>
      <img alt="store" [src]="urlProductMediumMedia(product.medias[0])"
           *ngIf="product.medias && product.medias.length>0 "/>
    </a>
  </ng-container>
  <ng-container *ngIf="collection">
    <a href="/search?collection={{collection.id}}" class="collage-item">
      <img alt="store" [src]="urlCollectionMediumMedia(collection.media)" *ngIf="collection.media"/>
    </a>
  </ng-container>
  <ng-container *ngIf="imgURL">
    <ng-container *ngIf="getLink(getFieldValue('url')); else noLink">
      <a [href]="getLink(getFieldValue('url'))" class="collage-item">
        <img alt="store" [src]="imgURL" [style.object-fit]="imgFit"/>
      </a>
    </ng-container>
    <ng-template #noLink>
      <img alt="store" [src]="imgURL" [style.object-fit]="imgFit"/>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="youtubeSafeURL">
    <iframe [src]="youtubeSafeURL"
            [frameBorder]="0" allowfullscreen></iframe>
  </ng-container>
  <ng-container *ngIf="noImage">
    <div class="default-image" [ngClass]="currentClass"></div>
  </ng-container>
</div>
