<div class="ph-item">
  <div class="ph-col-12">
    <div class="ph-picture"></div>
    <div class="ph-row">
      <div class="ph-col-6"></div>
      <div class="ph-col-4 empty big"></div>
      <div class="ph-col-2"></div>
      <div class="ph-col-4"></div>
      <div class="ph-col-8 empty"></div>
      <div class="ph-col-6"></div>
      <div class="ph-col-2 empty"></div>
      <div class="ph-col-4"></div>
    </div>
  </div>
</div>
