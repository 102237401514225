<div [id]="data.code" class="neo-my-collections shopyan-section" [class.build-mode]="buildMode">
  <div class="neo-section-layout large">
    <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
    <ng-container *ngIf="collections && collections.length > 0">
      <div class="collections-content" [class.one]="collections.length == 1" [class.two]="collections.length == 2">
        <a href="/search?collection={{collection.id}}" class="collection"
           *ngFor="let collection of collections;">
          <div class="col-image">
            <img alt="store"  [src]="urlCollectionMediumMedia(collection.media)" *ngIf="collection.media"/>
          </div>
          <div class="col-label">
            <span>{{collection.name}}</span>
          </div>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading && (!collections || collections.length == 0)">
      <lib-neo-no-result></lib-neo-no-result>
    </ng-container>
  </div>
</div>
