import { Product } from "./product.model";

export class Upsell {
  id: string;
  label: string;
  active: boolean;
  discountPercent: number;
  fixedDiscount: number;
  timer: number;
  description: string;
  triggerProducts: Product[];
  offerProducts: Product[];
}

export enum UpsellPage {
  PRODUCT = 'PRODUCT',
  CART = 'CART',
  CHECKOUT = 'CHECKOUT',
  THANK_YOU = 'THANK_YOU',
}
