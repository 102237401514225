import {Component, Input} from '@angular/core';
import {constructLink, constructMenuLink, GraphSection, Link, MenuItem} from "@app/_shared";

@Component({
  template: ''
})
export abstract class ShopyanSectionComponent {

  @Input()
  data: GraphSection;

  @Input()
  buildMode: boolean;

  @Input()
  storeID: string;

  protected constructor() {
  }

  public getFieldValue(fieldCode: string): any {
    return this.data?.values?.find(item => item.fieldCode === fieldCode)?.value;
  }

  public getFieldsGroupValue(fieldsGroupCode: string, fieldCode: string): any {
    return this.data?.fieldsGroups?.find(item => item.blockCode === fieldsGroupCode)?.values?.find(item => item.fieldCode === fieldCode)?.value;
  }

  public getBlockFieldValue(blockCode: string, fieldCode: string): any {
    return this.data?.blocks?.find(item => item.blockCode === blockCode)?.values?.find(item => item.fieldCode === fieldCode)?.value;
  }

  public getBlockFieldValueByIndex(blockIndex: number, fieldCode: string): any {
    if (this.data?.blocks && this.data?.blocks.length > blockIndex) {
      return this.data?.blocks![blockIndex].values?.find(item => item.fieldCode === fieldCode)?.value;
    }
    return;
  }

  public getFieldsGroup(blockCode: string): any {
    return this.data?.fieldsGroups?.find(item => item.blockCode === blockCode);
  }

  public getBlock(blockCode: string): any {
    return this.data?.blocks?.find(item => item.blockCode === blockCode);
  }

  constructLink(link: Link): any {
    return constructLink(link);
  }

  constructMenuLink(menuItem: MenuItem): any {
     return constructMenuLink(menuItem);
  }
}
