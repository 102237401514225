import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {
  AppsService,
  AuthService,
  CheckoutFieldSubType,
  CheckoutFieldType,
  CommonService,
  ComponentLookup,
  ProductService,
  ShopyanCartHelperService,
  ShopyanProductComponent,
  ShopyanToastrService,
  StorageService,
  StoreService,
  UpsellService
} from '@app/_shared';
import {DomSanitizer, TransferState} from "@angular/platform-browser";
import {Gallery, GalleryItem, ImageItem} from "ng-gallery";
import {Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ConversionService} from "@app/_shared/service/conversion.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'lib-neo-express-checkout',
  templateUrl: './neo-express-checkout.component.html',
  styleUrls: ['./neo-express-checkout.component.scss']
})
@ComponentLookup('neo-express-checkout')
export class NeoExpressCheckoutComponent extends ShopyanProductComponent implements OnInit {

  galleryItem: GalleryItem[] = [];

  slideConfig: any = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "infinite": false
  };

  displayCarousel = true;
  checkoutFieldSubType = CheckoutFieldSubType;

  constructor(translate: TranslateService,
              _sanitizer: DomSanitizer,
              public gallery: Gallery,
              @Inject(DOCUMENT) private document: Document,
              router: Router,
              upsellService: UpsellService,
              storageService: StorageService,
              dialog: MatDialog,
              authService: AuthService,
              shopyanCartHelperService: ShopyanCartHelperService,
              toastr: ShopyanToastrService,
              formBuilder: FormBuilder,
              shopyanAppsService: AppsService,
              commonService: CommonService,
              productService: ProductService,
              storeService: StoreService,
              http: HttpClient,
              conversionService: ConversionService,
              transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any,
              deviceDetectorService: DeviceDetectorService) {
    super(translate, _sanitizer, upsellService, commonService, productService, storeService, router, storageService, authService, dialog, shopyanCartHelperService,
      toastr, formBuilder, shopyanAppsService, http, conversionService, transferState, platformId, deviceDetectorService);
  }

  ngOnInit(): void {
    this.init();
    this.initLTR();
    this.subscription.add(this.basicHelperService.langSubject.subscribe((next: any) => {
      this.displayCarousel = false;
      this.initLTR();
      setTimeout(() => {
        this.displayCarousel = true;
      }, 300);
    }));
  }

  initLTR(): void {
    this.slideConfig.rtl = this.translate.currentLang === 'ar';
  }

  /**
   * init gallery
   */
  initGallery(): void {

    //Load the light box
    this.media?.forEach(m => {
      if (m.isVideo === false) {
        this.galleryItem.push(new ImageItem({src: m.link, thumb: m.link}));
      }
    });

    const lightboxRef = this.gallery.ref();
    // Load items into the lightbox gallery ref
    lightboxRef.load(this.galleryItem);
  }

  get discountPercent(): number {
    if (this.selectedBundle && this.selectedBundle.initTotal > 0) {
      return Math.floor(100 * (this.selectedBundle.initTotal - this.selectedBundle.offerTotal) / this.selectedBundle.initTotal);
    } else if (this.basicPrice > 0) {
      return Math.floor(100 * (this.basicPrice - (this.price - this.basicHelperService.getDiscountValue(this.discounts, this.price))) / this.product.pricing.basicPrice);
    }
    return 0;
  }

  /**
   * Lightbox index
   */
  get lightBoxIndex(): number {
    return this.galleryItem.indexOf(this.galleryItem.find(item => item.data.src === this.selectedMedia.link)!);
  }

  isLandingComponent(): boolean {
    return true;
  }

  protected readonly CheckoutFieldSubType = CheckoutFieldSubType;
  protected readonly CheckoutFieldType = CheckoutFieldType;
}
