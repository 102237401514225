import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {
  AuthService, CartItem,
  ComponentLookup, OrderItem,
  ShopyanCartComponent,
  ShopyanCartHelperService,
  ShopyanToastrService,
  StorageService,
  UpsellPage,
  UpsellService
} from '@app/_shared';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'lib-neo-cart',
  templateUrl: './neo-cart.component.html',
  styleUrls: ['./neo-cart.component.scss']
})
@ComponentLookup('neo-cart')
export class NeoCartComponent extends ShopyanCartComponent implements OnInit, OnDestroy {


  constructor(helperService: ShopyanCartHelperService,
              basicToastr: ShopyanToastrService,
              storageService: StorageService,
              authService: AuthService,
              http: HttpClient,
              router: Router,
              private upsellService: UpsellService,
              @Inject(PLATFORM_ID) platformId: any) {
    super(helperService, basicToastr, storageService, authService, http, router, platformId);
  }

  ngOnInit(): void {
    this.init();
  }

  initUpsells(): void {
    this.upsellService.checkUpSells(UpsellPage.CART, this.storeID);
  }


  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
