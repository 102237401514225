<div [id]="data.code" class="neo-images shopyan-section" [class.build-mode]="buildMode">

  <div class="neo-section-layout"
       [class.large]="getFieldsGroupValue('layout','layout-width') == 'large'">

    <div class="images"
         [style.gap]="getFieldsGroupValue('layout','gap')+'px'">
      <ng-container *ngFor="let block of data.blocks; let index = index">
        <div class="image-container"
             *ngIf="!block.hidden"
             [style.border-radius]="getFieldsGroupValue('layout','radius')+'px'"
             [class.default-bg1]="!getBlockFieldValueByIndex(index, 'img') && index % 3 == 0"
             [class.default-bg2]="!getBlockFieldValueByIndex(index, 'img') && index % 3 == 1"
             [class.default-bg3]="!getBlockFieldValueByIndex(index, 'img') && index % 3 == 2"
             [style.height]="getFieldsGroupValue('layout','img-height')+'rem'">

          <a class="link" *ngIf="getLink(getBlockFieldValueByIndex(index,'link'))"
             [href]="getLink(getBlockFieldValueByIndex(index,'link'))"></a>
          <img alt="store" *ngIf="getBlockFieldValueByIndex(index,'img')"
               [style.object-position]="getBlockFieldValueByIndex(index,'background-position')"
               [style.object-fit]="getBlockFieldValueByIndex(index,'fit')"
               [src]="urlStoreMedia(getBlockFieldValueByIndex(index,'img'))"/>
        </div>
      </ng-container>
    </div>

  </div>

</div>
