import {Component} from '@angular/core';
import {AuthService, StorageService, User} from "@app/_shared";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  template: ''
})
export abstract class ShopyanLoginComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  passwordVisible = false;
  loginFormGroup: FormGroup;
  errorMsg: string;
  loading = false;

  protected constructor(private translate: TranslateService,
                        private formBuilder: FormBuilder,
                        private authService: AuthService,
                        private storageService: StorageService,
                        private router: Router) {
    super();
  }

  init(): void {
    if (!this.buildMode && (!this.authService.isAuthEnabled() || this.authService.isAuthenticated())) {
      this.router.navigate(['']).then();
    }
    this.loginFormGroup = this.formBuilder.group({
      email: [null, [Validators.required, Validators.maxLength(255), Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
      rememberMe: [false]
    });
    this.initFormFromLocalStorage();
  }

  /**
   * show or hide password
   */
  togglePassword(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  /**
   * Submit form
   */
  submitLoginForm() {
    this.loginFormGroup.markAllAsTouched();
    if (this.loginFormGroup.valid) {

      const user = {} as User;
      user.email = this.loginFormGroup.get('email')?.value;
      user.password = this.loginFormGroup.get('password')?.value;
      this.initRememberMe();

      this.loading = true;
      this.subscription.add(this.authService.logIn(user).subscribe({
        next: response => {
          this.authService.updateConnectedUser(response.customer);
          this.authService.setToken(response.token);
          this.loading = false;
          this.router.navigate(['']).then();
        }, error: error => {
          if (error.error && error.error.errors) {
            this.errorMsg = error.error.errors[0];
          }
          if (!this.errorMsg) {
            this.errorMsg = this.translate.instant("COMMON.MESSAGE.OOPS")
          }
          this.loading = false;
        }
      }));
    }
  }

  /**
   * Init from local storage
   */
  initFormFromLocalStorage() {
    if (localStorage.getItem('rememberMe') === 'true') {
      this.loginFormGroup.patchValue({
        email: this.storageService.getData('email'),
        password: this.storageService.getData('password'),
        rememberMe: true
      });
    }
  }

  /**
   * Init remember me
   */
  initRememberMe() {
    if (this.loginFormGroup.get('rememberMe')?.value === true) {
      this.storageService.saveData('email', this.loginFormGroup.get('email')?.value);
      this.storageService.saveData('password', this.loginFormGroup.get('password')?.value);
      localStorage.setItem('rememberMe', 'true');
    } else {
      this.storageService.removeData('email');
      this.storageService.removeData('password');
      localStorage.removeItem('rememberMe');
    }
  }


}
