<div id="neo-address-form" class="neo-modal yan-modal">
  <div class="close-modal" (click)="closeModal()"></div>
  <div class="yan-modal-title">{{'ACCOUNT.PROFILE.ADDRESS_FORM' | translate}}</div>
  <div class="yan-modal-content">
    <form [formGroup]="formGroup" *ngIf="formGroup">
      <div class="neo-flex">
        <div class="neo-field">
          <div class="neo-field-label">
            <label class="neo-label">
              <span>{{'ACCOUNT.PROFILE.FIRST_NAME' | translate}}</span>
              <span class="mandatory">*</span>
            </label>
          </div>
          <input type="text" class="neo-input"
                 formControlName="firstName"
                 placeholder="{{'ACCOUNT.PROFILE.FIRST_NAME' | translate}}" InputValidator/>
          <app-field-error controlName="firstName" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
        </div>
        <div class="neo-field">
          <div class="neo-field-label">
            <label class="neo-label">
              <span>{{'ACCOUNT.PROFILE.LAST_NAME' | translate}}</span>
            </label>
          </div>
          <input type="text" class="neo-input"
                 formControlName="lastName"
                 placeholder="{{'ACCOUNT.PROFILE.LAST_NAME' | translate}}"/>
        </div>
      </div>
      <div class="neo-field">
        <div class="neo-field-label">
          <label class="neo-label">
            <span>{{'ACCOUNT.PROFILE.ADDRESS' | translate}}</span>
            <span class="mandatory">*</span>
          </label>
        </div>
        <input type="text" class="neo-input"
               formControlName="line1"
               InputValidator
               placeholder="{{'ACCOUNT.PROFILE.ADDRESS' | translate}}"/>
        <app-field-error controlName="line1" errorKey="required">
          {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
        </app-field-error>
      </div>
      <div class="neo-flex">
        <div class="neo-field">
          <div class="neo-field-label">
            <label class="neo-label">
              <span>{{'ACCOUNT.PROFILE.ADDRESS2' | translate}}</span>
            </label>
          </div>
          <input type="text" class="neo-input"
                 formControlName="line2"
                 placeholder="{{'ACCOUNT.PROFILE.ADDRESS2' | translate}}"/>
        </div>
        <div class="neo-field">
          <div class="neo-field-label">
            <label class="neo-label">
              <span>{{'ACCOUNT.PROFILE.PHONE' | translate}}</span>
            </label>
          </div>
          <input type="text" class="neo-input"
                 formControlName="phoneNumber"
                 placeholder="{{'ACCOUNT.PROFILE.PHONE' | translate}}"/>
        </div>
      </div>
      <div class="neo-flex">
        <div class="neo-field">
          <div class="neo-field-label">
            <label class="neo-label">
              <span>{{'ACCOUNT.PROFILE.CITY' | translate}}</span>
              <span class="mandatory">*</span>
            </label>
          </div>
          <input type="text" class="neo-input"
                 formControlName="city"
                 InputValidator
                 placeholder="{{'ACCOUNT.PROFILE.CITY' | translate}}"/>
          <app-field-error controlName="city" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
        </div>
        <div class="neo-field">
          <div class="neo-field-label">
            <label class="neo-label">
              <span>{{'ACCOUNT.PROFILE.STATE' | translate}}</span>
            </label>
          </div>
          <input type="text" class="neo-input"
                 formControlName="state"
                 placeholder="{{'ACCOUNT.PROFILE.STATE' | translate}}"/>
        </div>
      </div>
      <div class="neo-flex">
        <div class="neo-field">
          <div class="neo-field-label">
            <label class="neo-label">
              <span>{{'ACCOUNT.PROFILE.ZIP' | translate}}</span>
            </label>
          </div>
          <input type="text" class="neo-input"
                 formControlName="zipCode"
                 placeholder="{{'ACCOUNT.PROFILE.ZIP' | translate}}"/>
        </div>
        <div class="neo-field">
          <div class="neo-field-label">
            <label class="neo-label">
              <span>{{'ACCOUNT.PROFILE.COUNTRY' | translate}}</span>
              <span class="mandatory">*</span>
            </label>
          </div>
          <mat-form-field appearance="outline">
            <mat-select formControlName="country" >
              <ng-container *ngFor="let item of countries">
                <mat-option [value]="item.code">{{item.name}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <app-field-error controlName="country" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
        </div>
      </div>
    </form>
  </div>
  <div class="yan-modal-btns">
    <button class="neo-btn" (click)="confirmForm()" loadingBtn
            [loading]="loading">{{'COMMON.SUBMIT' | translate}}</button>
    <div class="neo-btn secondary" (click)="closeModal()">{{'COMMON.CANCEL' | translate}}</div>
  </div>
</div>
