import {Component} from '@angular/core';
import {AuthService, ShopyanToastrService, StorageService, User} from "@app/_shared";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  template: ''
})
export abstract class ShopyanForgotPasswordComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  passwordVisible = false;
  formGroup: FormGroup;
  errorMsg: string;
  loading = false;

  protected constructor(
                        private translate: TranslateService,
                        private formBuilder: FormBuilder,
                        private authService: AuthService,
                        private storageService: StorageService,
                        private toastrService: ShopyanToastrService,
                        private router: Router) {
    super();
  }

  init(): void {
    if (!this.buildMode && this.authService.isAuthenticated()) {
      this.router.navigate(['']).then();
    }
    this.formGroup = this.formBuilder.group({
      email: [null, [Validators.required, Validators.maxLength(255), Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
    });
  }


  /**
   * Submit form
   */
  submit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {

      const user = {} as User;
      user.email = this.formGroup.get('email')?.value;


      // TODO, Temporary, to be removed when the back resource is done
      this.toastrService.success(this.translate.instant("AUTH.FORGOT_PASSWORD.MESSAGE.SUCCESS"));
      this.router.navigate(['/login']).then();
      return;

      this.loading = true;
      this.subscription.add(this.authService.forgot(user).subscribe({
        next: response => {
          this.loading = false;
          this.toastrService.success(this.translate.instant("AUTH.FORGOT_PASSWORD.MESSAGE.SUCCESS"));
          this.router.navigate(['/login']).then();
        }, error: error => {
          if (error.error && error.error.errors) {
            this.errorMsg = error.error.errors[0];
          }
          if (!this.errorMsg) {
            this.errorMsg = this.translate.instant("COMMON.MESSAGE.OOPS")
          }
          this.loading = false;
        }
      }));
    }
  }

}
