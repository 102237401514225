import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {isPlatformServer} from "@angular/common";


@Injectable({providedIn: 'root'})
export class MenuService {


  constructor(@Inject(PLATFORM_ID) private platformId: any, private http: HttpClient) {
  }

  getMenuItems(ids: string[]) {
    let url = `${this.getStoreUrl()}/menus/filter`;

    let params = new HttpParams();
    params = params.append('ids', ids.join(','));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  findMenu(id: string) {
    let url = `${this.getStoreUrl()}/menus/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  private getStoreUrl() {
    return isPlatformServer(this.platformId) ? environment.privateStoreApiUrl : environment.pubicStoreApiUrl;
  }

}
