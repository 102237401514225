import {Component, OnInit} from '@angular/core';
import {StorageService} from "@app/_shared";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-internal-error',
  templateUrl: './internal-error.component.html',
  styleUrls: ['./internal-error.component.scss']
})
export class InternalErrorComponent implements OnInit {

  constructor(private translate: TranslateService,
              private storageService: StorageService) {
  }

  ngOnInit(): void {
    const selectedLang = this.storageService.getData('my_lang') || 'fr';
    this.translate.use(selectedLang);
  }

  get direction(): any {
    return this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
  }


}
