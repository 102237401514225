import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {AuthService, ComponentLookup, Customer} from '@app/_shared';
import {Router} from "@angular/router";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'lib-neo-account',
  templateUrl: './neo-account.component.html',
  styleUrls: ['./neo-account.component.scss']
})
@ComponentLookup('neo-account')
export class NeoAccountComponent extends ShopyanSectionComponent implements OnInit {

  customer: Customer;
  currentTab = 1;

  constructor(private authService: AuthService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  ngOnInit(): void {
    if (!this.buildMode && isPlatformBrowser(this.platformId)) {
      if (!this.authService.isAuthEnabled()) {
        this.router.navigate(['']).then();
      }
      this.customer = this.authService.getConnectedUser();
    }
  }

  logout(): void {
    this.authService.logOut();
  }

}

