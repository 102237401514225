<div [id]="data.code" class="neo-newsletter shopyan-section"
     [class.build-mode]="buildMode"
     [style.background]="getFieldValue('background')">

  <div class="neo-section-layout v-padding"
       [ngClass]="getFieldValue('display')">

    <div class="newsletter-header">
      <div class="n-title" *ngIf="getFieldValue('title')">
        {{getFieldValue('title')}}
      </div>
      <div class="n-desc"
           *ngIf="getFieldValue('desc')">
        <p>  {{getFieldValue('desc')}}</p>
      </div>
    </div>
    <div class="mail-form" [formGroup]="formGroup" *ngIf="formGroup">
      <div class="inpt">
        <input type="text" InputValidator class="neo-input" placeholder="{{ 'NEWSLETTER.EMAIL_PLACEHOLDER' | translate }}" formControlName="mail"/>
        <app-field-error controlName="mail" errorKey="required">
          {{ 'COMMON.ERROR.REQUIRED_FIELD' | translate }}
        </app-field-error>
        <app-field-error controlName="mail" errorKey="email">
          {{ 'COMMON.ERROR.INVALID_MAIL' | translate }}
        </app-field-error>
      </div>
      <button loadingBtn class="neo-btn"
              [loading]="loader" (click)="submit()">
        {{ 'NEWSLETTER.SUBSCRIBE' | translate }}
      </button>
    </div>

  </div>
</div>
