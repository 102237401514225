import {Component, Input} from '@angular/core';
import {
  Address,
  CheckoutFieldType,
  MediaSize,
  MediaType,
  OrderDetail,
  OrderItem,
  ProvisionalDiscountType,
  ShopyanCartHelperService,
  urlMedia
} from "@app/_shared";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  template: ''
})
export abstract class ShopyanSummaryComponent extends ShopyanSectionComponent {

  @Input()
  order: OrderDetail;

  @Input()
  storeId: string;

  @Input()
  hideContinueShopping: boolean;

  protected constructor(protected helperService: ShopyanCartHelperService) {
    super();
  }

  /**
   * Get Guest label
   */
  get getGuest(): string {
    return this.order.guest.fields?.filter(field => field.type === CheckoutFieldType.FIRST_NAME || field.type === CheckoutFieldType.LAST_NAME || field.type === CheckoutFieldType.FULL_NAME)
      .map(field => field.value).join(' ') || '';
  }

  /**
   * Get shipping address
   */
  get shippingAddress(): string | undefined {
    if (!this.order.shippingAddress) {
      return undefined;
    }
    return this.getAddressAsString(this.order.shippingAddress);
  }


  /**
   * Generate address as a string to display
   * @param address
   */
  getAddressAsString(address: Address): string {
    let str = '';
    if (address.firstName) {
      str += address.firstName + ' ';
    }
    if (address.lastName) {
      str += address.lastName;
    }
    if (address.firstName || address.lastName) {
      str += '<br/>';
    }
    if (address.line1) {
      str += address.line1 + '<br/>';
    }
    if (address.line2) {
      str += address.line2 + '<br/>';
    }
    str += address.city;
    if (address.state) {
      str += ' ' + address.state;
    }
    str += '<br/>';
    if (address.zipCode) {
      str += address.zipCode + ' ';
    }
    str += address.country + '<br/>';
    if (address.phoneNumber) {
      str += address.phoneNumber;
    }
    return str;
  }


  getDiscountedPrice(item: OrderItem) {
    let price = item.total || 0;
    if (item.upsell) {
      if (item.upsell.fixedDiscount) {
        price -= item.upsell.fixedDiscount;
      } else if (item.upsell.discountPercent) {
        price -= (item.upsell.discountPercent * price) / 100;
      }
    }
    price -= this.helperService.getDiscountValue(item.discounts, price);
    return price > 0 ? price : 0;
  }

  urlProductMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.PRODUCT, MediaSize.small, media);
  }

  urlVariantMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.VARIANT, MediaSize.small, media);
  }

  get taxValue(): number {
    let total = this.order.subtotal;
    let discountVal = 0;
    if (this.order.discounts) {
      this.order.discounts.forEach((discount: any) => {
        discountVal += discount.percent ? +((discount.percent * total) / 100).toFixed(2) : discount.value;
      });
    }
    if (this.order.provisionalDiscount) {
      discountVal += this.order.provisionalDiscount.type == ProvisionalDiscountType.PERCENT ?
        +((this.order.provisionalDiscount.value * total) / 100).toFixed(2) : this.order.provisionalDiscount.value;
    }
    total -= discountVal;
    return +((this.order.taxes * total) / 100).toFixed(2);
  }
}

