import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {
  AuthService,
  ComponentLookup, PixelService,
  ShopyanCartHelperService,
  ShopyanThankYouComponent,
  ShopyanToastrService,
  StorageService,
  UpsellService
} from '@app/_shared';
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ConversionService} from "@app/_shared/service/conversion.service";

@Component({
  selector: 'lib-neo-thank-you',
  templateUrl: './neo-thank-you.component.html',
  styleUrls: ['./neo-thank-you.component.scss']
})
@ComponentLookup('neo-thank-you')
export class NeoThankYouComponent extends ShopyanThankYouComponent implements OnInit, OnDestroy {

  constructor(_sanitizer: DomSanitizer,
              activatedRoute: ActivatedRoute,
              http: HttpClient,
              authService: AuthService,
              storageService: StorageService,
              basicToastr: ShopyanToastrService,
              private upsellService: UpsellService,
              basicHelperService: ShopyanCartHelperService,
              router: Router,
              pixelService: PixelService,
              conversionService: ConversionService,
              @Inject(PLATFORM_ID)  platformId: any) {
    super(_sanitizer, activatedRoute, http, authService, storageService,
      basicToastr, router, basicHelperService, pixelService, conversionService, platformId);
  }

  ngOnInit(): void {
    this.init();
  }

  initUpsells(): void {
    //this.upsellService.checkUpSells(UpsellPage.THANK_YOU, this.storeID);
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
