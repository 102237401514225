import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {Contact, ContactService, ShopyanToastrService} from "@app/_shared";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  template: ''
})
export abstract class ShopyanContactComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();
  formGroup: FormGroup;
  loader = false;

  protected constructor(private translate: TranslateService,
                        private formBuilder: FormBuilder,
                        private contactService: ContactService,
                        private basicToastrService: ShopyanToastrService) {
    super();
  }

  init(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
      mail: [null, [Validators.required, Validators.email]],
      phone: [null],
      comment: [null, Validators.required]
    });
  }

  submit(): void {

    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      this.basicToastrService.error(this.translate.instant("COMMON.MESSAGE.FILL_REQUIRED_FIELDS"));
    }

    let contact = new Contact();
    Object.assign(contact, this.formGroup.value);

    this.loader = true;
    this.subscription.add(this.contactService.submitContactForm(contact).subscribe((response: any) => {
      this.basicToastrService.success(this.translate.instant("COMMON.MESSAGE.DONE"));
      this.loader = false;
      this.formGroup.reset();
    }));

  }

}
