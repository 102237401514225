export const environment = {
  production: true,
  mediaURL: 'https://api.svc.sookify.com/products/media',
  pubicStoreApiUrl: 'https://api.svc.sookify.com/store',
  pubicProductApiUrl: 'https://api.svc.sookify.com/products',
  pubicOrdersApiUrl: 'https://api.svc.sookify.com/orders',
  pubicDiscountApiUrl: 'https://api.svc.sookify.com/discounts',
  // private endpoints (server side)
  privateStoreApiUrl: 'https://shopyan-store.shopyan.svc.cluster.local:8443',
  privateProductApiUrl: 'https://shopyan-product.shopyan.svc.cluster.local:8443',
  privateOrdersApiUrl: 'https://shopyan-orders.shopyan.svc.cluster.local:8443',
  privateDiscountApiUrl: 'https://shopyan-discount.shopyan.svc.cluster.local:8443'
};
