import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ComponentLookup} from '@app/_shared';
import {DomSanitizer} from "@angular/platform-browser";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'lib-neo-countdown',
  templateUrl: './neo-countdown.component.html',
  styleUrls: ['./neo-countdown.component.scss']
})
@ComponentLookup('neo-countdown')
export class NeoCountdownComponent extends ShopyanSectionComponent implements OnInit, OnDestroy {

  timer: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;

  htmlText: any;
  intervalId: any;

  constructor(private _sanitizer: DomSanitizer, @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  ngOnInit(): void {
    this.timer = (Number(this.getFieldValue('timer-minutes')) || 180) * 60;
    let message = this.getFieldValue('timer-intro');
    if (message) {
      this.htmlText = this._sanitizer.bypassSecurityTrustHtml(message);
    }
    this.tickTock();
    if (isPlatformBrowser(this.platformId)) {
      this.intervalId = setInterval(() => {
        this.tickTock();
      }, 1000);
    }
  }

  tickTock(): void {
    if (this.timer >= 0) {
      const total_minutes = Math.floor(this.timer / 60);
      const total_hours = Math.floor(total_minutes / 60);
      this.days = Math.floor(total_hours / 24);
      this.hours = total_hours % 24;
      this.minutes = total_minutes % 60;
      this.seconds = this.timer % 60;
      this.timer--;
    }
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      clearInterval(this.intervalId);
    }
  }

  protected readonly Math = Math;
}
