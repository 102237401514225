import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {Direction} from "@angular/cdk/bidi";

@Injectable({providedIn: 'root'})
export class ShopyanToastrService {

  constructor(private _snackBar: MatSnackBar,
              private translate: TranslateService) {

  }

  success(message: string) {
    if (!message) {
      message = 'Success';
    }
    const dir: Direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._snackBar.open(message, '', {
      panelClass: ['green-snackbar'],
      direction: dir
    });
  }

  error(message: string) {
    if (!message) {
      message = 'Error';
    }
    const dir: Direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._snackBar.open(message, '', {
      panelClass: ['red-snackbar'],
      direction: dir
    });
  }

  warn(message: string) {
    if (!message) {
      message = 'Warning';
    }
    this._snackBar.open(message, '', {
      panelClass: ['orange-snackbar']
    });
  }


}
