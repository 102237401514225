<div id="upsell-modal" *ngIf="product">
  <div class="upsell-data">
    <div class="upsell-intro">
      <div class="upsell-ttl">{{'UPSELL.TITLE' | translate}}</div>
      <div class="upsell-desc" *ngIf="description" [innerHTML]="description"></div>
    </div>
    <div class="upsell-countdown" *ngIf="upsell.timer">
      <countdown [config]="config" class="custom-style"></countdown>
    </div>
    <div class="media-area">
      <ngx-slick-carousel [config]="slideConfig">
        <ng-container *ngFor="let item of media;">
          <img alt="store" [src]="item" loading="lazy" ngxSlickItem/>
        </ng-container>
      </ngx-slick-carousel>
    </div>

    <div class="product-name">{{product.name}}</div>
    <div class="product-price">
      <div class="real-price">{{price | price}}</div>
      <div class="init-price" *ngIf="basicPrice> 0 && basicPrice != price"
      >{{basicPrice| price}}
      </div>
    </div>
    <div class="product-options" *ngIf="product.variations && product.variations.length > 0">
      <mat-form-field appearance="outline">
        <mat-label>{{'UPSELL.CHOOSE_OPTION' | translate}}</mat-label>
        <mat-select [(ngModel)]="selectedVariation" (selectionChange)="changeVariation()">
          <ng-container *ngFor="let variation of product.variations">
            <mat-option [value]="variation.id"
                        [disabled]="variation.disabled || (!product.disableOutOfStock && (!variation.quantity || variation.quantity <= 0))">
              <span>{{variation.optionValues.join(' - ') + ' - '}} {{getDiscountedPrice(variation)| price}}</span>
              <span
                *ngIf="!product.disableOutOfStock && (!variation.quantity || variation.quantity <= 0)">({{'UPSELL.OUT_OF_STOCK' | translate}}
                )</span>
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="neo-btn add-to-cart"
         *ngIf="upsellPage.THANK_YOU == page || upsellPage.PRODUCT == page; else addToCartBtn" (click)="buyNow()">
      {{'UPSELL.BUY_NOW' | translate}}
    </div>
    <ng-template #addToCartBtn>
      <div class="neo-btn add-to-cart" (click)="addToCart()">{{'UPSELL.ADD_TO_CART' | translate}}</div>
    </ng-template>
    <div class="neo-btn not-interested" (click)="closeModal()">{{'UPSELL.NO_THANKS' | translate}}</div>
  </div>
</div>
