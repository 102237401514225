import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductBundleOffer} from "@app/_shared";

@Component({
  selector: 'app-neo-bundles',
  templateUrl: './neo-bundles.component.html',
  styleUrls: ['./neo-bundles.component.scss']
})
export class NeoBundlesComponent implements OnInit {

  @Input()
  bundles: ProductBundleOffer[];

  @Output()
  selectBundle = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    this.bundles[0].selected = true;
  }

  selectItem(index: number): void {
    this.bundles.forEach((bundle, i) => {
      bundle.selected = i === index;
    });
    this.selectBundle.emit(index);
  }

  updateBundle(index: number): void {
    this.selectBundle.emit(index);
  }

}
