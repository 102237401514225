import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {
  Address,
  AuthService,
  CommonService,
  NeoAddressFormComponent,
  NeoConfirmationDialogComponent,
  ShopyanAccountComponent,
  ShopyanToastrService
} from "@app/_shared";
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {Direction} from "@angular/cdk/bidi";

@Component({
  selector: 'lib-neo-account-form',
  templateUrl: './neo-account-form.component.html',
  styleUrls: ['./neo-account-form.component.scss']
})
export class NeoAccountFormComponent extends ShopyanAccountComponent implements OnInit, OnDestroy {

  constructor(translate: TranslateService,
              formBuilder: FormBuilder,
              router: Router,
              toastrService: ShopyanToastrService,
              commonService: CommonService,
              private translateService: TranslateService,
              authService: AuthService,
              public dialog: MatDialog,
              @Inject(PLATFORM_ID) private platformId: any) {
    super(translate, formBuilder, router, toastrService, commonService, authService)
  }

  ngOnInit(): void {
    this.init();
  }


  /**
   * Add/Edit customer address
   * @param address
   * @param index
   */
  openAddressFormModal(address?: Address, index?: number): void {
    const dir: Direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    let config = {
      width: '700px',
      autoFocus: false,
      panelClass: ['yan-dialog'],
      direction: dir,
      data: address ? {...address} : null
    };
    const dialogRef = this.dialog.open(NeoAddressFormComponent, config);
    this.subscription.add(this.router.events.subscribe(() => {
      dialogRef.close();
    }));
    this.subscription.add(dialogRef.afterClosed().subscribe((data: Address) => {
      if (data) {
        if (address) {
          data.defaultAddress = address.defaultAddress;
          this.customer.addresses[index!] = data;
        } else {
          if (!this.customer.addresses) {
            this.customer.addresses = [];
          }
          this.customer.addresses.push(data);
        }
        this.authService.updateConnectedUser(this.customer);
      }
    }));
  }

  /**
   * Mark as default
   * @param id
   * @param index
   */
  markAsDefault(id: string, index: number) {
    let config = {
      data: this.translateService.instant("ACCOUNT.PROFILE.CONFIRM_MARK_AS_DEFAULT"),
      panelClass: ['yan-dialog'],
    };
    const dialogRef = this.dialog.open(NeoConfirmationDialogComponent, config);
    this.subscription.add(this.router.events.subscribe(() => {
      dialogRef.close();
    }));
    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.doMarkAsDefault(id, index);
      }
    }));
  }


  /**
   * Delete address
   * @param id
   * @param index
   */
  deleteAddress(id: string, index: number) {
    let config = {
      data: this.translateService.instant("ACCOUNT.PROFILE.CONFIRM_DELETE"),
      panelClass: ['yan-dialog'],
    };
    const dialogRef = this.dialog.open(NeoConfirmationDialogComponent, config);
    this.subscription.add(this.router.events.subscribe(() => {
      dialogRef.close();
    }));
    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.doDeleteAddress(id, index);
      }
    }));
  }


  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
