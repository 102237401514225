import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {CollectionService, ComponentLookup, ShopyanFeaturedCollectionsComponent} from '@app/_shared';
import {TransferState} from "@angular/platform-browser";

@Component({
  selector: 'lib-neo-featured-collections',
  templateUrl: './neo-featured-collections.component.html',
  styleUrls: ['./neo-featured-collections.component.scss']
})
@ComponentLookup('neo-featured-collections')
export class NeoFeaturedCollectionsComponent extends ShopyanFeaturedCollectionsComponent implements OnInit, OnDestroy {

  constructor(collectionService: CollectionService,
              transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any) {
    super(collectionService, transferState, platformId);
  }

  ngOnInit(): void {
    this.init();
  }

  getMaxCollections(): number {
    return this.getFieldValue('max-collections')
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
