import {Component} from '@angular/core';
import {
  Address,
  AuthService,
  CommonService,
  Customer,
  extractErrorMessagesFromResponse,
  ShopyanToastrService
} from "@app/_shared";
import {Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  template: ''
})
export abstract class ShopyanAccountComponent {

  subscription: Subscription = new Subscription();

  customer: Customer;
  customerFG: FormGroup;
  customerLoading: boolean;

  passwordFG: FormGroup;
  passwordVisible: boolean;
  newPasswordVisible: boolean;
  passwordLoading: boolean;

  protected constructor(
    protected translate: TranslateService,
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected toastrService: ShopyanToastrService,
    protected commonService: CommonService,
    protected authService: AuthService) {
  }

  init(): void {
    if (!this.authService.isAuthEnabled()) {
      this.router.navigate(['']).then();
    }
    this.customer = this.authService.getConnectedUser();
    this.initCustomerFG();
    this.initPasswordFG();
  }

  initCustomerFG(): void {
    this.customerFG = this.formBuilder.group({
      firstName: [this.customer?.firstName, Validators.required],
      lastName: [this.customer?.lastName,],
      email: [this.customer?.email, [Validators.required, Validators.maxLength(255), Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      phoneNumber: [this.customer?.phoneNumber],
      marketingEmails: [this.customer?.marketingEmails],
    });
  }

  initPasswordFG(): void {
    this.passwordFG = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
      newPassword: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
    });
  }

  /**
   * show or hide password
   */
  togglePassword(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  /**
   * show or hide password
   */
  toggleNewPassword(): void {
    this.newPasswordVisible = !this.newPasswordVisible;
  }

  /**
   * Submit customer form
   */
  submitCustomerForm(): void {

    this.customerFG.markAllAsTouched();
    if (this.customerFG.valid) {
      this.customerLoading = true;
      // Browser only - no private api call
      const url = `${environment.pubicStoreApiUrl}/api/customers`;
      this.subscription.add(this.commonService.update(url, this.customerFG.value).subscribe({
        next: response => {
          this.authService.updateConnectedUser(response);
          this.customerLoading = false;
          this.toastrService.success(this.translate.instant("COMMON.MESSAGE.UPDATED"));
        }, error: error => {
          this.toastrService.error(extractErrorMessagesFromResponse(error));
          this.customerLoading = false;
        }
      }));
    }

  }


  /**
   * Submit password form
   */
  submitPasswordForm(): void {

    this.passwordFG.markAllAsTouched();
    if (this.passwordFG.valid) {
      this.passwordLoading = true;
      // Browser only - no private api call
      const url = `${environment.pubicStoreApiUrl}/api/customers/reset-password`;
      this.subscription.add(this.commonService.update(url, this.passwordFG.value).subscribe({
        next: response => {
          this.passwordLoading = false;
          this.toastrService.success(this.translate.instant("COMMON.MESSAGE.UPDATED"));
          this.passwordFG.reset();
        }, error: error => {
          this.toastrService.error(extractErrorMessagesFromResponse(error));
          this.passwordLoading = false;
        }
      }));
    }

  }

  /**
   * Generate address as a string to display
   * @param address
   */
  getAddressAsString(address: Address): string {
    let str = '';
    if (address.firstName) {
      str += address.firstName + ' ';
    }
    if (address.lastName) {
      str += address.lastName;
    }
    if (address.firstName || address.lastName) {
      str += '<br/>';
    }
    if (address.line1) {
      str += address.line1 + '<br/>';
    }
    if (address.line2) {
      str += address.line2 + '<br/>';
    }
    str += address.city;
    if (address.state) {
      str += ' ' + address.state;
    }
    str += '<br/>';
    if (address.zipCode) {
      str += address.zipCode + ' ';
    }
    str += address.country + '<br/>';
    if (address.phoneNumber) {
      str += address.phoneNumber;
    }
    return str;
  }


  /**
   * Add/Edit customer address
   * @param address
   * @param index
   */
  abstract openAddressFormModal(address?: Address, index?: number): void;

  /**
   * Mark as default
   * @param id
   * @param index
   */
  abstract markAsDefault(id: string, index: number): void;

  /**
   * Confirm marks as default
   * @param id
   * @param index
   */
  doMarkAsDefault(id: string, index: number) {
    // Browser only - no private api call
    const url = `${environment.pubicStoreApiUrl}/api/addresses/${id}/mark-as-default`;
    const sub = this.commonService.update(url, null).subscribe({
      next: response => {
        this.customer.addresses.forEach(item => {
          item.defaultAddress = item.id === id;
        });
        this.authService.updateConnectedUser(this.customer);
        this.toastrService.success(this.translate.instant("COMMON.MESSAGE.DONE"));
      }, error: error => {
        this.toastrService.error(extractErrorMessagesFromResponse(error));
      }
    });
    this.subscription.add(sub);
  }

  /**
   * Delete address
   * @param id
   * @param index
   */
  abstract deleteAddress(id: string, index: number): void;

  /**
   * Confirm address deletion
   * @param id
   * @param index
   */
  doDeleteAddress(id: string, index: number) {
    // Browser only - no private api call
    const url = `${environment.pubicStoreApiUrl}/api/addresses/${id}`;
    const sub = this.commonService.delete(url).subscribe({
      next: response => {
        this.customer.addresses.splice(index, 1);
        this.authService.updateConnectedUser(this.customer);
        this.toastrService.success(this.translate.instant("COMMON.MESSAGE.DELETED"));
      }, error: error => {
        this.toastrService.error(extractErrorMessagesFromResponse(error));
      }
    });
    this.subscription.add(sub);
  }

}
