export function getNeoProductsPerRow(width: number, total: number): number {
  const result = +(width / 300).toFixed(0);
  if (result < 2)
    return 2;
  else if (result > total)
    return total;
  else return result;
}

export function getNeoCollectionsPerRow(width: number, total: number): number {
  const result = +(width / 300).toFixed(0);
  if (result < 2)
    return 2;
  else if (result > total)
    return total;
  else return result;
}

export function getNeoColumnPerRow(width: number, total: number): number {
  const result = +(width / 400).toFixed(0);
  if (result < 1)
    return 1;
  else if (result > total)
    return total;
  else return result;
}

export function getNeoIndicatorPerRow(width: number, total: number): number {
  const result = +(width / 250).toFixed(0);
  if (result < 1)
    return 1;
  else if (result > total)
    return total;
  else return result;
}

export function getNeoReviewsPerRow(width: number, total: number): number {
  const result = +(width / 295).toFixed(0);
  if (result < 1)
    return 1;
  else if (result > total)
    return total;
  else return result;
}

export function getNeoFooterItemPerRow(total: number): number {
   if (window.innerWidth < 900 && total > 2) {
    total = 2;
  } else if (window.innerWidth < 1200 && total > 3) {
    total = 3;
  } else if (window.innerWidth < 1500 && total > 4) {
    total = 4;
  }
  return total;
}



