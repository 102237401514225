import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {MediaSize, MediaType, Product, SalesPop, urlMedia} from "@app/_shared";
import {SalesPopService} from "@app/_shared/service/sales-pop.service";
import {Subscription} from "rxjs";
import {isPlatformBrowser} from "@angular/common";
import {environment} from "../../../environments/environment";
import {StoreService} from "@app/_shared/service/store.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-sales-pop',
  templateUrl: './sales-pop.component.html',
  styleUrls: ['./sales-pop.component.scss']
})
export class SalesPopComponent implements OnInit, OnDestroy {

  subscribers: Subscription = new Subscription();

  salesPop: SalesPop;
  product?: Product;
  displayPop = false;
  timer = 0;
  since: number;
  intervalId: any;

  constructor(private salesPopService: SalesPopService,
              private storeService: StoreService,
              private translate: TranslateService,
              @Inject(PLATFORM_ID) private platformId: any) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.salesPopService.getSalesPopData().subscribe((response: any) => {
        this.salesPop = response;
        if (this.salesPop) {
          this.initTimer();
        }
      });
    }
  }

  get direction(): any {
    return this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
  }

  /**
   * Init timer
   * @private
   */
  private initTimer(): void {

    this.intervalId = setInterval(() => {
      this.timer++;
      if (this.displayPop && this.timer >= this.salesPop.duration) {
        this.displayPop = false;
        this.timer = 0;
      } else if (!this.displayPop && this.timer >= this.salesPop.pauseTime) {
        this.findProduct();
      }
    }, 1000);

  }

  /**
   * Find product
   * @private
   */
  private findProduct(): void {
    delete this.product;
    this.subscribers.add(this.salesPopService.getSalesPopProduct(this.salesPop.products).subscribe({
      next: response => {
        this.product = response;
        this.since = Math.floor(Math.random() * (59 - 2 + 1) + 2)
        this.displayPop = true;
        this.timer = 0;
      }, error: err => {
        this.timer = 0;
      }
    }));
  }

  urlMedia(media: string) {
    return urlMedia(environment.mediaURL + "/", this.storeService.storeId, MediaType.PRODUCT, MediaSize.small, media);
  }

  close(): void {
    this.displayPop = false;
    this.timer = 0;
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      clearInterval(this.intervalId);
    }
    this.subscribers.unsubscribe();
  }
}
