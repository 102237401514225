<div class="neo-section-header">
  <div class="nsh-ttl"
       [style.color]="getFieldValue('label-color')">
    <span [style.font-size]="getFieldValue('label-size')+'px'">{{getFieldValue('label-value')}}</span>
  </div>
  <div class="nsh-desc"
       *ngIf="getFieldValue('sub-label-value')">
    <p [style.color]="getFieldValue('sub-label-color')"
       [style.font-size]="getFieldValue('sub-label-size')+'px'">{{getFieldValue('sub-label-value')}}</p>
  </div>
</div>


