import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AuthService,
  ComponentLookup,
  ShopyanForgotPasswordComponent,
  ShopyanToastrService,
  StorageService
} from '@app/_shared';
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lib-neo-forgot-password',
  templateUrl: './neo-forgot-password.component.html',
  styleUrls: ['./neo-forgot-password.component.scss']
})

@ComponentLookup('neo-forgot-password')
export class NeoForgotPasswordComponent extends ShopyanForgotPasswordComponent implements OnInit, OnDestroy {

  constructor(translate: TranslateService,
              formBuilder: FormBuilder,
              authService: AuthService,
              storageService: StorageService,
              toastrService: ShopyanToastrService,
              router: Router) {
    super(translate, formBuilder, authService, storageService, toastrService, router);
  }

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
