<div [id]="data.code" class="neo-image shopyan-section" [class.build-mode]="buildMode">

  <div class="neo-section-layout large">

    <div class="image-container"
         [style.background-color]="getFieldValue('background-color')"
         [class.default-bg]="!getFieldValue('img')">

      <a class="link" *ngIf="getLink(getFieldValue('link'))" [href]="getLink(getFieldValue('link'))"></a>
      <img alt="store" *ngIf="getFieldValue('img')"
           [style.object-position]="getFieldValue('background-position')"
           [style.object-fit]="getFieldValue('fit')"
           [class.full-width]="getFieldValue('full-width')"
           [style.height]="getFieldValue('height')+'rem'"
           [src]="urlStoreMedia(getFieldValue('img'))"/>
    </div>

  </div>

</div>
