export enum MediaType {
    COLLECTION = 'COLLECTION',
    PRODUCT = 'PRODUCT',
    VARIANT = 'VARIANT',
    STORE = 'STORE',
    REVIEW = 'REVIEW'
}


export enum MediaSize {
  small = 'small',
  original = 'original',
  medium = 'medium'
}
