import {Component, Input} from '@angular/core';
import {Address, AuthService, MediaSize, MediaType, OrderService, Pageable, urlMedia} from "@app/_shared";
import {Subscription} from "rxjs";
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  template: ''
})
export abstract class ShopyanOrdersComponent {

  subscription: Subscription = new Subscription();

  @Input()
  storeId: string;

  orders: any[];
  total: number;
  pageable = new Pageable();
  currentOrderId?: string;
  showMore: boolean;

  loading: boolean;

  protected constructor(protected formBuilder: FormBuilder,
                        protected router: Router,
                        protected orderService: OrderService,
                        protected authService: AuthService) {
  }

  init(): void {
    if (!this.authService.isAuthEnabled()) {
      this.router.navigate(['']).then();
    } else if (!this.authService.isAuthenticated()) {
      this.router.navigate(['login']).then();
    }
    this.pageable.size = 5;
    this.getOrders();
  }

  getOrders(showMore?: boolean): void {
    if (showMore) {
      this.pageable.page++;
    }
    this.loading = true;
    this.subscription.add(this.orderService.filterCustomerOrders(this.pageable).subscribe(response => {
      this.loading = false;
      if (showMore) {
        this.orders = [...this.orders, ...response.data];
      } else {
        this.orders = response.data;
      }
      this.total = response.total;
      this.showMore = response.data && response.data.length == this.pageable.size;
    }));
  }

  urlProductMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.PRODUCT, MediaSize.small, media);
  }

  urlVariantMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.VARIANT, MediaSize.small, media);
  }

  getAddressAsString(address: Address): string {
    let str = '';
    if (address.firstName) {
      str += address.firstName + ' ';
    }
    if (address.lastName) {
      str += address.lastName;
    }
    if (address.firstName || address.lastName) {
      str += '<br/>';
    }
    if (address.line1) {
      str += address.line1 + '<br/>';
    }
    if (address.line2) {
      str += address.line2 + '<br/>';
    }
    str += address.city;
    if (address.state) {
      str += ' ' + address.state;
    }
    str += '<br/>';
    if (address.zipCode) {
      str += address.zipCode + ' ';
    }
    str += address.country + '<br/>';
    if (address.phoneNumber) {
      str += address.phoneNumber;
    }
    return str;
  }


  toggleOrder(order: any): void {
    if (order.ref === this.currentOrderId) {
      delete this.currentOrderId;
    } else {
      this.currentOrderId = order.ref;
    }
  }


}
