import {Component, OnInit} from '@angular/core';
import {ComponentLookup, Link} from '@app/_shared';
import {DomSanitizer} from "@angular/platform-browser";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  selector: 'lib-neo-rich-text',
  templateUrl: './neo-rich-text.component.html',
  styleUrls: ['./neo-rich-text.component.scss']
})
@ComponentLookup('neo-rich-text')
export class NeoRichTextComponent extends ShopyanSectionComponent implements OnInit {

  constructor(private _sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
  }

  getLink(link: Link): any {
    let l = this.constructLink(link);
    return l ? this._sanitizer.bypassSecurityTrustResourceUrl(l) : undefined;
  }

}
