import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {ContactService, ShopyanToastrService} from "@app/_shared";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  template: ''
})
export abstract class ShopyanNewsLetterComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  formGroup: FormGroup;

  loader = false;


  protected constructor(private translate: TranslateService,
                        private formBuilder: FormBuilder,
                        private basicToastrService: ShopyanToastrService,
                        private contactService: ContactService) {
    super();
  }

  init(): void {
    this.formGroup = this.formBuilder.group({
      mail: [null, [Validators.required, Validators.email]],
    });
  }

  submit(): void {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      this.basicToastrService.error(this.translate.instant("COMMON.MESSAGE.FILL_REQUIRED_FIELDS"));
    }

    this.loader = true;
    this.subscription.add(this.contactService.subscribeToNewsletter(this.formGroup.get('mail')?.value).subscribe((response: any) => {
      this.basicToastrService.success(this.translate.instant("COMMON.MESSAGE.DONE"));
      this.loader = false;
      this.formGroup.reset();
    }));

  }


}
