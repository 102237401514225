import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Whatsapp} from "@app/store-apps/whatsapp/whatsapp.model";
import {isPlatformBrowser} from "@angular/common";
import {CommonService, StorageService} from "@app/_shared";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {
  whatsapp: Whatsapp;

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private commonService: CommonService,
              private storageService: StorageService) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Browser only - no private api call
      let url = `${environment.pubicStoreApiUrl}/applications/application-data/WHATSAPP`;
      this.commonService.get(url).subscribe((response: any) => {
        this.whatsapp = response;
      });
    }
  }

  get message(): string {
    let txt = this.whatsapp.message || '';
    if (txt) {
      txt += '%0a';
    }
    const productName = this.storageService.getData('current_product_name');
    if (productName) {
      txt += productName + '%0a';
    }
    txt += encodeURIComponent(window.location.href);
    return txt;
  }

}
