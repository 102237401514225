import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {constructMenuLink, MenuItem} from "@app/_shared";
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-neo-tree',
  templateUrl: './neo-tree.component.html',
  styleUrls: ['./neo-tree.component.scss']
})
export class NeoTreeComponent implements OnInit {

  @Input()
  items: MenuItem[];

  @Output()
  displayMobileOptionsEvent = new EventEmitter();

  private _transformer = (node: MenuItem, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      menuItem: node,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<FlatMenuNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private _sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.dataSource.data = this.items;
  }

  hasChild = (_: number, node: FlatMenuNode) => node.expandable;

  getLink(menuItem: MenuItem): any {
    return this._sanitizer.bypassSecurityTrustResourceUrl(constructMenuLink(menuItem));
  }

  menuAction(link?: string): void {
    if (link === '') {
      setTimeout(() => {
        this.displayMobileOptionsEvent.emit();
      }, 300);
    }
  }
}

export interface FlatMenuNode {
  expandable: boolean;
  menuItem: MenuItem;
  level: number;
}
