import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Upsell} from '@app/_shared';

@Component({
  selector: 'lib-neo-upsell-modal',
  templateUrl: './neo-upsell-modal.component.html',
  styleUrls: ['./neo-upsell-modal.component.scss']
})
export class NeoUpsellModalComponent implements OnInit {

  upsells: Upsell[];
  storeId: string;
  isExpressForm: boolean;

  slideConfig: any = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false
  };

  constructor(public dialogRef: MatDialogRef<NeoUpsellModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.upsells = this.data.upsells;
    this.storeId = this.data.storeId;
    this.isExpressForm = this.data.express;
  }

  /**
   *Close modal
   */
  closeModal(event?: any): void {
    this.dialogRef.close(event);
  }

}
