<div class="neo-modal">
  <div class="close-modal" (click)="closeModal()"></div>
  <div class="upsells-area">
    <ng-container *ngIf="upsells">
      <ngx-slick-carousel [config]="slideConfig">
        <ng-container *ngFor="let upsell of upsells">
          <div class="upsell-item" ngxSlickItem *ngFor="let product of upsell.offerProducts;">
            <lib-neo-upsell-mono-product [storeId]="storeId"
                                         [upsell]="upsell"
                                         [product]="product"
                                         [page]="data.page"
                                         [isExpress]="isExpressForm"
                                         (closeModalEvent)="closeModal($event)"></lib-neo-upsell-mono-product>
          </div>
        </ng-container>
      </ngx-slick-carousel>
    </ng-container>
  </div>

</div>
