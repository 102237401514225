import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Subscription} from "rxjs";
import {DomSanitizer, TransferState} from "@angular/platform-browser";
import {
  extractErrorMessagesFromResponse,
  OrderDetail,
  OrderService,
  ShopyanCartHelperService,
  ShopyanToastrService
} from "@app/_shared";
import {Router} from "@angular/router";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  template: ''
})
export abstract class ShopyanOrderSummaryComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();
  order: OrderDetail;
  loading: boolean;

  protected constructor(private _sanitizer: DomSanitizer,
                        private orderService: OrderService,
                        private basicToastr: ShopyanToastrService,
                        private router: Router,
                        protected basicHelperService: ShopyanCartHelperService,
                        private transferState: TransferState,
                        @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  init(): void {
    if (!this.buildMode) {
      this.loading = true;

      let orderId = this.router.url.split('/')[this.router.url.split('/').length - 1];
      if (!orderId) {
        this.router.navigateByUrl(`/not-found`).then();
      }
      this.subscription.add(this.orderService.findOrder(orderId).subscribe({
        next: (response: any) => {
          this.loading = false;
          this.order = response;
        }, error: (error: any) => {
          this.loading = false;
          this.basicToastr.error(extractErrorMessagesFromResponse(error));
        }
      }));
    }
  }


}

