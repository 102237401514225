import { HttpParams } from "@angular/common/http";

export class Pageable {
  page: number = 1;
  size: number = 20;
  sort?: string[] = [];

  getParams(): HttpParams {
    let params = new HttpParams();
    params = params.append('size', this.size);
    params = params.append('page', this.page - 1);
    this.sort?.forEach(item => {
      params = params.append('sort', item);
    });

    return params;
  }
}
