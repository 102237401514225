import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Pageable} from "@app/_shared";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {isPlatformServer} from "@angular/common";


@Injectable({providedIn: 'root'})
export class CollectionService {


  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: any) {
  }

  /**
   * Find featured collections
   * @param pageable
   */
  public filterCollections(pageable?: Pageable): Observable<any> {
    let url = `${this.getProductUrl()}/collections/filter`;
    let params = pageable ? pageable.getParams() : new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);

  }

  /**
   * Find featured collections
   * @param pageable
   */
  public findFeaturedCollections(pageable: Pageable): Observable<any> {
    let url = `${this.getProductUrl()}/collections/filter`;
    let params = pageable.getParams();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);

  }

  findCollection(id: string) {
    let url = `${this.getProductUrl()}/collections/consult/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  findCollectionsByIds(ids: string[]) {
    let url = `${this.getProductUrl()}/collections/by-ids`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);
  }

  private getProductUrl() {
    return isPlatformServer(this.platformId) ? environment.privateProductApiUrl : environment.pubicProductApiUrl;
  }

}
