import {Directive, TemplateRef, ViewContainerRef} from "@angular/core";
import {StorageService} from "@app/_shared";

@Directive({
  selector: '[addToCart]'
})
export class AddToCartDirective {

  constructor(private storageService: StorageService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
    if (this.storageService.getData('dis_add_to')) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
