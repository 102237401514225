import {Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {
  AuthService,
  ShopyanCartComponent,
  ShopyanCartHelperService,
  ShopyanToastrService,
  StorageService
} from '@app/_shared';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'lib-neo-cart-preview',
  templateUrl: './neo-cart-preview.component.html',
  styleUrls: ['./neo-cart-preview.component.scss']
})
export class NeoCartPreviewComponent extends ShopyanCartComponent implements OnInit {

  @Output()
  close = new EventEmitter();

  constructor(helperService: ShopyanCartHelperService,
              basicToastr: ShopyanToastrService,
              storageService: StorageService,
              authService: AuthService,
              http: HttpClient,
              router: Router,
              @Inject(PLATFORM_ID) platformId: any) {
    super(helperService, basicToastr, storageService, authService, http, router, platformId);
  }

  ngOnInit(): void {
    this.init();
  }

  initUpsells(): void {
    // Nothing to do here !
  }


  closeCart(): void {
    this.close.emit();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
