import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Pageable, Product, ProductService, ShopyanCartHelperService,} from "@app/_shared";
import {Subscription} from "rxjs";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {isPlatformServer} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanFeaturedProductsComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  products: Product[] = [];
  pageable: Pageable = new Pageable();
  loading: boolean;

  protected constructor(private productService: ProductService,
                        private shopyanCartHelperService: ShopyanCartHelperService,
                        private transferState: TransferState,
                        @Inject(PLATFORM_ID) protected platformId: any) {
    super();
  }

  init(): void {
    if (isPlatformServer(this.platformId) || this.buildMode) {
      if (this.getFieldsGroupValue('products-layout', 'max-products')) {
        this.pageable.size = this.getFieldsGroupValue('products-layout', 'max-products');
      }
      this.filterProducts();
    } else {
      this.products = this.transferState.get(makeStateKey(this.data.code+'-products'), null as any);
      this.shopyanCartHelperService.initDiscounts(this.products);
    }
  }

  /**
   * Filter products
   */
  filterProducts(): void {
    this.loading = true;
    this.subscription.add(this.productService.findFeaturedProducts(this.pageable).subscribe((response: any) => {
      this.loading = false;
      if (response) {
        this.products = response.data;
        this.shopyanCartHelperService.initDiscounts(this.products);
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(makeStateKey(this.data.code + '-products'), this.products as any);
        }
      }
    }));
  }


}
