import {Component, OnInit} from '@angular/core';
import {ComponentLookup} from '@app/_shared';
import {DomSanitizer} from "@angular/platform-browser";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  selector: 'lib-neo-page-content',
  templateUrl: './neo-page-content.component.html'
})
@ComponentLookup('neo-page-content')
export class NeoPageContentComponent extends ShopyanSectionComponent implements OnInit {

  htmlText: any;

  constructor(private sanitized: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
    const content = this.getFieldValue('content');
    if (content) {
      this.htmlText = this.sanitized.bypassSecurityTrustHtml(content);
    }
  }

}
