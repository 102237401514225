import {Component, OnInit} from '@angular/core';
import {ComponentLookup} from '@app/_shared';
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  selector: 'lib-neo-separator',
  templateUrl: './neo-separator.component.html',
  styleUrls: ['./neo-separator.component.scss']
})
@ComponentLookup('neo-separator')
export class NeoSeparatorComponent extends ShopyanSectionComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
