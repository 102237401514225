import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Subscription} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {
  AuthService,
  EventTypeEnum,
  extractErrorMessagesFromResponse,
  OrderDetail,
  PixelService,
  ShopyanCartHelperService,
  ShopyanToastrService,
  StorageService
} from "@app/_shared";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {ConversionService} from "@app/_shared/service/conversion.service";
import {isPlatformBrowser} from "@angular/common";

declare var fbq: Function;
declare var snaptr: Function;
declare var ttq: any;

@Component({
  template: ''
})
export abstract class ShopyanThankYouComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();
  order: OrderDetail;
  loading: boolean;

  protected constructor(private _sanitizer: DomSanitizer,
                        private activatedRoute: ActivatedRoute,
                        private http: HttpClient,
                        private authService: AuthService,
                        private storageService: StorageService,
                        private basicToastr: ShopyanToastrService,
                        private router: Router,
                        protected basicHelperService: ShopyanCartHelperService,
                        protected pixelService: PixelService,
                        protected conversionService: ConversionService,
                        @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  init(): void {

    if (!this.buildMode && isPlatformBrowser(this.platformId)) {

      this.loading = true;
      this.subscription.add(this.activatedRoute.queryParamMap.subscribe(
        queryParams => {
          const token = queryParams.get('token');
          const order = queryParams.get('order');
          // Browser only - no private api call
          let url = `${environment.pubicOrdersApiUrl}/checkout/success/${order}?token=${token}`;

          if (this.authService.isAuthenticated()) {
            url = `${environment.pubicOrdersApiUrl}/api/orders/checkout/success/${order}?token=${token}`;
          }
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          };
          this.subscription.add(this.http.post(url, null, httpOptions).subscribe({
            next: (response: any) => {
              this.loading = false;
              this.order = response;
              if (this.order.thankYou) {
                this.router.navigateByUrl(`/order-summary/${order}`).then();
                return;
              }

              // Check upsells
              this.initUpsells();

              //Send purchase event
              this.firePurchaseEvent();

              // Clear cart localstorage (!!Should always be after checking upsells, not before!!)
              this.storageService.removeData('car');

            }, error: (error: any) => {
              this.loading = false;
              this.basicToastr.error(extractErrorMessagesFromResponse(error));
            }
          }));
        }));

    }
  }


  firePurchaseEvent(): void {

    if (typeof fbq !== "undefined") {
      fbq('track', this.pixelService.facebookConversionType, {currency: this.order.currency, value: this.order.total});
    }

    if (typeof snaptr !== "undefined") {
      snaptr('track', 'PURCHASE');
    }

    if (typeof ttq !== "undefined") {
      ttq.track('Purchase', {
        contents: [],
        content_type: 'product',
        value: this.order.total,
        currency: this.order.currency,
      });
    }

    // Conversion api
    this.subscription.add(this.conversionService.sendBuyEvent(this.order).subscribe());

    // Browser only - no private api call
    let url = `${environment.pubicStoreApiUrl}/events`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };
    this.subscription.add(this.http
      .post(url, {
        eventType: EventTypeEnum.THANK_YOU
      }, httpOptions).subscribe());
  }

  protected abstract initUpsells(): void;

}

