<div [id]="data.code" class="neo-cancel shopyan-section" [class.build-mode]="buildMode">
  <div class="neo-section-layout v-padding">
    <div id="neo-cancel">
      <div class="ty-ic"></div>
      <div class="ty-ttl">{{'CANCEL.TITLE' | translate}}</div>
      <div class="ty-txt">{{'CANCEL.DESC' | translate}}</div>
      <a href="/search" class="neo-btn">{{'COMMON.GO_BACK' | translate}}</a>
    </div>
  </div>
</div>
