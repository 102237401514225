<div [id]="data.code" class="neo-favorite-products shopyan-section"
     [class.build-mode]="buildMode"
     [style.background]="getFieldsGroupValue('favorites-layout','background')">

  <div class="neo-section-layout v-padding"
       [class.large]="getFieldsGroupValue('favorites-layout','layout-width') == 'large'">

    <lib-neo-section-header
      *ngIf="getFieldsGroup('favorites-header') && !getFieldsGroup('favorites-header').hidden"
      [data]="getFieldsGroup('favorites-header')"></lib-neo-section-header>

    <div class="result-area" #layout>
      <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
      <ng-container *ngIf="products && products.length > 0">
        <div class="products"
             [style.grid-template-columns]="'repeat('+productsPerRow+', minmax(0, 1fr))'">
          <lib-neo-product-card
            *ngFor="let item of products;"
            [storeId]="storeID"
            [product]="item"
            [fields]="getFieldsGroup('favorites-layout')?.values || []"
          ></lib-neo-product-card>
        </div>
        <div class="show-more" *ngIf="showMore">
          <div class="neo-btn" (click)="filterProducts(true)">
            {{ 'FAVORITES.SHOW_MORE' | translate }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading && (!products || products.length == 0)">
        <lib-neo-no-result></lib-neo-no-result>
      </ng-container>
    </div>

  </div>
</div>
