import {Component, OnDestroy, OnInit} from '@angular/core';
import {ComponentLookup, Link, MediaSize, MediaType, ShopyanCartHelperService, urlMedia} from '@app/_shared';
import {DomSanitizer} from "@angular/platform-browser";
import {DeviceDetectorService} from "ngx-device-detector";
import {environment} from "../../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";

@Component({
  selector: 'lib-neo-carousel',
  templateUrl: './neo-carousel.component.html',
  styleUrls: ['./neo-carousel.component.scss']
})
@ComponentLookup('neo-carousel')
export class NeoCarouselComponent extends ShopyanSectionComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  slideConfig: any = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    "autoplay": true,
    "dots":true
  };

  displayCarousel = true;
  constructor(private translate: TranslateService,
              private shopyanHelper: ShopyanCartHelperService,
              private _sanitizer: DomSanitizer,
              private deviceDetectorService: DeviceDetectorService) {
    super();
  }

  ngOnInit(): void {
    this.initLTR();
    this.subscription.add(this.shopyanHelper.langSubject.subscribe((next: any) => {
      this.displayCarousel = false;
      this.initLTR();
      setTimeout(() => {
        this.displayCarousel = true;
      }, 300);
    }));
  }

  initLTR(): void {
    this.slideConfig.rtl = this.translate.currentLang === 'ar';
  }

  get mobile(): boolean {
    return this.deviceDetectorService.isMobile() || window.innerWidth < 600;
  }

  getLink(link: Link): any {
    let l = this.constructLink(link);
    return l ? this._sanitizer.bypassSecurityTrustResourceUrl(l) : undefined;
  }

  urlStoreMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.STORE, MediaSize.original, media);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
