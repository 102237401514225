<div [id]="data.code" class="neo-multi-columns shopyan-section" [class.build-mode]="buildMode">

  <div class="neo-section-layout" #layout
       [class.large]="getFieldsGroupValue('layout','layout-width') == 'large'">

    <div class="columns-container"
         [style.background]="getFieldsGroupValue('layout','background')"
         [style.grid-template-columns]="'repeat('+itemsPerRow+', minmax(0, 1fr))'"
         [style.margin-right]="getFieldsGroupValue('layout','margin-right')+'px'"
         [style.margin-left]="getFieldsGroupValue('layout','margin-left')+'px'"
         [style.border-radius]="getFieldsGroupValue('layout','border-radius')+'px'">


    <ng-container *ngFor="let block of data.blocks; let index = index;">
        <div class="column-item" *ngIf="!block.hidden"
             [style.text-align]="getFieldsGroupValue('layout','column-justify-content')"
             [style.border-radius]="getBlockFieldValueByIndex(index,'border-radius')+'px'"
             [style.background-color]="getBlockFieldValueByIndex(index,'background-color')">

          <img alt="store"  class="bg-img"
               *ngIf="getBlockFieldValueByIndex(index, 'background-image')"
               [src]="urlStoreMedia(getBlockFieldValueByIndex(index, 'background-image'))"
               [style.object-fit]="getBlockFieldValueByIndex(index,'background-size')"/>

          <div class="ci-image" *ngIf="getBlockFieldValueByIndex(index, 'image')">
            <img alt="store"  [src]="urlStoreMedia(getBlockFieldValueByIndex(index, 'image'))"
                 [style.object-fit]="getBlockFieldValueByIndex(index,'img-fit')"/>
          </div>
          <div class="ci-label"
               [style.color]="getBlockFieldValueByIndex(index, 'title-color')"
               [style.font-size]="getBlockFieldValueByIndex(index, 'title-font-size')+'px'">
            {{getBlockFieldValueByIndex(index, 'title')}}
          </div>
          <div class="ci-desc"
               [style.margin-top]="getBlockFieldValueByIndex(index, 'desc-margin-top')+'px'">
            <p
              [style.font-size]="getBlockFieldValueByIndex(index, 'desc-font-size')+'px'"
              [style.color]="getBlockFieldValueByIndex(index, 'desc-color')">
              {{getBlockFieldValueByIndex(index, 'desc')}}
            </p>
          </div>

          <a class="neo-btn" [href]="getLink(getBlockFieldValueByIndex(index, 'btn-url'))"
             [style.margin-top]="getBlockFieldValueByIndex(index, 'btn-margin-top')+'px'"
             *ngIf="getBlockFieldValueByIndex(index, 'btn-label')">
            {{getBlockFieldValueByIndex(index, 'btn-label')}}
          </a>

        </div>
      </ng-container>


    </div>

  </div>
</div>
