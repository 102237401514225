import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {CheckoutForm, CommonService, Country, Customer, StoreAccountMode} from '@app/_shared';
import {FormGroup} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {isPlatformServer} from "@angular/common";

@Component({
  selector: 'lib-neo-checkout-classic',
  templateUrl: './neo-checkout-classic.component.html',
  styleUrls: ['./neo-checkout-classic.component.scss']
})
export class NeoCheckoutClassicComponent implements OnInit {

  @Input()
  customer?: Customer;

  @Input()
  storeAccountMode: StoreAccountMode;

  @Input()
  shippingAddressFG: FormGroup;

  @Input()
  billingAddressFG: FormGroup;

  @Input()
  checkoutForm: CheckoutForm;

  storeAccountModeEnum = StoreAccountMode;

  countries: Country[] = [];

  avatar: string = '';

  constructor(private commonService: CommonService,
              @Inject(PLATFORM_ID) private platformId: any) {
  }

  ngOnInit(): void {

    this.checkoutForm.existingShippingAddress = '';
    this.checkoutForm.existingBillingAddress = '';

    const url = `${isPlatformServer(this.platformId) ? environment.privateStoreApiUrl : environment.pubicStoreApiUrl}/refData/countries`
    this.commonService.get(url).subscribe(data => this.countries = data);

    if (this.customer && this.customer.addresses && this.customer.addresses.length > 0) {
      let defaultId = this.customer.addresses.find(item => item.defaultAddress)?.id;
      if (defaultId) {
        this.checkoutForm.existingShippingAddress = defaultId;
      } else {
        this.checkoutForm.existingShippingAddress = this.customer.addresses[0].id || '';
      }
    }

    if (this.customer) {
      if (this.customer.firstName) {
        this.avatar = this.customer.firstName.toUpperCase().charAt(0);
      }
      if (this.customer.lastName) {
        this.avatar = this.customer.lastName.toUpperCase().charAt(0);
      }
    }

  }


}
