import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Pageable} from "@app/_shared";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {isPlatformServer} from "@angular/common";


@Injectable({providedIn: 'root'})
export class ProductService {


  constructor(@Inject(PLATFORM_ID) private platformId: any, private http: HttpClient) {
  }

  /**
   * Find featured products (to do change the api to get the real featured ones)
   * @param pageable
   */
  public findFeaturedProducts(pageable: Pageable): Observable<any> {
    let url = `${this.getProductUrl()}/products/filter`;
    let params = pageable.getParams();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);
  }

  /**
   * Filter products
   * @param pageable
   * @param filter
   * @param collection
   */
  public filterProducts(pageable: Pageable, collection?: string, filter?: string): Observable<any> {
    let url = `${this.getProductUrl()}/products/filter`;
    let params = pageable.getParams();
    if (filter) {
      params = params.append('filter', filter);
    }
    if (collection) {
      params = params.append('collection', collection);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);
  }

  public findRelatedProducts(path: string): Observable<any> {
    let url = `${this.getProductUrl()}/products/related-products`;

    let params = new HttpParams();
    params = params.append('path', path);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  public findCollectionProducts(pageable: Pageable, collection: string): Observable<any> {
    let url = `${this.getProductUrl()}/products/filter`;
    let params = pageable.getParams();
    params = params.append('collection', collection);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);
  }

  findProduct(id: string, optional: boolean) {
    let url = `${this.getProductUrl()}/products/`;
    if (optional) {
      url = url + `optional/${id}`;
    } else {
      url = url + `${id}`
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  findProductBySlug(slug: string) {
    let url = `${this.getProductUrl()}/products/by-slug/${slug}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  getProductIdBySlug(slug: String) {
    let url = `${this.getProductUrl()}/products/get-product-id/${slug}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };
    return this.http.get(url, httpOptions);
  }

  findRandomProduct() {
    let url = `${this.getProductUrl()}/products/random-product`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  findProductsByIds(ids: string[]) {
    let url = `${this.getProductUrl()}/products/by-ids`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);
  }


  findFavoriteProducts(ids: string[]) {
    let url = `${this.getProductUrl()}/products/by-ids`;
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

  public findProductReviews(productId: string, pageable: Pageable): Observable<any> {
    let url = `${this.getProductUrl()}/reviews/${productId}`;
    let params = pageable.getParams();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);
  }

  private getProductUrl() {
    return isPlatformServer(this.platformId) ? environment.privateProductApiUrl : environment.pubicProductApiUrl;
  }
}
