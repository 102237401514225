<div [id]="data.code" class="neo-countdown shopyan-section animated tdFadeInUp"
     [class.build-mode]="buildMode"
     [style.background-color]="getFieldValue('section-background-color')">
  <div class="neo-section-layout large">
    <div id="neo-countdown-title" *ngIf="htmlText">
      <div [innerHtml]="htmlText"></div>
    </div>
    <div id="neo-countdown-container"
         [class.shake-items]="getFieldValue('shake-items')"
         [class.circle]="getFieldValue('circle-mode')"
         [class.standard]="!getFieldValue('circle-mode')">
      <div class="time-item">
        <div class="ti-value"
             [style.border-radius]="getFieldValue('border-radius')+'px'"
             [style.background-color]="getFieldValue('background-color')"
             [style.border-color]="getFieldValue('border-color')"
             [style.width]="getFieldValue('width')+'px'"
             [style.line-height]="getFieldValue('height')+'px'"
             [style.font-size]="getFieldValue('font-size')+'px'"
             [style.color]="getFieldValue('color')">
          <div [style.border-radius]="getFieldValue('border-radius')+'px'"
               [style.background-color]="getFieldValue('background-color')"
               [style.border-color]="getFieldValue('border-color')"
               [style.width]="getFieldValue('width')+'px'"
               [style.line-height]="getFieldValue('height')+'px'"
               [style.font-size]="getFieldValue('font-size')+'px'"
               [style.color]="getFieldValue('color')">
            {{Math.floor(days / 10)}}
          </div>
          <div [style.border-radius]="getFieldValue('border-radius')+'px'"
               [style.background-color]="getFieldValue('background-color')"
               [style.border-color]="getFieldValue('border-color')"
               [style.width]="getFieldValue('width')+'px'"
               [style.line-height]="getFieldValue('height')+'px'"
               [style.font-size]="getFieldValue('font-size')+'px'"
               [style.color]="getFieldValue('color')">
            {{days % 10}}
          </div>
        </div>
        <div class="ti-label"
             [style.font-size]="getFieldValue('label-font-size')+'px'"
             [style.color]="getFieldValue('label-color')">{{'COUNTDOWN.DAYS' | translate}}
        </div>
      </div>
      <div class="sep"
           [style.line-height]="getFieldValue('height')+'px'"
           [style.font-size]="getFieldValue('sep-font-size')+'px'"
           [style.color]="getFieldValue('sep-color')">:
      </div>
      <div class="time-item">
        <div class="ti-value"
             [style.border-radius]="getFieldValue('border-radius')+'px'"
             [style.background-color]="getFieldValue('background-color')"
             [style.border-color]="getFieldValue('border-color')"
             [style.width]="getFieldValue('width')+'px'"
             [style.line-height]="getFieldValue('height')+'px'"
             [style.font-size]="getFieldValue('font-size')+'px'"
             [style.color]="getFieldValue('color')">
          <div [style.border-radius]="getFieldValue('border-radius')+'px'"
               [style.background-color]="getFieldValue('background-color')"
               [style.border-color]="getFieldValue('border-color')"
               [style.width]="getFieldValue('width')+'px'"
               [style.line-height]="getFieldValue('height')+'px'"
               [style.font-size]="getFieldValue('font-size')+'px'"
               [style.color]="getFieldValue('color')">
            {{Math.floor(hours / 10)}}
          </div>
          <div [style.border-radius]="getFieldValue('border-radius')+'px'"
               [style.background-color]="getFieldValue('background-color')"
               [style.border-color]="getFieldValue('border-color')"
               [style.width]="getFieldValue('width')+'px'"
               [style.line-height]="getFieldValue('height')+'px'"
               [style.font-size]="getFieldValue('font-size')+'px'"
               [style.color]="getFieldValue('color')">
            {{hours % 10}}
          </div>
        </div>
        <div class="ti-label"
             [style.font-size]="getFieldValue('label-font-size')+'px'"
             [style.color]="getFieldValue('label-color')">{{'COUNTDOWN.HOURS' | translate}}
        </div>
      </div>
      <div class="sep"
           [style.line-height]="getFieldValue('height')+'px'"
           [style.font-size]="getFieldValue('sep-font-size')+'px'"
           [style.color]="getFieldValue('sep-color')">:
      </div>
      <div class="time-item">
        <div class="ti-value"
             [style.border-radius]="getFieldValue('border-radius')+'px'"
             [style.background-color]="getFieldValue('background-color')"
             [style.border-color]="getFieldValue('border-color')"
             [style.width]="getFieldValue('width')+'px'"
             [style.line-height]="getFieldValue('height')+'px'"
             [style.font-size]="getFieldValue('font-size')+'px'"
             [style.color]="getFieldValue('color')">
          <div [style.border-radius]="getFieldValue('border-radius')+'px'"
               [style.background-color]="getFieldValue('background-color')"
               [style.border-color]="getFieldValue('border-color')"
               [style.width]="getFieldValue('width')+'px'"
               [style.line-height]="getFieldValue('height')+'px'"
               [style.font-size]="getFieldValue('font-size')+'px'"
               [style.color]="getFieldValue('color')">
            {{Math.floor(minutes / 10)}}
          </div>
          <div [style.border-radius]="getFieldValue('border-radius')+'px'"
               [style.background-color]="getFieldValue('background-color')"
               [style.border-color]="getFieldValue('border-color')"
               [style.width]="getFieldValue('width')+'px'"
               [style.line-height]="getFieldValue('height')+'px'"
               [style.font-size]="getFieldValue('font-size')+'px'"
               [style.color]="getFieldValue('color')">
            {{minutes % 10}}
          </div>
        </div>
        <div class="ti-label"
             [style.font-size]="getFieldValue('label-font-size')+'px'"
             [style.color]="getFieldValue('label-color')">{{'COUNTDOWN.MINUTES' | translate}}
        </div>
      </div>
      <div class="sep"
           [style.line-height]="getFieldValue('height')+'px'"
           [style.font-size]="getFieldValue('sep-font-size')+'px'"
           [style.color]="getFieldValue('sep-color')">:
      </div>
      <div class="time-item">
        <div class="ti-value"
             [style.border-radius]="getFieldValue('border-radius')+'px'"
             [style.background-color]="getFieldValue('background-color')"
             [style.border-color]="getFieldValue('border-color')"
             [style.width]="getFieldValue('width')+'px'"
             [style.line-height]="getFieldValue('height')+'px'"
             [style.font-size]="getFieldValue('font-size')+'px'"
             [style.color]="getFieldValue('color')">
          <div [style.border-radius]="getFieldValue('border-radius')+'px'"
               [style.background-color]="getFieldValue('background-color')"
               [style.border-color]="getFieldValue('border-color')"
               [style.width]="getFieldValue('width')+'px'"
               [style.line-height]="getFieldValue('height')+'px'"
               [style.font-size]="getFieldValue('font-size')+'px'"
               [style.color]="getFieldValue('color')">
            {{Math.floor(seconds / 10)}}
          </div>
          <div [style.border-radius]="getFieldValue('border-radius')+'px'"
               [style.background-color]="getFieldValue('background-color')"
               [style.border-color]="getFieldValue('border-color')"
               [style.width]="getFieldValue('width')+'px'"
               [style.line-height]="getFieldValue('height')+'px'"
               [style.font-size]="getFieldValue('font-size')+'px'"
               [style.color]="getFieldValue('color')">
            {{(seconds % 10)}}
          </div>
        </div>
        <div class="ti-label"
             [style.font-size]="getFieldValue('label-font-size')+'px'"
             [style.color]="getFieldValue('label-color')">{{'COUNTDOWN.SECONDS' | translate}}
        </div>
      </div>
    </div>
  </div>

</div>
