<div [id]="data.code" class="neo-register shopyan-section" [class.build-mode]="buildMode">
  <form class="form-container" [formGroup]="registrationFormGroup" *ngIf="registrationFormGroup">
    <div class="form-label">
      <div>{{'AUTH.REGISTER.TITLE' | translate}}</div>
      <span>{{'AUTH.REGISTER.INTRO' | translate}}</span>
    </div>
    <div class="neo-alert ERROR" *ngIf="errorMsg">
      {{errorMsg}}.
    </div>
    <div class="neo-field">
      <input InputValidator type="text" class="neo-input" placeholder="{{'AUTH.REGISTER.FIRST_NAME' | translate}}" formControlName="firstName"/>
      <app-field-error controlName="firstName" errorKey="required">
        {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
      </app-field-error>
      <app-field-error controlName="firstName" errorKey="maxlength">
        {{'COMMON.ERROR.TOO_LONG' | translate}}
      </app-field-error>
    </div>

    <div class="neo-field">
      <input InputValidator type="text" class="neo-input" placeholder="{{'AUTH.REGISTER.LAST_NAME' | translate}}" formControlName="lastName"/>
      <app-field-error controlName="lastName" errorKey="maxlength">
        {{'COMMON.ERROR.TOO_LONG' | translate}}
      </app-field-error>
    </div>

    <div class="neo-field">
      <input InputValidator type="text" class="neo-input" placeholder="{{'AUTH.REGISTER.EMAIL' | translate}}" formControlName="email"/>
      <app-field-error controlName="email" errorKey="required">
        {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
      </app-field-error>
      <app-field-error controlName="email" errorKey="pattern">
        {{'COMMON.ERROR.INVALID_MAIL' | translate}}
      </app-field-error>
      <app-field-error controlName="email" errorKey="maxlength">
        {{'COMMON.ERROR.TOO_LONG' | translate}}
      </app-field-error>
    </div>

    <div class="neo-field password-field">
      <div class="password-toggle" (click)="togglePassword()" [class.to-hide]="passwordVisible"></div>
      <input InputValidator [type]="passwordVisible ? 'text' : 'password'" class="neo-input" placeholder="{{'AUTH.REGISTER.PASSWORD' | translate}}"
             formControlName="password"/>
      <app-field-error controlName="password" errorKey="required">
        {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
      </app-field-error>
      <app-field-error controlName="password" errorKey="minlength">
        {{'COMMON.ERROR.TOO_SHORT' | translate}}
      </app-field-error>
      <app-field-error controlName="password" errorKey="maxlength">
        {{'COMMON.ERROR.TOO_LONG' | translate}}
      </app-field-error>
    </div>

    <div class="warn">
      {{'AUTH.REGISTER.ACCEPT' | translate}} <a href="#">{{'AUTH.REGISTER.TERMS_OF_USE' | translate}}</a> {{'AUTH.REGISTER.AND_THE' | translate}}
      <a href="#">{{'AUTH.REGISTER.PRIVACY_POLICY' | translate}}</a> {{'AUTH.REGISTER.OF_THE_STORE' | translate}}
    </div>

    <button loadingBtn [style.background-color]="getFieldValue('btn-color')"
            [loading]="loading" (click)="submitRegistrationForm()" type="submit" class="neo-btn submit">
      {{'AUTH.REGISTER.CREATE_ACCOUNT' | translate}}
    </button>

    <div class="switch-page">
      {{'AUTH.REGISTER.ALREADY' | translate}}
      <a [routerLink]="['/login']">{{'AUTH.REGISTER.SIGN_IN' | translate}}</a>
    </div>
  </form>
</div>
