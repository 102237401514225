import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, ComponentLookup, ShopyanRegisterComponent, StorageService} from '@app/_shared';
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lib-neo-register',
  templateUrl: './neo-register.component.html',
  styleUrls: ['./neo-register.component.scss']
})

@ComponentLookup('neo-register')
export class NeoRegisterComponent extends ShopyanRegisterComponent implements OnInit, OnDestroy {


  constructor(translate: TranslateService,
              formBuilder: FormBuilder,
              authService: AuthService,
              storageService: StorageService,
              router: Router) {
    super(translate, formBuilder, authService, storageService, router);
  }

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
