<div [id]="data.code" class="neo-header shadow shopyan-section"
     [class.build-mode]="buildMode"
     [class.shadow]="getFieldsGroupValue('header-layout','has-shadow') == true"
     [style.background-color]="getFieldsGroupValue('header-layout', 'background-color')"
     [style.padding-top]="getFieldsGroupValue('header-layout','padding-top')+'px'"
     [style.padding-bottom]="getFieldsGroupValue('header-layout','padding-bottom')+'px'"
     [class.sticky]="getFieldsGroupValue('header-layout','sticky-header') == true">

  <div class="neo-section-layout neo-header-layout"
       [class.large]="getFieldsGroupValue('header-layout','layout-width') == 'large'"
       [style.padding-left]="getFieldsGroupValue('header-layout','padding-left')+'px'"
       [style.padding-right]="getFieldsGroupValue('header-layout','padding-right')+'px'">

    <!-- DESKTOP HEADER -->
    <div class="neo-header-content">
      <div class="h-logo">
        <a href="/">
          <span
            *ngIf="getFieldsGroupValue('logo-block','text-logo-content') && !getFieldsGroupValue('logo-block','logo-img')"
            [class.neo-bold]="getFieldsGroupValue('logo-block','text-logo-bold') == true"
            [class.neo-upper]="getFieldsGroupValue('logo-block','text-logo-upper') == true"
            [style.color]="getFieldsGroupValue('logo-block','text-logo-color')"
            [style.font-size]="getFieldsGroupValue('logo-block','text-logo-size')+'px'">
            {{getFieldsGroupValue('logo-block', 'text-logo-content')}}
          </span>
          <div class="store-logo"
               *ngIf="getFieldsGroupValue('logo-block','logo-img') || !getFieldsGroupValue('logo-block','text-logo-content')"
               [class.default-logo]="!getFieldsGroupValue('logo-block','logo-img')">
            <img alt="store" *ngIf="getFieldsGroupValue('logo-block','logo-img')"
                 [style.object-fit]="getFieldsGroupValue('logo-block','logo-fit')"
                 [style.width]="getFieldsGroupValue('logo-block','logo-width')+'px'"
                 [style.height]="getFieldsGroupValue('logo-block','logo-height')+'px'"
                 [src]="urlStoreMedia(getFieldsGroupValue('logo-block','logo-img'))"/>
          </div>
        </a>
      </div>
      <div class="h-menu">
        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="item.children && item.children.length > 0; else simpleLink">
            <div class="menu-item"
                 [matMenuTriggerFor]="menu.childMenu"
                 [style.color]="getFieldsGroupValue('menu-block', 'menu-color')+'!important'"
                 [style.font-size]="getFieldsGroupValue('menu-block','menu-font-size')+'px'"
                 [class.menu-bold]="getFieldsGroupValue('menu-block','menu-bold') == true"
                 [class.neo-upper]="getFieldsGroupValue('menu-block','menu-upper') == true">
              <div>{{item.label}}</div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 64 64" viewBox="0 0 64 64"
                     id="arrow">
                  <path [attr.fill]="getFieldsGroupValue('menu-block', 'menu-color')" d="m-218.7-308.6 2-2 11.7 11.8 11.7-11.8 2 2-13.7 13.7-13.7-13.7"
                        transform="translate(237 335)"></path>
                </svg>
              </div>
            </div>
            <span style="position: absolute">
              <app-neo-menu-item #menu [items]="item.children"></app-neo-menu-item>
            </span>
          </ng-container>
          <ng-template #simpleLink>
            <a [href]="getLink(item)" class="menu-item"
               [style.color]="getFieldsGroupValue('menu-block', 'menu-color')+'!important'"
               [style.font-size]="getFieldsGroupValue('menu-block','menu-font-size')+'px'"
               [class.menu-bold]="getFieldsGroupValue('menu-block','menu-bold') == true"
               [class.neo-upper]="getFieldsGroupValue('menu-block','menu-upper') == true">
              {{item.label}}
            </a>
          </ng-template>
        </ng-container>
      </div>
      <div class="h-icons" [class.white-icons]="getFieldsGroupValue('icons-block','white-icons') == true">
        <div class="ic-item search"
             *ngIf="getFieldsGroupValue('icons-block','hide-search') != true"
             (click)="displaySearch = !displaySearch;">
        </div>
        <div class="ic-item lang" [matMenuTriggerFor]="localeMenu" *ngIf="locales.length > 1">
          <div class="flag" *ngIf="selectedLang" [ngClass]="selectedLang.code">
          </div>
        </div>
        <a class="ic-item wishlist" href="/wishlist"
           *ngIf="getFieldsGroupValue('icons-block','hide-wishlist') != true">
          <div class="lbl">wishlist</div>
          <div class="count" *ngIf="favoritesCount > 0"
               [style.background-color]="getFieldsGroupValue('icons-block','count-background')"
               [style.color]="getFieldsGroupValue('icons-block','count-color')">
            <span *ngIf="favoritesCount > 9">{{favoritesCount}}</span>
            <span *ngIf="favoritesCount < 10">0{{favoritesCount}}</span>
          </div>
        </a>
        <div class="ic-item cart" (click)="showCartPreview()" *addToCart>
          <div class="count" *ngIf="cartCount > 0"
               [style.background-color]="getFieldsGroupValue('icons-block','count-background')"
               [style.color]="getFieldsGroupValue('icons-block','count-color')">
            <span *ngIf="cartCount > 9">{{cartCount}}</span>
            <span *ngIf="cartCount < 10">0{{cartCount}}</span>
          </div>
          <span #cartPreviewTrigger="matMenuTrigger" [matMenuTriggerFor]="cartPreview"></span>
        </div>
        <ng-container *ngIf="isAuthEnabled">
          <div class="ic-item account" *ngIf="isAuthenticated" [matMenuTriggerFor]="accountMenu">
          </div>
          <a href="/login" class="ic-item account" *ngIf="!isAuthenticated">
            login
          </a>
        </ng-container>
      </div>
    </div>

    <!-- MOBILE HEADER -->
    <div class="neo-mobile-header-content">
      <div class="h-options"
           [class.white-icons]="getFieldsGroupValue('icons-block','white-icons') == true"
           (click)="displayMobileOptions = true">
      </div>
      <div class="h-logo">
        <a href="/">
          <span
            *ngIf="getFieldsGroupValue('logo-block','text-logo-content') && !getFieldsGroupValue('logo-block','logo-img')"
            [class.neo-bold]="getFieldsGroupValue('logo-block','text-logo-bold') == true"
            [class.neo-upper]="getFieldsGroupValue('logo-block','text-logo-upper') == true"
            [style.color]="getFieldsGroupValue('logo-block','text-logo-color')"
            [style.font-size]="getFieldsGroupValue('logo-block','text-logo-size')+'px'">
            {{getFieldsGroupValue('logo-block', 'text-logo-content')}}
          </span>
          <div class="store-logo"
               *ngIf="getFieldsGroupValue('logo-block','logo-img') || !getFieldsGroupValue('logo-block','text-logo-content')"
               [class.default-logo]="!getFieldsGroupValue('logo-block','logo-img')">
            <img alt="store" *ngIf="getFieldsGroupValue('logo-block','logo-img')"
                 [style.object-fit]="getFieldsGroupValue('logo-block','logo-fit')"
                 [style.width]="getFieldsGroupValue('logo-block','logo-width')+'px'"
                 [style.height]="getFieldsGroupValue('logo-block','logo-height')+'px'"
                 [src]="urlStoreMedia(getFieldsGroupValue('logo-block','logo-img'))"/>
          </div>
        </a>
      </div>
      <div class="h-icons"
           [class.white-icons]="getFieldsGroupValue('icons-block','white-icons') == true">
        <div class="ic-item lang" [matMenuTriggerFor]="mobileLocaleMenu" *ngIf="locales.length > 1">
          <div class="flag" *ngIf="selectedLang" [ngClass]="selectedLang.code">
          </div>
        </div>
        <div class="ic-item cart"
             *addToCart (click)="showCartPreview()">
          <div class="count" *ngIf="cartCount > 0"
               [style.background-color]="getFieldsGroupValue('icons-block','count-background')"
               [style.color]="getFieldsGroupValue('icons-block','count-color')">
            <span *ngIf="cartCount > 9">{{cartCount}}</span>
            <span *ngIf="cartCount < 10">0{{cartCount}}</span>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="search-layout animated tdFadeInDown" *ngIf="displaySearch">
    <div class="neo-section-layout">
      <div class="search-top">
        <span>{{ 'HEADER.SEARCH.TITLE' | translate }}</span>
        <div class="close-search-bar" (click)="displaySearch = false"></div>
      </div>
      <div class="search-bar">
        <div class="search-ic" (click)="search()"></div>
        <input type="text" placeholder="{{ 'HEADER.SEARCH.PLACEHOLDER' | translate }}" (keydown.enter)="search()"
               [(ngModel)]="filter"/>
      </div>
    </div>

  </div>

</div>

<div id="over-div" *ngIf="displayMobileOptions || displayMobileCartContent"
     (click)="displayMobileOptions = false; displayMobileCartContent  = false"></div>

<mat-menu #cartPreview>
  <div id="cart-preview" [dir]="direction" (click)="$event.stopPropagation()">
    <lib-neo-cart-preview [storeID]="storeID" *ngIf="displayCartContent"></lib-neo-cart-preview>
  </div>
</mat-menu>

<div id="mobile-cart-preview" [class.open]="displayMobileCartContent">
  <lib-neo-cart-preview [storeID]="storeID" *ngIf="displayMobileCartContent"
                        (close)="displayMobileCartContent = false"></lib-neo-cart-preview>
</div>

<mat-menu #accountMenu>
  <div id="my-account">
    <a mat-menu-item [routerLink]="['/account']">{{ 'HEADER.MENU.MY_ACCOUNT' | translate }}</a>
    <div (click)="logout()" mat-menu-item>{{ 'HEADER.MENU.LOG_OUT' | translate }}</div>
  </div>
</mat-menu>

<div id="mobile-options" [class.open]="displayMobileOptions">
  <div class="close-area" (click)="displayMobileOptions = false">
    <div class="lbl">{{ 'HEADER.MENU.CLOSE' | translate }}</div>
    <div class="ic"></div>
  </div>
  <div class="options-area">
    <div class="search-area" *ngIf="getFieldsGroupValue('icons-block','hide-search') != true">
      <div class="search-ic" (click)="search()"></div>
      <input type="text" placeholder="{{ 'HEADER.SEARCH.PLACEHOLDER' | translate }}" (keydown.enter)="search()"
             [(ngModel)]="filter"/>
    </div>
    <div class="menus" *ngIf="menuItems && menuItems.length > 0">
      <app-neo-tree (displayMobileOptionsEvent)="displayMobileOptions = false" [items]="menuItems"></app-neo-tree>
    </div>
    <div class="account" *ngIf="isAuthEnabled || getFieldsGroupValue('icons-block','hide-wishlist') != true">
      <a class="option-item" href="/wishlist" *ngIf="getFieldsGroupValue('icons-block','hide-wishlist') != true">
        <div class="oi-lbl">{{ 'HEADER.MENU.WISHLIST' | translate }}</div>
        <div class="oi-arrow"></div>
      </a>
      <ng-container *ngIf="isAuthEnabled">
        <ng-container *ngIf="isAuthenticated">
          <a class="option-item" [routerLink]="['/account']">
            <div class="oi-lbl">{{ 'HEADER.MENU.MY_ACCOUNT' | translate }}</div>
            <div class="oi-arrow"></div>
          </a>
          <div class="option-item" (click)="logout()">
            <div class="oi-lbl">{{ 'HEADER.MENU.LOG_OUT' | translate }}</div>
            <div class="oi-arrow"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isAuthenticated">
          <a class="option-item" [routerLink]="['/login']">
            <div class="oi-lbl">{{ 'HEADER.MENU.SIGN_IN' | translate }}</div>
            <div class="oi-arrow"></div>
          </a>
          <a class="option-item" [routerLink]="['/register']">
            <div class="oi-lbl">{{ 'HEADER.MENU.REGISTER' | translate }}</div>
            <div class="oi-arrow"></div>
          </a>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<!-- LOCALE MENU -->
<mat-menu #localeMenu>
  <div class="locale-menu">
    <div class="locale-item" mat-menu-item (click)="changeLanguage(locale.code)" *ngFor="let locale of locales">
      <div class="flag" [ngClass]="locale.code"></div>
      <span>{{locale.name}}</span>
    </div>
  </div>
</mat-menu>

<!-- LOCALE MENU -->
<mat-menu #mobileLocaleMenu>
  <div class="locale-menu">
    <div class="locale-item" mat-menu-item (click)="changeLanguage(locale.code)" *ngFor="let locale of locales">
      <div class="flag" [ngClass]="locale.code"></div>
      <span>{{locale.name}}</span>
    </div>
  </div>
</mat-menu>
