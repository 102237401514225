import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {
  CollectionService,
  ComponentLookup,
  getNeoCollectionsPerRow,
  getNeoProductsPerRow,
  ShopyanCollectionListComponent
} from '@app/_shared';
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";

@Component({
  selector: 'lib-neo-collection-list',
  templateUrl: './neo-collection-list.component.html',
  styleUrls: ['./neo-collection-list.component.scss']
})

@ComponentLookup('neo-collection-list')
export class NeoCollectionListComponent extends ShopyanCollectionListComponent implements OnInit, OnDestroy , AfterViewInit {

  collectionsPerRow: number = 3;

  @ViewChild("layout")
  layout: ElementRef;

  constructor(collectionService: CollectionService,
              transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any) {
    super(collectionService, transferState, platformId);
  }

  ngOnInit(): void {
    this.pageable.size = 9;
    if(isPlatformServer(this.platformId) || this.buildMode) {
      this.findCollections();
    }else{
      this.collections = this.transferState.get(makeStateKey(this.data.code + '-collections'), null as any);
      this.showMore = this.transferState.get(makeStateKey(this.data.code + '-showMore'), null as any);
    }
  }

  ngAfterViewInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.onResize();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let total = this.getFieldsGroupValue('layout', 'collections-per-row') || 3;
    this.collectionsPerRow = getNeoCollectionsPerRow(this.layout.nativeElement.offsetWidth, total);
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
