<div [id]="data.code" class="neo-text-banner shopyan-section animated tdFadeInUp"
     [class.build-mode]="buildMode">

  <div class="neo-section-layout large v-padding"
       [style.min-height]="getFieldsGroupValue('layout','min-height')+'rem'"
       [style.border-radius]="getFieldsGroupValue('layout','border-radius')+'px'"
       [style.background-color]="getFieldsGroupValue('layout','background-color')"
       [style.margin-left]="getFieldsGroupValue('layout','margin-left')+'px'"
       [style.margin-right]="getFieldsGroupValue('layout','margin-right')+'px'">

    <ng-container *ngFor="let block of data.blocks; let index = index">
      <div class="banner-item animated tdStampIn" *ngIf="selectedBlockIndex == index">
        <div class="text-title"
             [style.font-size]="getBlockFieldValueByIndex(selectedBlockIndex,'title-size')+'px'"
             [style.color]="getBlockFieldValueByIndex(selectedBlockIndex,'title-color')">
          {{getBlockFieldValueByIndex(selectedBlockIndex, 'title')}}
        </div>

        <div class="text-desc"
             [style.margin-top]="getBlockFieldValueByIndex(selectedBlockIndex,'desc-margin-top')+'px'">
          <p
            [style.font-size]="getBlockFieldValueByIndex(selectedBlockIndex,'desc-size')+'px'"
            [style.color]="getBlockFieldValueByIndex(selectedBlockIndex,'desc-color')">
            {{getBlockFieldValueByIndex(selectedBlockIndex, 'desc')}}
          </p>
        </div>

        <div>
          <a class="neo-btn"
             *ngIf="getBlockFieldValueByIndex(selectedBlockIndex, 'btn-label')"
             [href]="getLink(getBlockFieldValueByIndex(selectedBlockIndex,'btn-link'))"
             [style.margin-top]="getBlockFieldValueByIndex(selectedBlockIndex,'btn-margin-top')+'px'">
            {{getBlockFieldValueByIndex(selectedBlockIndex, 'btn-label')}}</a>
        </div>
      </div>
    </ng-container>

  </div>

</div>
