import { Menu, MenuTargetType } from '@app/_shared';

export const neoDefaultFooterMenu: Menu[] = [
  {
    id: '',
    name: 'Termes et politiques',
    menuItems: [
      {
        label: 'Politique de confidentialité',
        targetType: MenuTargetType.PAGE,
        targetValue: 'privacy'
      },
      {
        label: 'Retours & échanges',
        targetType: MenuTargetType.PAGE,
        targetValue: 'return-exchange'
      },
      {
        label: 'Conditions d\'utilisation',
        targetType: MenuTargetType.PAGE,
        targetValue: 'conditions'
      }
    ]
  },
  {
    id: '',
    name: 'Liens rapides',
    menuItems: [
      {
        label: 'Livraison & co',
        targetType: MenuTargetType.PAGE,
        targetValue: 'delivery-shipping'
      },
      {
        label: 'Nos collections',
        targetType: MenuTargetType.PAGE,
        targetValue: 'collections'
      },
      {
        label: 'Nos produits',
        targetType: MenuTargetType.PAGE,
        targetValue: 'search'
      }
    ]
  },
  {
    id: '',
    name: 'Company',
    menuItems: [
      {
        label: 'About us',
        targetType: MenuTargetType.PAGE,
        targetValue: 'about-us'
      },
      {
        label: 'Contact us',
        targetType: MenuTargetType.PAGE,
        targetValue: 'contact-us'
      }
    ]
  }
]
