<div [id]="data.code" class="neo-thank-you shopyan-section" [class.build-mode]="buildMode">
  <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>

  <div class="thank-you">
    <div class="ty-ic"></div>
    <div class="ty-ttl">{{getFieldValue('title')}}</div>
    <div class="ty-txt">{{getFieldValue('desc')}}</div>
  </div>

  <lib-neo-order-summary-shared [hideContinueShopping]="getFieldValue('hide-continue-shopping')==true" *ngIf="order" [order]="order" [storeId]="storeID"></lib-neo-order-summary-shared>

</div>
