<div id="neo-product-checkout">
  <!-- EXPRESS CHECKOUT -->
  <ng-container *ngIf="expressFG">
    <div class="cod-form">
      <div id="cod-form-anchor" class="anchor"></div>
      <div [formGroup]="expressFG" class="cod-form-content"
           [style.border-color]="getFieldValue('express-border-color')"
           [style.border-width]="getFieldValue('express-border-width')+'px'"
           [style.border-radius]="getFieldValue('express-border-radius')+'px'"
           [style.padding]="getFieldValue('express-padding')+'px'"
           [style.background-color]="getFieldValue('express-background-background-color')">
        <div *ngIf="checkoutLabel" class="form-intro ">
          <div class="fr-view" [innerHTML]="checkoutLabel"></div>
        </div>
        <ng-container formArrayName="fields">
          <div class="fields">
            <ng-container *ngFor="let field of checkoutFields; let index = index">
              <ng-container
                *ngIf="field.subType != checkoutFieldSubType.HIDDEN">
                <ng-container [formGroupName]="index">
                  <div class="neo-field">
                    <ng-container [ngSwitch]="field.type">
                      <ng-container *ngSwitchCase="checkoutFieldType.PHONE">
                        <input
                          [style.border-width]="getFieldValue('express-field-border-width')+'px'"
                          [style.font-size]="getFieldValue('express-field-size')+'px'"
                          [style.border-color]="getFieldValue('express-field-border-color')"
                          [style.background-color]="getFieldValue('express-field-background-color')"
                          [style.color]="getFieldValue('express-field-color')"
                          [style.border-radius]="getFieldValue('express-field-border-radius')+'px'"
                          [style.line-height]="getFieldValue('express-field-height')+'px'"
                          [style.height]="getFieldValue('express-field-height')+'px'"
                          InputValidator type="tel" class="neo-input" [placeholder]="field.label"
                          formControlName="value"/>
                      </ng-container>
                      <ng-container *ngSwitchCase="field.type == checkoutFieldType.ADDRESS_1 ||
                                                     field.type == checkoutFieldType.ADDRESS_2 ||
                                                     field.type == checkoutFieldType.COMMENT">
                          <textarea
                            [style.border-width]="getFieldValue('express-field-border-width')+'px'"
                            [style.font-size]="getFieldValue('express-field-size')+'px'"
                            [style.border-color]="getFieldValue('express-field-border-color')"
                            [style.background-color]="getFieldValue('express-field-background-color')"
                            [style.color]="getFieldValue('express-field-color')"
                            [style.border-radius]="getFieldValue('express-field-border-radius')+'px'"
                            InputValidator class="neo-input" [placeholder]="field.label"
                            formControlName="value"></textarea>
                      </ng-container>
                      <ng-container *ngSwitchCase="checkoutFieldType.CUSTOM_FIELD">
                        <ng-container [ngSwitch]="field.subType">
                          <ng-container *ngSwitchCase="checkoutFieldSubType.INPUT">
                            <input
                              [style.border-width]="getFieldValue('express-field-border-width')+'px'"
                              [style.font-size]="getFieldValue('express-field-size')+'px'"
                              [style.border-color]="getFieldValue('express-field-border-color')"
                              [style.background-color]="getFieldValue('express-field-background-color')"
                              [style.color]="getFieldValue('express-field-color')"
                              [style.border-radius]="getFieldValue('express-field-border-radius')+'px'"
                              [style.line-height]="getFieldValue('express-field-height')+'px'"
                              [style.height]="getFieldValue('express-field-height')+'px'"
                              InputValidator type="text" class="neo-input" [placeholder]="field.label"
                              formControlName="value"/>
                          </ng-container>
                          <ng-container *ngSwitchCase="checkoutFieldSubType.NUMBER">
                            <input
                              [style.border-width]="getFieldValue('express-field-border-width')+'px'"
                              [style.font-size]="getFieldValue('express-field-size')+'px'"
                              [style.border-color]="getFieldValue('express-field-border-color')"
                              [style.background-color]="getFieldValue('express-field-background-color')"
                              [style.color]="getFieldValue('express-field-color')"
                              [style.border-radius]="getFieldValue('express-field-border-radius')+'px'"
                              [style.line-height]="getFieldValue('express-field-height')+'px'"
                              [style.height]="getFieldValue('express-field-height')+'px'"
                              InputValidator type="number" class="neo-input" [placeholder]="field.label"
                              formControlName="value"/>
                          </ng-container>
                          <ng-container *ngSwitchCase="checkoutFieldSubType.TEXTAREA">
                           <textarea
                             [style.border-width]="getFieldValue('express-field-border-width')+'px'"
                             [style.font-size]="getFieldValue('express-field-size')+'px'"
                             [style.border-color]="getFieldValue('express-field-border-color')"
                             [style.background-color]="getFieldValue('express-field-background-color')"
                             [style.color]="getFieldValue('express-field-color')"
                             [style.border-radius]="getFieldValue('express-field-border-radius')+'px'"
                             InputValidator class="neo-input" [placeholder]="field.label"
                             formControlName="value"></textarea>
                          </ng-container>
                          <ng-container *ngSwitchCase="checkoutFieldSubType.SELECT">

                            <select class="neo-input" formControlName="value"
                                    [style.border-width]="getFieldValue('express-field-border-width')+'px'"
                                    [style.font-size]="getFieldValue('express-field-size')+'px'"
                                    [style.border-color]="getFieldValue('express-field-border-color')"
                                    [style.background-color]="getFieldValue('express-field-background-color')"
                                    [style.color]="getFieldValue('express-field-color')"
                                    [style.border-radius]="getFieldValue('express-field-border-radius')+'px'"
                                    [style.line-height]="getFieldValue('express-field-height')+'px'"
                                    [style.height]="getFieldValue('express-field-height')+'px'"
                            >
                              <option [value]="''">{{field.label}}</option>
                              <option [value]="item" *ngFor="let item of field.options">
                                {{item}}
                              </option>
                            </select>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <input InputValidator
                               [style.border-width]="getFieldValue('express-field-border-width')+'px'"
                               [style.font-size]="getFieldValue('express-field-size')+'px'"
                               [style.border-color]="getFieldValue('express-field-border-color')"
                               [style.background-color]="getFieldValue('express-field-background-color')"
                               [style.color]="getFieldValue('express-field-color')"
                               [style.border-radius]="getFieldValue('express-field-border-radius')+'px'"
                               [style.line-height]="getFieldValue('express-field-height')+'px'"
                               [style.height]="getFieldValue('express-field-height')+'px'"
                               type="text" class="neo-input with-ic" [placeholder]="field.label"
                               formControlName="value"/>
                      </ng-container>
                      <app-field-error controlName="value" errorKey="required">
                        {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
                      </app-field-error>
                      <app-field-error controlName="value" errorKey="pattern">
                        {{'COMMON.ERROR.INVALID_VALUE' | translate}}
                      </app-field-error>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="buy-actions">
      <div *addToCart
           (click)="addToCart()"
           [style.color]="getFieldValue('add-cart-color')"
           [style.background-color]="getFieldValue('add-cart-bg-color')"
           [style.line-height]="getFieldValue('buy-height')+'px'"
           [style.border-radius]="getFieldValue('buy-border-radius')+'px'"
           [style.font-size]="getFieldValue('buy-font-size')+'px'"
           [class.lower]="getFieldValue('buy-lower') == true"
           class="neo-btn add-cart">
        <span>{{'PRODUCT.ADD_TO_CART' | translate}}</span>
      </div>
      <div class="buy-now-qty">
        <div id="product-quantity-anchor" class="anchor"></div>
        <div class="quantity" *ngIf="getFieldValue('hide-quantity') != true && !hideQuantity">
          <lib-neo-quantity [(value)]="quantity" [min]="1" [max]="maxQuantity"
                            (valueChange)="refreshQuantity()"></lib-neo-quantity>
        </div>
        <button (click)="submitExpressCheckout()"
                [class.shake-btn]="getFieldValue('shake-btn')== true"
                [loading]="expressLoading"
                [style.color]="getFieldValue('buy-color')"
                [style.background-color]="getFieldValue('buy-bg-color')"
                [style.line-height]="getFieldValue('buy-height')+'px'"
                [style.border-radius]="getFieldValue('buy-border-radius')+'px'"
                [style.font-size]="getFieldValue('buy-font-size')+'px'"
                [class.lower]="getFieldValue('buy-lower') == true"
                [class.with-ic]="getFieldValue('buy-show-icon') == true"
                class="neo-btn buy-now"
                loadingBtn>
          <div class="ic" *ngIf="getFieldValue('buy-show-icon') == true"></div>
          <span>{{ getFieldValue('buy-label') || 'PRODUCT.BUY_NOW' | translate}}</span>
        </button>
      </div>
    </div>
  </ng-container>

  <!-- CLASSIC CHECKOUT -->
  <div class="buy-actions" *ngIf="!expressFG">
    <div *addToCart
         (click)="addToCart()"
         [style.color]="getFieldValue('add-cart-color')"
         [style.background-color]="getFieldValue('add-cart-bg-color')"
         [style.line-height]="getFieldValue('buy-height')+'px'"
         [style.border-radius]="getFieldValue('buy-border-radius')+'px'"
         [style.font-size]="getFieldValue('buy-font-size')+'px'"
         [class.lower]="getFieldValue('buy-lower') == true"
         class="neo-btn add-cart">
      {{'PRODUCT.ADD_TO_CART' | translate}}
    </div>
    <div class="buy-now-qty">
      <div id="product-quantity-anchor" class="anchor"></div>
      <div class="quantity" *ngIf="!hideQuantity">
        <lib-neo-quantity [(value)]="quantity" [min]="1" [max]="maxQuantity"
                          (valueChange)="refreshQuantity()"></lib-neo-quantity>
      </div>
      <button (click)="submitClassicCheckout()"
              [class.shake-btn]="getFieldValue('shake-btn') == true"
              [style.color]="getFieldValue('buy-color')"
              [style.background-color]="getFieldValue('buy-bg-color')"
              [style.line-height]="getFieldValue('buy-height')+'px'"
              [style.border-radius]="getFieldValue('buy-border-radius')+'px'"
              [style.font-size]="getFieldValue('buy-font-size')+'px'"
              [class.lower]="getFieldValue('buy-lower') == true"
              [class.with-ic]="getFieldValue('buy-show-icon') == true"
              [loading]="expressLoading"
              loadingBtn
              class="neo-btn buy-now">
        <div class="ic" *ngIf="getFieldValue('buy-show-icon') == true"></div>
        <span>{{ getFieldValue('buy-label') || 'PRODUCT.BUY_NOW' | translate}}</span>
      </button>
    </div>
  </div>
</div>
