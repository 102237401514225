<div [id]="data.code" class="neo-checkout-btn shopyan-section animated tdFadeInUp"
     [class.build-mode]="buildMode"
     [style.background-color]="getFieldValue('background-color')">

  <div class="neo-checkout-btn-content"
       [style.padding-top]="getFieldValue('padding-top')+'px'"
       [style.padding-bottom]="getFieldValue('padding-bottom')+'px'">

    <button
          (click)="goToCheckout()"
            [style.line-height]="getFieldValue('line-height')+'px'"
            [style.font-size]="getFieldValue('font-size')+'px'"
            [style.border-radius]="getFieldValue('border-radius')+'px'"
            [class.shake-btn]="getFieldValue('shake-btn') == true"
            [class.lower]="getFieldValue('upper') == false"
            [style.max-width]="getFieldValue('max-width')+'px'"
            [style.color]="getFieldValue('color')"
            [style.background-color]="getFieldValue('bg-color')"
            class="neo-btn buy-now">
      {{ getFieldValue('buy-label') || 'PRODUCT.BUY_NOW' | translate}}
    </button>

  </div>
</div>
