import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class EventService {
  constructor(private http: HttpClient) {
  }


  sendVisit(event: any, storeId: string): Observable<any> {
    // Browser only - no private api call
    let url = `${environment.pubicStoreApiUrl}/events/visit`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'storeId': storeId,
      }),
      responseType: 'text' as 'json'
    };
    return this.http
      .post(url, event, httpOptions);
  }
}
