<div [id]="data.code" class="neo-cart shopyan-section"
     [class.build-mode]="buildMode">
  <div class="neo-section-layout v-padding cart-container">

    <div class="cart-section items-area">
      <div class="cs-header">
        {{'CART.TITLE' | translate}} <span *ngIf="cart && cart.items">({{cart.items.length}})</span>
      </div>

      <ng-container *ngIf="cart && cart.items && cart.items.length > 0; else emptyCart">
        <div class="cart-items">
          <div class="cart-item" *ngFor="let item of cart.items; let itemIndex = index">
            <div class="ci-img">
              <img alt="store" [src]="urlProductMediumMedia(item.product.medias[0])"
                   *ngIf="!item.variation && item.product.medias && item.product.medias.length > 0"/>
              <img alt="store" [src]="urlVariantMediumMedia(item.variation.media)"
                   *ngIf="item.variation && item.variation.media"/>
              <img alt="store" [src]="item.product.imageLinks[0]"
                   *ngIf="(!item.product.medias || item.product.medias.length == 0) && (!item.variation || !item.variation.media)
                    && item.product.imageLinks && item.product.imageLinks.length > 0"/>

              <div class="product-discounts" *ngIf="item.discounts || item.upsell">
                <ng-container *ngIf="item.upsell && (item.upsell.fixedDiscount || item.upsell.discountPercent)">
                  <div class="discount">
                    -<span *ngIf="item.upsell.fixedDiscount">{{item.upsell.fixedDiscount | price}}</span>
                    <span *ngIf="item.upsell.discountPercent">{{item.upsell.discountPercent}}%</span>
                  </div>
                </ng-container>
                <ng-container *ngFor="let discount of item.discounts; let index = index">
                  <div class="discount">
                    -<span *ngIf="discount.value">{{discount.value | price}}</span>
                    <span *ngIf="discount.percent">{{discount.percent}}%</span>
                  </div>
                </ng-container>
              </div>

            </div>
            <div class="ci-product">

              <a href="/product/{{item.product.slug}}" class="product-label">{{item.product.name}}</a>

              <div class="product-variations" *ngIf="item.variation">
                <div class="variation-container">
                  <ng-container *ngFor="let option of item.variation.optionValues; let index = index">
                    <div class="variation">{{option}}</div>
                    <div class="sep" *ngIf="index < (item.variation.optionValues.length - 1 )">-</div>
                  </ng-container>
                </div>
              </div>

              <ng-container *ngIf="item.productBundle && item.productBundle.variations">
                <div class="bundle-variations" *ngFor="let variation of item.productBundle.variations">
                  <ng-container *ngFor="let option of variation.optionValues; let index = index">
                    <div class="variation">{{option}}</div>
                    <div class="sep" *ngIf="index < (variation.optionValues.length - 1 )">-</div>
                  </ng-container>
                </div>
              </ng-container>

              <div *ngIf="item.productBundle">
                <div class="product-bundle">
                  {{item.productBundle.bundle.title}}
                </div>
              </div>

              <div class="product-footer">
                <div class="product-price">
                  <span>{{getDiscountedPrice(item) || 0 | price}}</span>
                </div>
                <div class="product-actions">
                  <lib-neo-quantity [min]="1" [(value)]="item.quantity"
                                    *ngIf="!item.productBundle"
                                    (valueChange)="refreshTotal()"></lib-neo-quantity>
                  <div class="delete" (click)="removeItem(itemIndex)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #emptyCart>
        <div class="empty-cart">
          <div class="ic"></div>
          <div class="title">{{'CART.EMPTY' | translate}}</div>
        </div>
      </ng-template>

    </div>

    <div class="cart-section total-section" *ngIf="cart">
      <div class="cs-header">
        {{'CART.SUMMARY' | translate}}
      </div>

      <div class="totals">
        <div class="sub-total">
          <span class="lbl">{{'ORDER_SUMMARY.SUB_TOTAL' | translate}}</span>
          <span class="val">{{cart.total | price}}</span>
        </div>
        <ng-container *ngIf="cart.discounts && cart.discounts.length > 0">
          <div class="sub-total">
            <div class="lbl" *ngFor="let discountItem of cart.discounts">{{'CART.DISCOUNT' | translate}}
              ({{discountItem.label}} - <span
                *ngIf="discountItem.value">{{discountItem.value}}</span>
              <span *ngIf="discountItem.percent">{{discountItem.percent}}%</span>)
            </div>
            <div class="val">-{{basicHelperService.getDiscountValue(cart.discounts, cart.total) | price}}</div>
          </div>
        </ng-container>
        <div class="sub-total">
          <span class="lbl">{{'CART.TAX' | translate}} ({{tax}}%)</span>
          <div class="val">{{taxValue | price}}</div>
        </div>
        <div class="sub-total">
          <span class="lbl">{{'CART.SHIPPING' | translate}}</span>
          <span class="val">-</span>
        </div>
        <div class="big-total">
          <span class="lbl">{{'CART.TOTAL' | translate}}</span>
          <div class="val">{{bigTotal | price}}</div>
        </div>
      </div>

      <div class="cart-note">
        <div class="neo-field">
          <textarea class="neo-input" [(ngModel)]="cart.comment" maxlength="255"
                    placeholder="{{'CART.INSTRUCTION_PLACEHOLDER' | translate}}"></textarea>
        </div>
      </div>

      <div class="neo-btn go-checkout" (click)="goCheckout()">{{'CART.GO_CHECKOUT' | translate}}</div>
      <div class="coupon-notif">{{'CART.APPLY_COUPON' | translate}}</div>
      <a class="continue-shopping" href="/search">{{'CART.CONTINUE_SHOPPING' | translate}}</a>
    </div>

  </div>
</div>
