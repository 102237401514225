import {Component} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  template: ''
})
export abstract class ShopyanVideoComponent extends ShopyanSectionComponent {

  safeURL: any;

  protected constructor(private _sanitizer: DomSanitizer) {
    super();
  }

  protected getYoutubeVideoId(url: string): string | null {
    if (!url) {
      return null;
    }
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

}
