import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CartFormItem,
  MediaSize,
  MediaType,
  Product,
  ProductVariation,
  ShopyanCartHelperService,
  ShopyanToastrService,
  StorageService,
  Upsell,
  UpsellPage,
  urlMedia
} from '@app/_shared';
import {CountdownConfig} from "ngx-countdown";
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "../../../../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lib-neo-upsell-mono-product',
  templateUrl: './neo-upsell-mono-product.component.html',
  styleUrls: ['./neo-upsell-mono-product.component.scss']
})
export class NeoUpsellMonoProductComponent implements OnInit {

  @Input()
  upsell: Upsell;

  @Input()
  product: Product;

  @Input()
  page: UpsellPage;

  @Input()
  storeId: string;

  @Input()
  isExpress: boolean;

  description: any;
  price: number;
  basicPrice: number;
  selectedVariation = '';

  upsellPage = UpsellPage;

  config: CountdownConfig = {
    format: 'HH:mm:ss',
    prettyText: (text) => {
      const items: string[] = ['', '', ''];
      const formatted = text
        .split(':')
        .map((v, index) => {
          let val = +v;
          return `<li class="item"><span class="val">${val}</span> <span class="lbl">${items[index]}</span></li>`
        })
        .join('');

      return `<ul>${formatted}</ul>`;
    }
  };

  slideConfig: any = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false
  };

  @Output()
  closeModalEvent = new EventEmitter();

  media: string[] = [];

  constructor(private storageService: StorageService,
              private _sanitizer: DomSanitizer,
              private translate: TranslateService,
              private basicToastr: ShopyanToastrService,
              public basicHelperService: ShopyanCartHelperService) {
  }

  ngOnInit(): void {
    this.initMedia();
    if (this.upsell.description) {
      this.description = this._sanitizer.bypassSecurityTrustHtml(this.upsell.description);
    }
    if (this.upsell.timer) {
      this.config.leftTime = this.upsell.timer * 60;
    }
    if (this.product.pricing) {
      this.price = this.product.pricing.price;
      this.basicPrice = this.product.pricing.basicPrice;
      this.applyDiscount(this.product.pricing.price);
    }
  }

  initMedia(): void {
    if (this.product.medias?.length > 0) {
      this.product.medias.forEach((item: any) => {
        this.media.push(this.urlProductMediumMedia(item));
      });
    }

    if (this.product.imageLinks?.length > 0) {
      this.product.imageLinks.forEach((imageLink: any) => {
        this.media.push(imageLink);
      });
    }

    this.product.variations?.forEach((variation: any) => {
      if (variation.media) {
        this.media.push(this.urlVariantMediumMedia(variation.media));
      }
    });
  }

  urlProductMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.PRODUCT, MediaSize.original, media);
  }

  urlVariantMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.VARIANT, MediaSize.medium, media);
  }

  /**
   * Change variation
   */
  changeVariation() {
    const variation = this.product.variations.find(v => v.id === this.selectedVariation);
    if (variation) {
      this.price = variation.price;
      this.basicPrice = variation.basicPrice;
      this.applyDiscount(variation.price);
    }

  }

  private applyDiscount(basicPrice: number): void {
    this.basicPrice = basicPrice;
    if (this.upsell.discountPercent) {
      this.price -= +(this.price * (this.upsell.discountPercent / 100)).toFixed(2);
    } else if (this.upsell.fixedDiscount) {
      this.price -= this.upsell.fixedDiscount;
    }
  }

  getDiscountedPrice(variation: ProductVariation): number {
    let price = variation.price;
    if (this.upsell.discountPercent) {
      price -= +(price * (this.upsell.discountPercent / 100)).toFixed(2);
    } else if (this.upsell.fixedDiscount) {
      price -= this.upsell.fixedDiscount;
    }
    price = +price.toFixed(2);
    return price;
  }

  addToCart(): void {
    if (this.checkUpsell()) {
      this.basicHelperService.addItemToCart(this.product, 1, this.selectedVariation, this.upsell);
      window.location.reload();
    }
  }

  buyNow(): void {
    if (this.checkUpsell()) {
      if (this.isExpress) {
        let cartFormItem = new CartFormItem();
        cartFormItem.product = this.product.id;
        cartFormItem.variation = this.selectedVariation;
        cartFormItem.quantity = 1;
        cartFormItem.upsell = this.upsell.id;
        this.closeModalEvent.emit(cartFormItem);
      } else {
        this.addToCart();
      }
    }
  }

  checkUpsell(): boolean {
    if (!this.selectedVariation && this.product.variations && this.product.variations.length > 0) {
      this.basicToastr.error(this.translate.instant("UPSELL.SELECT_OPTION"));
      return false;
    }
    return true;
  }

  closeModal(): void {
    this.closeModalEvent.emit();
  }

}
