import {Component, Inject, OnInit} from '@angular/core';
import {ComponentLookup, Link, MediaSize, MediaType, urlMedia} from '@app/_shared';
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  selector: 'lib-neo-image',
  templateUrl: './neo-image.component.html',
  styleUrls: ['./neo-image.component.scss']
})
@ComponentLookup('neo-image')
export class NeoImageComponent extends ShopyanSectionComponent implements OnInit {

  constructor(private _sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
  }

  getLink(link: Link): any {
    let l = this.constructLink(link);
    return l ? this._sanitizer.bypassSecurityTrustResourceUrl(l) : undefined;
  }

  urlStoreMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.STORE, MediaSize.original, media);
  }

}
