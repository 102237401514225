import { Menu, MenuTargetType } from '@app/_shared';

export const neoDefaultHeaderMenu: Menu =
  {
    id: '',
    name: 'Header default-menu',
    menuItems: [
      {
        label: 'Home',
        targetType: MenuTargetType.PAGE,
        targetValue: ''
      },
      {
        label: 'Products',
        targetType: MenuTargetType.PAGE,
        targetValue: 'search'
      },
      {
        label: 'Collections',
        targetType: MenuTargetType.PAGE,
        targetValue: 'collections'
      },
      {
        label: 'Contact us',
        targetType: MenuTargetType.PAGE,
        targetValue: 'contact-us'
      }
    ]
  };

