import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Pageable} from "@app/_shared";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {isPlatformServer} from "@angular/common";


@Injectable({providedIn: 'root'})
export class OrderService {


  constructor(@Inject(PLATFORM_ID) private platformId: any, private http: HttpClient) {
  }


  findOrder(id: string) {
    let url = `${this.getOrdersUrl()}/orders/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  public filterCustomerOrders(pageable?: Pageable): Observable<any> {
    let url = `${this.getOrdersUrl()}/api/orders/list`;
    let params = pageable ? pageable.getParams() : new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.get(url, httpOptions);
  }

  private getOrdersUrl() {
    return isPlatformServer(this.platformId) ? environment.privateOrdersApiUrl : environment.pubicOrdersApiUrl;
  }
}
