import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Product, ProductService, ShopyanCartHelperService,} from "@app/_shared";
import {Subscription} from "rxjs";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {isPlatformServer} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanMyProductsComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  products: Product[] = [];
  loading: boolean;

  protected constructor(private productService: ProductService,
                        private shopyanCartHelperService: ShopyanCartHelperService,
                        private transferState: TransferState,
                        @Inject(PLATFORM_ID) protected platformId: any) {
    super();
  }

  init(): void {
    if (isPlatformServer(this.platformId) || this.buildMode) {
      if (this.data && this.data.blocks && this.data.blocks.length > 0) {
        let productIds: string[] = this.data.blocks?.filter((block: any) => block.values?.length > 0 && !block.hidden).map((block: any) => block.values[0].value);
        if (productIds && productIds.length > 0) {
          this.loading = true;
          let sub = this.productService.findProductsByIds(productIds).subscribe(response => {
            if (response) {
              this.loading = false;
              productIds.forEach(id => {
                (response as Product[]).forEach(p => {
                  if (p.id === id) {
                    this.products.push(p);
                  }
                });
              });
              if (this.products?.length > 0) {
                this.shopyanCartHelperService.initDiscounts(this.products);
              }
            }
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(makeStateKey(this.data.code + '-products'), this.products as any);
            }
          });
          this.subscription.add(sub);
        }
      }
    } else {
      this.products = this.transferState.get(makeStateKey(this.data.code + '-products'), null as any);
      if (this.products?.length > 0) {
        this.shopyanCartHelperService.initDiscounts(this.products);
      }
    }

  }

}
