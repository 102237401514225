<div [id]="data.code" class="neo-reviews shopyan-section"
     [class.build-mode]="buildMode">

  <div class="neo-section-layout v-padding" [class.large]="getFieldValue('max-per-row') > 4">

    <div class="reviews-header">
      <div class="rh-title">{{ 'PRODUCT_REVIEWS.TITLE' | translate }}</div>
      <div class="neo-btn add-review"
           (click)="displayReviewForm = true;">{{ 'PRODUCT_REVIEWS.ADD_REVIEW' | translate }}</div>
    </div>

    <div class="reviews-content" #layout>
      <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>

      <ng-container *ngIf="reviews && reviews.length > 0">

        <div class="reviews-items" [style.grid-template-columns]="'repeat('+reviewsPerRow+', minmax(0, 1fr))'">
          <div class="review-item"
               *ngFor="let review of reviews">
            <div class="ri-img" *ngIf="review.media">
              <img alt="review" [src]="urlReviewMediumMedia(review.media)"/>
            </div>
            <div class="neo-rate">
              <bar-rating [rate]="review.rating" [max]="5" [readOnly]="true"></bar-rating>
            </div>
            <div class="ri-comment">
              <p>{{review.comment}}</p>
            </div>
            <div class="ri-inf">
              <div class="ri-reviewer">{{review.guest}}</div>
              <div class="ri-date">{{review.date | date: 'dd/MM/yyyy'}}</div>
            </div>
          </div>
        </div>
        <div class="show-more" *ngIf="showMore">
          <div class="neo-btn"
               (click)="filterReviews(true)">
            {{ 'PRODUCT_REVIEWS.SHOW_MORE' | translate }}
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="!loading && (!reviews || reviews.length == 0)">
        <div class="no-result">{{ 'PRODUCT_REVIEWS.NO_REVIEW' | translate }}</div>
      </ng-container>

    </div>

  </div>

</div>


<div id="review-modal" [class.open]="displayReviewForm">
  <div class="review-form" *ngIf="reviewFG" [formGroup]="reviewFG">
    <div class="rf-title">{{ 'PRODUCT_REVIEWS.FORM.TITLE' | translate }}</div>
    <div class="neo-field" *ngIf="!isAuthenticated">
      <div class="neo-field-label">
        <label class="neo-label">
          <span>{{ 'PRODUCT_REVIEWS.FORM.NAME' | translate }}</span>
          <span class="mandatory">*</span>
        </label>
      </div>
      <input type="text" InputValidator class="neo-input" formControlName="guest"/>
      <app-field-error controlName="guest" errorKey="required">
        {{ 'COMMON.ERROR.REQUIRED_FIELD' | translate }}
      </app-field-error>
    </div>
    <div class="neo-field">
      <div class="neo-field-label">
        <label class="neo-label">
          <span>{{ 'PRODUCT_REVIEWS.FORM.RATE' | translate }}</span>
          <span class="mandatory">*</span>
        </label>
      </div>
      <div class="neo-rate">
        <bar-rating [max]="5" formControlName="rating"></bar-rating>
      </div>
    </div>
    <div class="neo-field">
      <div class="neo-field-label">
        <label class="neo-label">
          <span>{{ 'PRODUCT_REVIEWS.FORM.COMMENT' | translate }}</span>
          <span class="mandatory">*</span>
        </label>
      </div>
      <textarea type="text" InputValidator class="neo-input"
                placeholder="{{ 'PRODUCT_REVIEWS.FORM.PLACEHOLDER' | translate }}"
                formControlName="comment"></textarea>
      <app-field-error controlName="comment" errorKey="required">
        {{ 'COMMON.ERROR.REQUIRED_FIELD' | translate }}
      </app-field-error>
    </div>
    <button loadingBtn class="neo-btn add-review" [loading]="reviewFormLoading" (click)="submitReview()">
      {{ 'COMMON.VALIDATE' | translate }}
    </button>
    <div class="neo-btn reverse" (click)="displayReviewForm = false">
      {{ 'COMMON.CANCEL' | translate }}
    </div>
  </div>
</div>

<div id="over-div" *ngIf="displayReviewForm" (click)="displayReviewForm = false"></div>

