import {Component, OnInit} from '@angular/core';
import {ComponentLookup} from '@app/_shared';
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  selector: 'lib-neo-cancel',
  templateUrl: './neo-cancel.component.html',
  styleUrls: ['./neo-cancel.component.scss']
})
@ComponentLookup('neo-cancel-payment')
export class NeoCancelComponent extends ShopyanSectionComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }


}
