import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {MediaSize, MediaType, Menu, MenuItem, MenuService, urlMedia} from "@app/_shared";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {DomSanitizer, makeStateKey, TransferState} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {isPlatformServer} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanFooterComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  menus: Menu[] = [];
  staticMenus: Menu[] = [];

  loading: boolean;

  protected constructor(private router: Router,
                        private _sanitizer: DomSanitizer,
                        private menuService: MenuService,
                        protected transferState: TransferState,
                        @Inject(PLATFORM_ID) protected platformId: any) {
    super();
  }

  init(): void {
    this.staticMenus = this.getDefaultMenus();
    if (isPlatformServer(this.platformId)) {
      this.getMenus();
    } else {
      this.menus = this.transferState.get(makeStateKey(this.data.code + '-menus'), null as any);
      if (!this.menus || this.menus.length === 0) {
        this.getMenus();
      }
    }
  }


  getMenus():void{
    let menuIds: string[] = [];
    this.data.blocks?.filter((block: any) => block.blockCode === 'footer-menu')?.forEach((block: any) => {
      if (block.values && block.values.length > 0 && block.values[0].value) {
        menuIds.push(block.values[0].value);
      }
    });

    if (menuIds.length > 0) {
      this.initMenuItems(menuIds);
    }
  }

  protected abstract getDefaultMenus(): Menu[];


  initMenuItems(ids: string[]): void {

    this.loading = true;
    this.subscription.add(this.menuService.getMenuItems(ids).subscribe((response: any) => {
      if (response) {
        this.loading = false;
        this.menus = response;
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(makeStateKey(this.data.code + '-menus'), this.menus as any);
        }
      }
    }));
  }

  getLink(menuItem: MenuItem): any {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.constructMenuLink(menuItem));
  }

  urlStoreMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.STORE, MediaSize.original, media);
  }

}
