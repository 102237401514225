import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {
  AuthService,
  ComponentLookup,
  Menu,
  MenuService,
  ShopyanCartHelperService,
  ShopyanHeaderComponent,
  StorageService
} from '@app/_shared';
import {Router} from "@angular/router";
import {DomSanitizer, TransferState} from "@angular/platform-browser";
import {neoDefaultHeaderMenu} from "./neo-default-header-menu";
import {MatMenuTrigger} from "@angular/material/menu";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lib-neo-header',
  templateUrl: './neo-header.component.html',
  styleUrls: ['./neo-header.component.scss']
})
@ComponentLookup('neo-header')
export class NeoHeaderComponent extends ShopyanHeaderComponent implements OnInit, OnDestroy {

  @ViewChild('cartPreviewTrigger') cartPreviewTrigger: MatMenuTrigger;

  displayMobileOptions = false;
  displayMobileCartContent = false;

  constructor(translate: TranslateService,
              router: Router,
              _sanitizer: DomSanitizer,
              storageService: StorageService,
              menuService: MenuService,
              basicHelperService: ShopyanCartHelperService,
              authService: AuthService,
              transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any) {
    super(translate, router, _sanitizer, storageService, menuService, basicHelperService,
      authService, transferState, platformId);
  }


  ngOnInit(): void {
    this.init();
  }


  protected getDefaultMenu(): Menu {
    return neoDefaultHeaderMenu;
  }

  showCartPreview(): void {
    // To refresh content
    this.displayCartContent = false;
    this.displayMobileCartContent = false;
    setTimeout(() => {
      if (window.innerWidth <= 1000) {
        this.displayMobileCartContent = true;
      } else {
        this.displayCartContent = true;
        this.cartPreviewTrigger.openMenu();
      }
    }, 50);
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

