import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CheckoutField, CheckoutFieldSubType, CheckoutFieldType, GraphSection} from '@app/_shared';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'lib-neo-product-checkout',
  templateUrl: './neo-product-checkout.component.html',
  styleUrls: ['./neo-product-checkout.component.scss']
})
export class NeoProductCheckoutComponent implements OnInit {

  @Input()
  data: GraphSection;

  @Input()
  expressFG: FormGroup;

  @Input()
  checkoutLabel: any;

  @Input()
  checkoutFields: CheckoutField[];

  checkoutFieldType = CheckoutFieldType;

  checkoutFieldSubType = CheckoutFieldSubType;

  @Input()
  quantity: number;

  @Input()
  maxQuantity: number;

  @Input()
  expressLoading: boolean;

  @Output()
  addToCartAction = new EventEmitter();

  @Output()
  refreshQuantityAction = new EventEmitter();

  @Output()
  submitClassicCheckoutAction = new EventEmitter();

  @Output()
  submitExpressCheckoutAction = new EventEmitter();


  @Input()
  hideQuantity: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  public getFieldValue(fieldCode: string): any {
    return this.data?.values?.find((item: any) => item.fieldCode === fieldCode)?.value;
  }

  addToCart(): void {
    this.addToCartAction.emit();
  }

  submitClassicCheckout(): void {
    this.submitClassicCheckoutAction.emit();
  }

  submitExpressCheckout(): void {
    this.submitExpressCheckoutAction.emit();
  }

  refreshQuantity() {
    this.refreshQuantityAction.emit(this.quantity);
  }
}
