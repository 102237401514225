<div [id]="data.code" class="neo-collapsible shopyan-section" [class.build-mode]="buildMode">

  <div class="neo-section-layout v-padding"
       [class.large]="getFieldsGroupValue('layout','layout-width') == 'large'">

    <lib-neo-section-header
      *ngIf="getFieldsGroup('collapsible-header') && !getFieldsGroup('collapsible-header').hidden"
      [data]="getFieldsGroup('collapsible-header')"></lib-neo-section-header>

    <div class="collapsible-content">
      <ng-container *ngFor="let block of data.blocks; let index = index;">
        <div class="collapsible-item" *ngIf="!block.hidden"
             [style.background]="getFieldsGroupValue('layout','background')">
          <div class="ci-header" (click)="clickBlock(block)">
            <div class="cih-ic"></div>
            <div class="cih-title"
                 [style.color]="getFieldsGroupValue('layout','item-title-color')">{{getBlockFieldValueByIndex(index, 'title')}}</div>
            <div class="cih-arrow" [class.checked]="buildMode || isBlockOpened(block)"></div>
          </div>
          <div class="ci-content animated tdFadeInUp" *ngIf="buildMode || isBlockOpened(block)">
            <p [style.color]="getFieldsGroupValue('layout','item-desc-color')">
              {{getBlockFieldValueByIndex(index, 'desc')}}
            </p>
          </div>
        </div>
      </ng-container>
    </div>


  </div>
</div>
