import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { catchError } from "rxjs/operators";
import {isPlatformServer} from "@angular/common";


@Injectable({providedIn: 'root'})
export class SalesPopService {

  constructor(@Inject(PLATFORM_ID) private platformId: any, private http: HttpClient) {
  }


  public getSalesPopData(): Observable<any> {
    let url = `${isPlatformServer(this.platformId) ? environment.privateStoreApiUrl : environment.pubicStoreApiUrl}/applications/application-data/SALES_POP`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Get product
   * @param ids
   */
  public getSalesPopProduct(ids: string[]): Observable<any> {
    let url = `${isPlatformServer(this.platformId) ? environment.privateProductApiUrl : environment.pubicProductApiUrl}/products/just-purchased`;
    let params = new HttpParams();
    if (ids && ids.length > 0) {
      params = params.append('ids', ids.join(','));
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ignoreError': 'true'
      }),
      params: params
    };

    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }


  /**
   * Handler request error
   * @param error
   */
  public handleError(error: any) {
    return throwError(() => error);
  }

}
