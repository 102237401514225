<div class="order-card">
  <div class="oc-header">
    <div class="confirmation-message">
      <span>{{'ORDER_SUMMARY.CONFIRMED' | translate}}</span>
      <div class="ic"></div>
    </div>
    <div class="confirmation-data">
      <div class="cd-item">
        <div class="lbl">{{'ORDER_SUMMARY.ORDER_REF' | translate}}</div>
        <div class="val">{{order.ref}}</div>
      </div>
      <div class="cd-item">
        <div class="lbl">{{'ORDER_SUMMARY.CUSTOMER' | translate}}</div>
        <div class="val">
          <ng-container *ngIf="order.customer">
            <div>{{order.customer.firstName + ' ' + order.customer.lastName}}</div>
            <div>{{order.customer.email}}</div>
            <div>{{order.customer.phoneNumber}}</div>
          </ng-container>
          <ng-container *ngIf="!order.customer">{{getGuest}}</ng-container>
        </div>
      </div>
      <div class="cd-item">
        <div class="lbl">{{'ORDER_SUMMARY.ADDRESS' | translate}}</div>
        <div class="val">
          <ng-container *ngIf="order.customer">
            <span [innerHTML]="shippingAddress"></span>
          </ng-container>
          <ng-container *ngIf="!order.customer">
            <div *ngFor="let field of order.guest.fields">
              {{field.label}} : <span class="field-value">{{field.value}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="oc-items">
    <div class="oc-item" *ngFor="let item of order.orderItems;">
      <div class="pic">
        <img alt="store"  [src]="urlProductMediumMedia(item.product.media)"
             *ngIf="!item.variant && item.product.media"/>
        <img alt="store"  [src]="urlVariantMediumMedia(item.variant.media)"
             *ngIf="item.variant && item.variant.media"/>
        <div class="quantity">{{item.quantity}}</div>
      </div>
      <div class="product-data">
        <a href="/product/{{item.product.slug}}" class="product-name">{{item.product.name}}</a>
        <div class="product-variations" *ngIf="item.variant">
          <ng-container *ngFor="let option of item.variant.optionValues; let index = index">
            <div class="variation">{{option}}</div>
            <div class="sep" *ngIf="index < (item.variant.optionValues.length - 1 )">-</div>
          </ng-container>
        </div>
        <ng-container *ngIf="item.bundleOffer && item.bundleOffer.variations">
          <div class="product-variations" *ngFor="let variation of item.bundleOffer.variations">
            {{variation}}
          </div>
        </ng-container>
        <div class="product-discounts" *ngIf="item.discounts || item.upsell">
          <ng-container *ngIf="item.upsell && (item.upsell.fixedDiscount || item.upsell.discountPercent)">
            <div>
              <div class="discount">
                -<span
                *ngIf="item.upsell.fixedDiscount">{{item.upsell.fixedDiscount | price:order.exchangeRate:order.currency}}</span>
                <span *ngIf="item.upsell.discountPercent">{{item.upsell.discountPercent}}%</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngFor="let discount of item.discounts; let index = index">
            <div>
              <div class="discount">
                -<span *ngIf="discount.value">{{discount.value | price:order.exchangeRate:order.currency}}</span>
                <span *ngIf="discount.percent">{{discount.percent}}%</span>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="item.bundleOffer">
          <div class="product-bundle">
            {{item.bundleOffer.label}}
          </div>
        </div>
      </div>
      <div class="product-price" *ngIf="item.bundleOffer">
        <div class="final-price discount">
          <span>{{item.bundleOffer.initTotal || 0 | price:order.exchangeRate:order.currency}}</span></div>
        <div class="product-discounted">{{item.bundleOffer.offerTotal | price:order.exchangeRate:order.currency}}</div>
      </div>
      <div class="product-price" *ngIf="!item.bundleOffer">
        <div class="final-price"
             [class.discount]="item.discounts && item.discounts.length != 0 || (item.upsell && (item.upsell.fixedDiscount || item.upsell.discountPercent))">
          <span>{{item.total || 0 | price:order.exchangeRate:order.currency}}</span></div>
        <div class="product-discounted"
             *ngIf="(item.discounts && item.discounts.length) != 0 || (item.upsell && (item.upsell.fixedDiscount || item.upsell.discountPercent))">{{getDiscountedPrice(item) | price:order.exchangeRate:order.currency}}</div>
      </div>
    </div>
  </div>
  <div class="oc-footer">
    <div class="total-summary">
      <div class="sub-total-item">
        <div class="lbl">{{'ORDER_SUMMARY.SUB_TOTAL' | translate}}</div>
        <div
          class="val">{{order.subtotal < 0 ? 0 : order.subtotal | price:order.exchangeRate:order.currency}}</div>
      </div>
      <ng-container *ngIf="order.discounts && order.discounts.length > 0">
        <div class="sub-total-item">
          <div class="lbl" *ngFor="let discountItem of order.discounts">{{'ORDER_SUMMARY.DISCOUNT' | translate}}
            ({{discountItem.label}}
            - <span *ngIf="discountItem.value">{{discountItem.value | price:order.exchangeRate:order.currency}}</span>
            <span *ngIf="discountItem.percent">{{discountItem.percent}}%</span>)
          </div>
          <div class="val">
            -{{helperService.getDiscountValue(order.discounts, order.subtotal) | price:order.exchangeRate:order.currency}}</div>
        </div>
      </ng-container>
      <div class="sub-total-item" *ngIf="order.shippingCosts">
        <div class="lbl">{{'ORDER_SUMMARY.SHIPPING' | translate}}</div>
        <div class="val">{{order.shippingCosts || 0}}</div>
      </div>
      <div class="sub-total-item" *ngIf="order.taxes">
        <div class="lbl">{{'ORDER_SUMMARY.TAX' | translate}} ({{order.taxes}}%)</div>
        <div class="val">{{taxValue | price:order.exchangeRate:order.currency}}</div>
      </div>
      <div class="sub-total-item">
        <div class="lbl">{{'ORDER_SUMMARY.TOTAL' | translate}}</div>
        <div class="val">{{order.total < 0 ? 0 : order.total | price:order.exchangeRate:order.currency}}</div>
      </div>
    </div>
    <div class="thank-you-area" *ngIf="!hideContinueShopping">
      <a href="/search" class="neo-btn">{{'ORDER_SUMMARY.CONTINUE_SHOPPING' | translate}}</a>
    </div>
  </div>
</div>
