import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {
  ComponentLookup,
  getNeoProductsPerRow,
  ProductService,
  ShopyanCartHelperService,
  ShopyanFeaturedProductsComponent
} from '@app/_shared';
import {TransferState} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'lib-neo-featured-products',
  templateUrl: './neo-featured-products.component.html',
  styleUrls: ['./neo-featured-products.component.scss']
})
@ComponentLookup('neo-featured-products')
export class NeoFeaturedProductsComponent extends ShopyanFeaturedProductsComponent implements OnInit, AfterViewInit, OnDestroy {

  productsPerRow: number = 2;

  @ViewChild("layout")
  layout: ElementRef;

  constructor(productService: ProductService,
              shopyanCartHelperService: ShopyanCartHelperService,
              transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any) {
    super(productService, shopyanCartHelperService, transferState, platformId);
  }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.onResize();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let total = this.getFieldsGroupValue('products-layout', 'products-per-row') || 4;
    this.productsPerRow = getNeoProductsPerRow(this.layout.nativeElement.offsetWidth, total);
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
