import {Component, OnDestroy, OnInit} from '@angular/core';
import {ComponentLookup, ContactService, ShopyanContactComponent, ShopyanToastrService} from '@app/_shared';
import {FormBuilder} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lib-neo-contact-form',
  templateUrl: './neo-contact-form.component.html',
  styleUrls: ['./neo-contact-form.component.scss']
})
@ComponentLookup('neo-contact-form')
export class NeoContactFormComponent extends ShopyanContactComponent implements OnInit, OnDestroy {

  constructor(translate: TranslateService,
              formBuilder: FormBuilder,
              basicToastrService: ShopyanToastrService,
              contactService: ContactService) {
    super(translate, formBuilder, contactService, basicToastrService);
  }

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}



