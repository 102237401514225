import {Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {CountDown} from "@app/_shared";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'lib-count-down',
  templateUrl: './shoypan-count-down.component.html',
  styleUrls: ['./shoypan-count-down.component.scss']
})
export class ShoypanCountDownComponent implements OnInit, OnDestroy {

  @Input()
  countDown: CountDown;

  timer: number;
  totalPeople?: string;

  days: number;
  hours: number;
  minutes: number;
  seconds: number;

  intervalId: any;
  interval2Id: any;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
  }

  ngOnInit(): void {
    this.initTimer();
    this.tickTock();
    this.initTotalPeopleWatching();
    if (isPlatformBrowser(this.platformId)) {
      this.intervalId = setInterval(() => {
        this.tickTock();
      }, 1000);
      this.interval2Id = setInterval(() => {
        this.initTotalPeopleWatching();
      }, 5000);
    }
  }

  initTimer(): void {
    if (this.countDown.fixedTimer) {
      this.timer = this.countDown.fixedTimer * 60;
    } else if (this.countDown.targetDate) {
      const diffMs = (new Date(this.countDown.targetDate).getTime() - new Date().getTime());
      this.timer = Math.round(diffMs / 1000);
    }
  }

  tickTock(): void {
    if (this.timer >= 0) {
      const total_minutes = Math.floor(this.timer / 60);
      const total_hours = Math.floor(total_minutes / 60);
      this.days = Math.floor(total_hours / 24);
      this.hours = total_hours % 24;
      this.minutes = total_minutes % 60;
      this.seconds = this.timer % 60;
      this.timer--;
    }
  }


  numberFormatter(val: number): string {
    return val > 9 ? '' + val : '0' + val;
  }


  private initTotalPeopleWatching(): void {
    let min = this.countDown.minVisitors || 0;
    let max = this.countDown.maxVisitors || 0;
    let random = Math.floor(Math.random() * (Math.abs(max) - Math.abs(min) + 1) + Math.abs(min));
    this.totalPeople = random > 0 ? this.numberFormatter(random) : undefined;
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      clearInterval(this.intervalId);
      clearInterval(this.interval2Id);
    }
  }

}
