<div [id]="data.code" class="neo-collection-list shopyan-section" [class.build-mode]="buildMode"
     [style.background]="getFieldsGroupValue('layout', 'background')">

  <div class="neo-section-layout v-padding"
       [class.large]="getFieldsGroupValue('layout','layout-width') == 'large'">

    <lib-neo-section-header
      *ngIf="getFieldsGroup('collection-header') && !getFieldsGroup('collection-header').hidden"
      [data]="getFieldsGroup('collection-header')"></lib-neo-section-header>

    <div class="result-area" #layout>
      <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
      <ng-container *ngIf="collections && collections.length > 0">

        <div class="collections-content"  [style.grid-template-columns]="'repeat('+collectionsPerRow+',minmax(0, 1fr))'">
          <a href="/search?collection={{collection.id}}" class="collection"
             *ngFor="let collection of collections; let index= index">
            <div class="col-image" [class.default-bg]="!collection.media">
              <img alt="store"  [src]="urlCollectionMediumMedia(collection.media)" *ngIf="collection.media"/>
            </div>
            <div class="col-label">
              <span>{{collection.name}}</span>
            </div>
          </a>
        </div>

        <div class="show-more" *ngIf="showMore">
          <div class="neo-btn" (click)="findCollections(true)">
            {{'COMMON.SHOW_MORE' | translate}}
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="!loading && (!collections || collections.length == 0)">
        <lib-neo-no-result></lib-neo-no-result>
      </ng-container>

    </div>

  </div>
</div>
