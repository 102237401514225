import {Component, OnDestroy, OnInit} from '@angular/core';
import {ComponentLookup, ContactService, ShopyanNewsLetterComponent, ShopyanToastrService} from '@app/_shared';
import {FormBuilder} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lib-neo-newsletter',
  templateUrl: './neo-newsletter.component.html',
  styleUrls: ['./neo-newsletter.component.scss']
})

@ComponentLookup('neo-newsletter')
export class NeoNewsletterComponent extends ShopyanNewsLetterComponent implements OnInit, OnDestroy {

  constructor(translate: TranslateService,
              formBuilder: FormBuilder,
              contactService: ContactService,
              toastrService: ShopyanToastrService) {
    super(translate, formBuilder, toastrService, contactService);
  }

  ngOnInit(): void {
    this.init();
  }


  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}



