import {
  AuthService,
  Cart,
  CartForm,
  CartFormItem,
  CartItem,
  Discount,
  Product, ProductBundleOffer,
  ProductVariation,
  ProductVariationForm,
  ShopyanToastrService,
  StorageService,
  Upsell,
  VariationDiscount
} from "@app/_shared";
import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Subject, Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {ConversionService} from "@app/_shared/service/conversion.service";
import {isPlatformServer} from "@angular/common";

@Injectable({providedIn: 'root'})
export class ShopyanCartHelperService {
  subscription: Subscription = new Subscription();

  public cartSubject = new Subject();

  public langSubject = new Subject();

  constructor(private storageService: StorageService,
              private basicToastr: ShopyanToastrService,
              private http: HttpClient,
              @Inject(PLATFORM_ID) private platformId: any,
              private authService: AuthService,
              private conversionService: ConversionService) {

  }

  /**
   * Add item to cart
   * @param product
   * @param quantity
   * @param variationID
   * @param upsell
   */
  public addItemToCart(product: Product, quantity: number, variationID?: string, upsell?: Upsell): void {
    let cartItem = new CartItem();
    cartItem.product = {...product};
    cartItem.product.description = '';
    let price = cartItem.product.pricing?.price || 0;
    if (variationID) {
      cartItem.variation = product.variations.find(v => v.id === variationID);
      price = cartItem.variation?.price || 0;
    }
    cartItem.quantity = quantity;
    cartItem.total = +(quantity * price).toFixed(2);
    cartItem.discounts = product.discounts;
    if (upsell) {
      cartItem.upsell = upsell;
    }

    let expressCart = false;

    let cart: Cart = new Cart();
    const expressStorageData = this.storageService.getData('express_cart');
    if (expressStorageData) {
      cart = JSON.parse(expressStorageData);
      expressCart = true;
    } else {
      const storageData = this.storageService.getData('car');
      if (storageData) {
        cart = JSON.parse(storageData);
      }
    }

    if (!cart) {
      cart = new Cart();
    }

    if (!cart.items) {
      cart.items = [];
    }

    let existedItem;
    if (cartItem.variation && cartItem.upsell) {
      existedItem = cart.items.find(item => item.product.id === cartItem.product.id && item.variation?.id === cartItem.variation?.id && item.upsell?.id === cartItem.upsell?.id);
    } else if (cartItem.variation) {
      existedItem = cart.items.find(item => item.product.id === cartItem.product.id && item.variation?.id === cartItem.variation?.id);
    } else if (cartItem.upsell) {
      existedItem = cart.items.find(item => item.product.id === cartItem.product.id && item.upsell?.id === cartItem.upsell?.id);
    } else {
      existedItem = cart.items.find(item => item.product.id === cartItem.product.id);
    }

    if (existedItem) {
      existedItem.quantity += cartItem.quantity;
      existedItem.total = +(existedItem.total + cartItem.total).toFixed(2);
    } else {
      cart.items.push(cartItem);
    }

    let total = 0;
    cart.items.forEach(item => {
      total = +(total + this.getDiscountedPrice(item)).toFixed(2);
    });
    cart.total = total;

    if (expressCart) {
      this.storageService.saveData('express_cart', JSON.stringify(cart));
    } else {
      this.storageService.saveData('car', JSON.stringify(cart));
      this.synchronizeConnectedCart(cart);
    }

    this.subscription.add(this.conversionService.sendAddToCartEvent().subscribe());
    if (!this.storageService.getData('dis_add_to')) {
      this.cartSubject.next("");
    }
  }


  /**
   * Add bundle to cart
   * @param product
   * @param bundle
   */
  public addBundleToCart(product: Product, bundle: ProductBundleOffer): void {
    let cartItem = new CartItem();
    cartItem.product = {...product};
    cartItem.product.description = '';
    cartItem.quantity = 1;
    cartItem.total = bundle.offerTotal;
    cartItem.productBundle = {...bundle};
    cartItem.productBundle.product.description  = '';

    let cart: Cart = new Cart();
    const storageData = this.storageService.getData('car');
    if (storageData) {
      cart = JSON.parse(storageData);
    }

    if (!cart) {
      cart = new Cart();
    }

    if (!cart.items) {
      cart.items = [];
    }
    cart.items.push(cartItem);

    let total = 0;
    cart.items.forEach(item => {
        total = +(total + this.getDiscountedPrice(item)).toFixed(2);
    });
    cart.total = total;

    this.storageService.saveData('car', JSON.stringify(cart));
    this.synchronizeConnectedCart(cart);

    this.subscription.add(this.conversionService.sendAddToCartEvent().subscribe());
    if (!this.storageService.getData('dis_add_to')) {
      this.cartSubject.next("");
    }
  }

  refreshCart(cart: Cart): void {
    this.storageService.saveData('car', JSON.stringify(cart));
  }

  public synchronizeConnectedCart(cart: Cart): void {
    if (this.authService.isAuthenticated()) {
      let url = `${isPlatformServer(this.platformId) ? environment.privateOrdersApiUrl : environment.pubicOrdersApiUrl}/api/cart`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      this.subscription.add(this.http.put(url, this.getCartFormFromCart(cart), httpOptions).subscribe((response: any) => {
        cart.items.forEach((item, index) => cart.items[index].id = response[index]);
        this.storageService.saveData('car', JSON.stringify(cart));
      }));
    }
  }

  public isProductWishListChecked(productId: string) {
    let wishList: string[] = [];
    const storageData = this.storageService.getData('wis');
    if (storageData) {
      wishList = JSON.parse(storageData);
    }
    if (wishList && wishList instanceof Array && wishList.indexOf(productId) >= 0) {
      return true;
    }
    return false;
  }

  public toggleToWishList(productId: string): void {
    let wishList: string[] = [];
    const storageData = this.storageService.getData('wis');
    if (storageData) {
      wishList = JSON.parse(storageData);
    }

    if (!wishList || !(wishList instanceof Array)) {
      wishList = [];
    }
    if (wishList.indexOf(productId) >= 0) {
      wishList.splice(wishList.indexOf(productId), 1);
    } else {
      wishList.push(productId);
      this.subscription.add(this.conversionService.sendAddToWishListEvent().subscribe());
    }
    this.storageService.saveData('wis', JSON.stringify(wishList));
    if (this.authService.isAuthenticated()) {
      let url = `${isPlatformServer(this.platformId) ? environment.privateStoreApiUrl : environment.pubicStoreApiUrl}/api/customers/wishList`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      this.subscription.add(this.http.put(url, {wishList: wishList}, httpOptions).subscribe({
        next: (response: any) => {
        }, error: (error: any) => {
          this.basicToastr.error("error on updating user WishList!");
        }
      }));
    }

  }

  /**
   * Get cart form from cart
   * @param cart
   * @private
   */
  public getCartFormFromCart(cart: Cart): CartForm {
    let cartForm = new CartForm();
    cartForm.items = [];
    if (cart) {
      cart.items.forEach((cartItem: CartItem) => {
        const cartFormItem = new CartFormItem();
        cartFormItem.id = cartItem.id;
        cartFormItem.product = cartItem.product.id;
        cartFormItem.variation = cartItem.variation?.id;
        cartFormItem.quantity = cartItem.quantity;
        cartFormItem.total = cartItem.total;
        cartFormItem.upsell = cartItem.upsell?.id;
        cartFormItem.bundle = cartItem.productBundle?.bundle?.id;
        if(cartItem.productBundle?.variations) {
          cartFormItem.bundleVariations = cartItem.productBundle?.variations?.map(value => value.id);
        }
        cartForm.items.push(cartFormItem);
      });
      cartForm.comment = cart.comment;
      cartForm.total = cart.total;
    }
    return cartForm;
  }

  public initDiscounts(products: Product[]): void {
    if(products) {
      let url = `${isPlatformServer(this.platformId) ? environment.privateDiscountApiUrl : environment.pubicDiscountApiUrl}/`;

      if (this.authService.isAuthenticated()) {
        url = url + `api/discounts/products`;
      } else {
        url = url + `discounts/products`;
      }

      const request = products.map((product: Product) => {
        let productVariationForm = new ProductVariationForm();
        productVariationForm.id = product.id;
        productVariationForm.variations = product.variations?.map((variation: ProductVariation) => variation.id);
        return productVariationForm;
      });

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      this.subscription.add(this.http.post(url, request, httpOptions).subscribe((response: any) => {
        if (response) {
          products.forEach(product => {
            const productDiscount = response.find((value: any) => value.id == product.id);
            product.discounts = productDiscount?.discounts || [];
            const map = new Map(productDiscount?.variations?.map((obj: VariationDiscount) => [obj.id, obj.discounts]));
            product.variations?.forEach((variation: ProductVariation) => variation.discounts = map.get(variation.id) as Discount[]);
          });
        }
      }));
    }
  }

  getDiscountedPrice(item: CartItem) {
    let price = item.total || 0;
    if (item.upsell) {
      if (item.upsell.fixedDiscount) {
        price -= item.upsell.fixedDiscount;
      } else if (item.upsell.discountPercent) {
        price -= (item.upsell.discountPercent * price) / 100;
      }
    }
    price -= this.getDiscountValue(item.discounts, price);
    return price > 0 ? price : 0;
  }


  /**
   * get discount value
   * @param discounts
   * @param total
   */
  public getDiscountValue(discounts: any, total: number): number {
    let value = 0;
    if (discounts) {
      discounts.forEach((discount: any) => {
        if (discount.value) {
          value += discount.value;
        } else if (discount.percent) {
          value += (discount.percent * total) / 100;
        }
      });
    }
    return value > 0 ? value : 0;
  }




}
