import {Component, OnInit} from '@angular/core';
import {StorageService} from "@app/_shared";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private translate: TranslateService,
              private storageService: StorageService) {
  }

  ngOnInit(): void {
    const selectedLang = this.storageService.getData('my_lang') || 'fr';
    if (selectedLang) {
      this.translate.use(selectedLang);
    }
  }

  get direction(): any {
    return this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
  }

}
