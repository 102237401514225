<div id="account-form">
  <div class="account-form-section" [formGroup]="customerFG" *ngIf="customerFG">
    <div class="neo-field">
      <div class="neo-field-label">
        <label class="neo-label">
          <span>{{'ACCOUNT.PROFILE.TITLE' | translate}}</span>
        </label>
      </div>
    </div>
    <div class="neo-field">
      <input InputValidator type="text" class="neo-input" placeholder="{{'ACCOUNT.PROFILE.FIRST_NAME' | translate}}"
             formControlName="firstName"/>
      <app-field-error controlName="firstName" errorKey="required">
        {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
      </app-field-error>
      <app-field-error controlName="firstName" errorKey="maxlength">
        {{'COMMON.ERROR.TOO_LONG' | translate}}
      </app-field-error>
    </div>
    <div class="neo-field">
      <input InputValidator type="text" class="neo-input" placeholder="{{'ACCOUNT.PROFILE.LAST_NAME' | translate}}"
             formControlName="lastName"/>
      <app-field-error controlName="lastName" errorKey="maxlength">
        {{'COMMON.ERROR.TOO_LONG' | translate}}
      </app-field-error>
    </div>
    <div class="neo-field">
      <input InputValidator type="text" class="neo-input" placeholder="{{'ACCOUNT.PROFILE.EMAIL' | translate}}"
             formControlName="email"/>
      <app-field-error controlName="email" errorKey="required">
        {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
      </app-field-error>
      <app-field-error controlName="email" errorKey="pattern">
        {{'COMMON.ERROR.INVALID_MAIL' | translate}}
      </app-field-error>
      <app-field-error controlName="email" errorKey="maxlength">
        {{'COMMON.ERROR.TOO_LONG' | translate}}
      </app-field-error>
    </div>
    <div class="neo-field">
      <input InputValidator type="tel" class="neo-input" placeholder="{{'ACCOUNT.PROFILE.PHONE' | translate}}"
             formControlName="phoneNumber"/>
    </div>
    <div class="neo-field marketing-mails">
      <mat-checkbox formControlName="marketingEmails">
        <span class="marketing-label">{{'ACCOUNT.PROFILE.AGREE_RECEIVE_MAILS' | translate}}</span>
      </mat-checkbox>
    </div>
    <button loadingBtn [loading]="customerLoading" (click)="submitCustomerForm()" class="neo-btn update-btn">
      {{'ACCOUNT.PROFILE.UPDATE' | translate}}
    </button>
  </div>
  <div class="account-form-section" [formGroup]="passwordFG" *ngIf="passwordFG">
    <div class="neo-field">
      <div class="neo-field-label">
        <label class="neo-label">
          <span>{{'ACCOUNT.PROFILE.UPDATE_PASSWORD' | translate}}</span>
        </label>
      </div>
      <div class="neo-flex">
        <div class="neo-field password-field">
          <div class="password-toggle" (click)="togglePassword()" [class.to-hide]="passwordVisible"></div>
          <input InputValidator [type]="passwordVisible ? 'text' : 'password'" class="neo-input"
                 placeholder="{{'ACCOUNT.PROFILE.CURRENT_PASSWORD' | translate}}" formControlName="password"/>
          <app-field-error controlName="password" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
          <app-field-error controlName="password" errorKey="minlength">
            {{'COMMON.ERROR.TOO_SHORT' | translate}}
          </app-field-error>
          <app-field-error controlName="password" errorKey="maxlength">
            {{'COMMON.ERROR.TOO_LONG' | translate}}
          </app-field-error>
        </div>
        <div class="neo-field password-field">
          <div class="password-toggle" (click)="toggleNewPassword()" [class.to-hide]="newPasswordVisible"></div>
          <input InputValidator [type]="newPasswordVisible ? 'text' : 'password'" class="neo-input"
                 placeholder="{{'ACCOUNT.PROFILE.NEW_PASSWORD' | translate}}" formControlName="newPassword"/>
          <app-field-error controlName="newPassword" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
          <app-field-error controlName="newPassword" errorKey="minlength">
            {{'COMMON.ERROR.TOO_SHORT' | translate}}
          </app-field-error>
          <app-field-error controlName="newPassword" errorKey="maxlength">
            {{'COMMON.ERROR.TOO_LONG' | translate}}
          </app-field-error>
        </div>
      </div>
    </div>
    <button loadingBtn [loading]="passwordLoading" (click)="submitPasswordForm()" class="neo-btn update-btn">
      {{'ACCOUNT.PROFILE.UPDATE' | translate}}
    </button>
  </div>
  <div class="account-form-section">
    <div class="neo-field">
      <div class="neo-field-label">
        <label class="neo-label">
          <span>{{'ACCOUNT.PROFILE.MY_ADDRESSES' | translate}}</span>
        </label>
      </div>
    </div>
    <ng-container *ngFor="let address of customer.addresses; let index = index">
      <div class="address-inf">
        <span [innerHTML]="getAddressAsString(address)"></span>
        <div class="more">
          <div class="default" *ngIf="address.defaultAddress">{{'ACCOUNT.PROFILE.DEFAULT_ADDRESS' | translate}}</div>
          <div class="mark-as-default" *ngIf="!address.defaultAddress" (click)="markAsDefault(address.id!, index)">
            {{'ACCOUNT.PROFILE.MARK_AS_DEFAULT_ADDRESS' | translate}}
          </div>
          <div class="edit" (click)="openAddressFormModal(address, index)">{{'ACCOUNT.PROFILE.EDIT' | translate}}</div>
          <div class="delete" (click)="deleteAddress(address.id!, index)" *ngIf="customer.addresses.length > 1">
            {{'ACCOUNT.PROFILE.DELETE' | translate}}
          </div>
        </div>
      </div>
    </ng-container>
    <div class="neo-btn update-btn" (click)="openAddressFormModal()">{{'ACCOUNT.PROFILE.ADD_ADDRESS' | translate}}</div>
  </div>
</div>
