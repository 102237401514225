import {Discount, ProductOption, ProductVariation, VariationDiscount} from "@app/_shared/model";

export class Product{
  id: string;
  name: string;
  description: string;
  available: boolean;
  options: ProductOption[];
  variations: ProductVariation[];
  pricing: ProductPrice;
  sku: string;
  stock: number;
  disableOutOfStock: boolean;
  tags: string[];
  medias: string[];
  imageLinks: string[];
  youtubeIds: string[];
  rate: number;
  rateCount: number;
  discounts: Discount[];
  slug: string;
}

export class ProductPrice {
  price: number;
  basicPrice: number;
}

export class ProductVariationForm {
  id: string;
  variations: string[];
}

export class ProductDiscount {
  id: string;
  variations: VariationDiscount[];
  discounts: Discount[];
}
