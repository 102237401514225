export class ProductOption {
  id: string;
  name: string;
  type: VariationOptionType;
  optionValues: ProductOptionValue[];
}

export class ProductOptionValue {
  color?: string;
  media?: string;
  value: string;
}

export enum VariationOptionType {
  TEXT = 'TEXT',
  COLOR = 'COLOR',
  IMAGE = 'IMAGE'
}
