import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {
  AuthService,
  Collection,
  CollectionService,
  ComponentLookup,
  MediaSize,
  MediaType,
  Pageable,
  Product,
  ProductService,
  ShopyanCartHelperService,
  urlMedia
} from '@app/_shared';
import {Subscription} from "rxjs";
import {DomSanitizer, makeStateKey, TransferState} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {isPlatformServer} from "@angular/common";

@Component({
  selector: 'lib-neo-collection',
  templateUrl: './neo-collection.component.html',
  styleUrls: ['./neo-collection.component.scss']
})
@ComponentLookup('neo-collection')
export class NeoCollectionComponent extends ShopyanSectionComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  collection: Collection;
  products: Product[] = [];

  pageable: Pageable = new Pageable();
  loading: boolean;

  constructor(private _sanitizer: DomSanitizer,
              private authService: AuthService,
              private basicHelperService: ShopyanCartHelperService,
              private productService: ProductService,
              private collectionService: CollectionService,
              private transferState: TransferState,
              @Inject(PLATFORM_ID) protected platformId: any) {
    super();
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId) || this.buildMode) {
      if (this.getFieldsGroupValue('collection-layout', 'max-products')) {
        this.pageable.size = this.getFieldsGroupValue('collection-layout', 'max-products');
      }
      const collectionCode = this.getFieldsGroupValue('collection-layout', 'collection');
      if (collectionCode) {
        this.findCollection(collectionCode);
        this.filterProducts(collectionCode);
      }
    } else {
      this.collection = this.transferState.get(makeStateKey(this.data.code + '-collection'), null as any);
      this.products = this.transferState.get(makeStateKey(this.data.code + '-products'), null as any);
      if (this.products && this.products.length > 0) {
        this.basicHelperService.initDiscounts(this.products);
      }
    }
  }

  /**
   * Filter products
   */
  filterProducts(collectionCode: string): void {
    this.subscription.add(this.productService.findCollectionProducts(this.pageable, collectionCode).subscribe((response: any) => {
      if (response) {
        this.products = response.data;
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(makeStateKey(this.data.code + '-products'), this.products as any);
        }
        this.basicHelperService.initDiscounts(this.products);
      }
    }));
  }

  findCollection(collectionCode: string): void {
    this.loading = true;
    this.subscription.add(this.collectionService.findCollection(collectionCode).subscribe((response: any) => {
      this.loading = false;
      if (response) {
        this.collection = response;
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(makeStateKey(this.data.code + '-collection'), this.collection as any);
        }
      }
    }));
  }

  urlCollectionMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.COLLECTION, MediaSize.medium, media);
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
