import {Cart, CheckoutField, Customer, PaymentMethod, ShippingItem, StoreAccountMode} from "@app/_shared";

export class CheckoutData {
  storeAccountMode: StoreAccountMode;
  checkoutFields: CheckoutField[];
  customer?: Customer;
  paymentMethods: PaymentMethod[];
  shippingItems?: ShippingItem[];
  cart: Cart;
  tax?: number;
}
