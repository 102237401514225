import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {Product, ProductService, ShopyanCartHelperService, StorageService,} from "@app/_shared";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  template: ''
})
export abstract class ShopyanFavoriteProductsComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  products: Product[] = [];
  showMore = false;
  page = 0;
  perPage = 20;

  loading: boolean;

  protected constructor(
    private storageService: StorageService,
    private productService: ProductService,
    private basicHelperService: ShopyanCartHelperService) {
    super();
  }


  /**
   * Filter products
   */
  filterProducts(isShowMore?: boolean): void {

    let wishList: string[] = [];
    const storageData = this.storageService.getData('wis');
    if (storageData) {
      wishList = JSON.parse(storageData);
    }

    if (!wishList || !(wishList instanceof Array)) {
      wishList = [];
    }

    if (isShowMore) {
      this.page++;
    }
    const ids = wishList.slice((this.page * this.perPage), ((this.page + 1) * this.perPage));
    if (!ids || ids.length === 0) {
      this.showMore = false;
      return;
    }
    if (ids.length < this.perPage) {
      this.showMore = false;
    } else {
      this.showMore = true;
    }

    this.loading = true;

    this.subscription.add(this.productService.findFavoriteProducts(ids).subscribe((response: any) => {
      this.loading = false;
      if (response) {
        this.basicHelperService.initDiscounts(response);
        this.products = [...this.products, ...response];
      }
    }));
  }

}
