import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {
  Address,
  AuthService,
  Cart,
  CartItem,
  CheckoutData, CheckoutFieldSubType,
  CheckoutFieldType,
  CheckoutForm,
  Customer,
  Discount,
  EventTypeEnum,
  ExpressOrder,
  extractErrorMessagesFromResponse,
  MediaSize,
  MediaType,
  PaymentMethod,
  ShippingItem,
  ShopyanCartHelperService,
  ShopyanToastrService,
  StorageService,
  StoreAccountMode,
  urlMedia
} from "@app/_shared";
import {combineLatest, Subscription} from "rxjs";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {loadStripe} from "@stripe/stripe-js/pure";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanCheckoutComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  loading: boolean;
  /**
   * Form group
   */
  expressFG: FormGroup;
  shippingAddressFG: FormGroup;
  billingAddressFG: FormGroup;

  /**
   * Enums
   */
  checkoutFieldType = CheckoutFieldType;
  checkoutFieldSubType= CheckoutFieldSubType;
  storeAccountMode = StoreAccountMode;
  paymentMethod = PaymentMethod;

  /**
   * Data
   */
  checkoutData: CheckoutData;
  checkoutForm = new CheckoutForm();
  appliedDiscount: Discount | null;
  selectedShipping: ShippingItem;
  discountCode: string | null;
  appliedDiscountCode: string | null;
  checkoutCart: Cart;
  expressCheckoutCart: Cart;

  /**
   * Screen management
   */
  checkoutLoading: boolean;
  discountError: string | null;

  protected constructor(
                        protected translate: TranslateService,
                        public formBuilder: FormBuilder,
                        private router: Router,
                        private storageService: StorageService,
                        private basicToastr: ShopyanToastrService,
                        public authService: AuthService,
                        public basicHelperService: ShopyanCartHelperService,
                        private http: HttpClient,
                        @Inject(PLATFORM_ID) private platformId: any) {
    super();
    loadStripe.setLoadParameters({advancedFraudSignals: false});
  }

  init() {
    if (!this.buildMode && isPlatformBrowser(this.platformId)) {
      this.refreshCheckout();
      this.sendCheckoutEvent();
    }
  }

  refreshCheckout(): void {
    this.loading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    // Get cart object
    let cart = this.getCartFromStorage();

    // Browser only - no private api call
    let checkoutDataUrl = `${environment.pubicOrdersApiUrl}/checkout/data`;
    if (this.authService.isAuthenticated() && !this.expressCheckoutCart) {
      checkoutDataUrl = `${environment.pubicOrdersApiUrl}/api/orders/checkout/data`;
    }

    this.subscription.add(combineLatest([
        // Browser only - no private api call
        this.http.get(`${environment.pubicStoreApiUrl}/express-checkout`, httpOptions),
        this.http.post(checkoutDataUrl, this.basicHelperService.getCartFormFromCart(cart), httpOptions)
      ]).subscribe({
        next: ([expressCheckoutResponse, checkoutDataResponse]: [any, any]) => {
          this.loading = false;
          this.checkoutData = new CheckoutData();

          // Express checkout
          if (expressCheckoutResponse && expressCheckoutResponse.fields) {
            this.checkoutData.checkoutFields = expressCheckoutResponse.fields.filter(((item: any) => !item.disabled));
          }
          // Account required
          this.checkoutData.storeAccountMode = expressCheckoutResponse.accountMode;
          this.checkoutData.tax = expressCheckoutResponse.tax;

          if (!this.buildMode && !this.expressCheckoutCart
            && this.checkoutData.storeAccountMode === StoreAccountMode.REQUIRED
            && !this.authService.isAuthenticated()) {
            this.router.navigateByUrl(`/login`).then();
          }

          // Payment methods
          this.checkoutData.paymentMethods = checkoutDataResponse.paymentMethods;

          // Init forms
          if (this.expressCheckoutCart && this.checkoutData.checkoutFields && this.checkoutData.checkoutFields.length > 0) {
            this.checkoutData.paymentMethods = [PaymentMethod.CASH_ON_DELIVERY];
            this.initExpressCheckoutForm();
          } else {
            this.initStandardForm()
          }

          if (this.authService.isAuthenticated()) {
            this.checkoutData.customer = this.authService.getConnectedUser() as Customer;
          }
          this.checkoutData.shippingItems = checkoutDataResponse.shippingItems as ShippingItem[];

          this.checkoutCart = JSON.parse(JSON.stringify(checkoutDataResponse.cart));
          this.checkoutData.cart = checkoutDataResponse.cart;
          // Refresh
          if (!this.expressCheckoutCart && this.checkoutData.cart) {
            this.basicHelperService.refreshCart(this.checkoutData.cart!);
          }

          // First payment method by default
          this.checkoutForm.paymentMethod = this.checkoutData.paymentMethods[0];

        },
        error: ([error]: any) => {
          this.loading = false;
          this.basicToastr.error(extractErrorMessagesFromResponse(error));
          this.router.navigateByUrl(`/cart`).then();
        }
      })
    );

    // Check upsells
    this.checkUpsells();
  }

  sendCheckoutEvent() {
    let url = `${environment.pubicStoreApiUrl}/events`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };
    this.http
      .post(url, {
        eventType: EventTypeEnum.CHECKOUT
      }, httpOptions).subscribe();
  }

  getCartFromStorage(): any {
    let cart: any;

    // If coming from express checkout
    let expressStorageData = this.storageService.getData('express_cart');
    if (expressStorageData) {
      this.expressCheckoutCart = JSON.parse(expressStorageData);
      cart = this.expressCheckoutCart;
    } else {
      let storageData = this.storageService.getData('car');
      if (storageData) {
        cart = JSON.parse(storageData);
      }
    }
    return cart;
  }

  protected abstract checkUpsells(): void;


  /**
   * Init express checkout form
   */
  initExpressCheckoutForm(): void {

    this.expressFG = this.formBuilder.group({
      fields: this.formBuilder.array([])
    });
    const fieldsArray = this.expressFG.get('fields') as FormArray;
    this.checkoutData.checkoutFields.forEach((item: any) => {
      let validators = [];
      if (item.required) {
        validators.push(Validators.required);
      }

      if (item.type === CheckoutFieldType.MAIL) {
        validators.push(Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'));
      }

      let defaultValue = item.defaultValue;

      if(!defaultValue && item.subType && item.subType === CheckoutFieldSubType.SELECT){
        defaultValue = '';
      }

      fieldsArray.push(this.formBuilder.group({
        id: [item.id],
        value: [defaultValue, validators]
      }));

    });

  }

  /**
   * Init standard form
   */
  initStandardForm(): void {
    this.shippingAddressFG = this.initAddressFormGroup();
    this.billingAddressFG = this.initAddressFormGroup();
  }

  /**
   * Common method to init an address form group
   * @private
   */
  initAddressFormGroup(): FormGroup {
    return this.formBuilder.group({
      firstName: [null, [Validators.required, Validators.maxLength(35)]],
      lastName: [null, [Validators.required, Validators.maxLength(35)]],
      line1: [null, Validators.required],
      line2: [null],
      phoneNumber: [null, [Validators.required, Validators.pattern('^(\\+[0-9]{1,3}|0)[0-9]{9}$')]],
      city: [null, Validators.required],
      zipCode: [null, [Validators.maxLength(10)]],
      country: [null, Validators.required],
    });
  }

  /**
   * Tax value
   */
  get taxValue(): number {
    if (this.checkoutData.tax) {
      let total = this.checkoutData.cart.total;

      // Automatic Discounts
      this.checkoutData.cart.discounts?.forEach((item: any) => {
        if (item.value) {
          total -= item.value;
        } else if (item.percent) {
          total -= (item.percent * this.checkoutData.cart.total) / 100;
        }
      });

      // Applied manually
      if (this.appliedDiscount) {
        if (this.appliedDiscount.value) {
          total -= this.appliedDiscount.value;
        } else if (this.appliedDiscount.percent) {
          total -= (this.appliedDiscount.percent * this.checkoutData.cart.total) / 100;
        }
      }
      return +(this.checkoutData.tax * total) / 100;
    }
    return 0;
  }

  /**
   * Calculate big total
   */
  get bigTotal(): number {

    let total = this.checkoutData.cart.total;

    // Automatic Discounts
    this.checkoutData.cart.discounts?.forEach((item: any) => {
      if (item.value) {
        total -= item.value;
      } else if (item.percent) {
        total -= (item.percent * this.checkoutData.cart.total) / 100;
      }
    });

    // Applied manually
    if (this.appliedDiscount) {
      if (this.appliedDiscount.value) {
        total -= this.appliedDiscount.value;
      } else if (this.appliedDiscount.percent) {
        total -= (this.appliedDiscount.percent * this.checkoutData.cart.total) / 100;
      }
    }

    // Taxes
    if (this.checkoutData.tax) {
      total += (this.checkoutData.tax * total) / 100;
    }

    // Shipping
    if (this.selectedShipping && this.selectedShipping.price) {
      total += this.selectedShipping.price;
    }

    return total < 0 ? 0 : total;
  }

  /**
   * Apply discount
   */
  applyDiscount(): void {
    if (this.discountCode) {
      const apiUrl = isPlatformServer(this.platformId) ? environment.privateDiscountApiUrl : environment.pubicDiscountApiUrl
      let url = `${apiUrl}/discounts/${this.discountCode}`;

      if (this.authService.isAuthenticated()) {
        url = `${apiUrl}/api/discounts/${this.discountCode}`;
      }
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      this.subscription.add(this.http.post(url, this.basicHelperService.getCartFormFromCart(this.checkoutData.cart), httpOptions).subscribe({
        next: (response: any) => {
          this.checkoutData.cart = JSON.parse(JSON.stringify(this.checkoutCart));
          let discount = new Discount();
          discount.code = this.discountCode as string;
          discount.percent = response.percent;
          discount.label = response.label;
          discount.value = response.fixedAmount;
          if (response.type == "ORDER") {
            this.appliedDiscount = discount;
          }
          if (response.type == "PRODUCT") {
            const validItems = response.items.map((item: any) => item.product + item.variation);
            this.checkoutData.cart.items.forEach((item: CartItem) => {
              if (validItems.includes(item.product.id + item.variation?.id)) {
                if (!item.discounts) item.discounts = [];
                item.discounts.push(discount);
                this.checkoutData.cart.total -= this.basicHelperService.getDiscountValue([discount], item.total);
              }
            });
          }
          this.appliedDiscountCode = JSON.parse(JSON.stringify(this.discountCode));
          this.discountCode = '';
          this.discountError = null;
          this.basicToastr.success(this.translate.instant("CHECKOUT.MESSAGE.DISCOUNT_APPLIED"));
        }, error: (errorRes: any) => {
          this.discountError = errorRes.error.errors[0];
          this.discountCode = null;
          this.appliedDiscount = null;
          this.appliedDiscountCode = null;
        }
      }));
    }
  }


  /**
   * Submit checkout value
   */

  submitCheckout() {
    // Form groups
    if (this.expressFG) {
      this.expressFG.markAllAsTouched();
      if (!this.expressFG.valid) {
        this.basicToastr.error(this.translate.instant("COMMON.MESSAGE.FILL_REQUIRED_FIELDS"));
        return;
      }
      this.checkoutForm.expressFormFields = [];
      Object.assign(this.checkoutForm.expressFormFields, this.expressFG.get('fields')?.value);

    } else {

      // Shipping address
      delete this.checkoutForm.shippingAddress;
      if (this.checkoutForm.existingShippingAddress === '') {
        this.shippingAddressFG.markAllAsTouched();
        if (!this.shippingAddressFG.valid) {
          this.basicToastr.error(this.translate.instant("COMMON.MESSAGE.FILL_REQUIRED_FIELDS"));
          return;
        }
        this.checkoutForm.shippingAddress = new Address();
        Object.assign(this.checkoutForm.shippingAddress, this.shippingAddressFG.value);
      }

      // Billing address
      delete this.checkoutForm.billingAddress;
      if (this.checkoutForm.existingBillingAddress === '-1') {
        this.billingAddressFG.markAllAsTouched();
        if (!this.billingAddressFG.valid) {
          this.basicToastr.error(this.translate.instant("COMMON.MESSAGE.FILL_REQUIRED_FIELDS"));
          return;
        }
        this.checkoutForm.billingAddress = new Address();
        Object.assign(this.checkoutForm.billingAddress, this.billingAddressFG.value);
      }

    }

    // Cart form
    this.checkoutForm.cartForm = this.basicHelperService.getCartFormFromCart(this.checkoutData.cart);

    // Discount code
    if (this.appliedDiscountCode) {
      this.checkoutForm.discountCode = this.appliedDiscountCode;
    }

    // Shipping
    if (this.selectedShipping) {
      this.checkoutForm.shipping = this.selectedShipping.id;
    }

    this.checkoutLoading = true;
    if (this.expressFG) {
      this.submitExpressCheckout();
    } else {
      this.submitStandardCheckout();
    }
  }

  submitStandardCheckout() {
    this.checkoutForm.origin = window.location.origin;

    // Browser only - no private api call
    let url = `${environment.pubicOrdersApiUrl}/checkout`;

    if (this.authService.isAuthenticated()) {
      url = `${environment.pubicOrdersApiUrl}/api/orders/checkout`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const sub = this.http.post(url, this.checkoutForm, httpOptions).subscribe({
      next: (res: any) => {
        if (res.cart) {
          this.basicToastr.error(this.translate.instant("CHECKOUT.MESSAGE.CART_CHANGED"));
          //TODO show items with errors
          this.basicHelperService.refreshCart(res.cart);
          this.basicHelperService.synchronizeConnectedCart(res.cart);
          this.checkoutLoading = false;
          return;
        }
        if (res.ignorePaiement) {
          this.router.navigateByUrl(`/thank-you?order=${res.orderCode}`).then();
        } else {
          switch (this.checkoutForm.paymentMethod) {
            case PaymentMethod.CREDIT_CARD:
              loadStripe(res.token).then(
                stripe => { // Success
                  stripe?.redirectToCheckout({
                    sessionId: res.payId,
                  });
                }
              );
              break;
            case PaymentMethod.PAYPAL:
              window.location.href = res.redirectUrl;
              break;
            case PaymentMethod.CASH_ON_DELIVERY:
              this.router.navigateByUrl(`/thank-you?order=${res.orderCode}`).then();
              break;
          }
        }
        this.checkoutLoading = false;
      }, error: error => {
        this.basicToastr.error(extractErrorMessagesFromResponse(error));
        this.checkoutLoading = false;
        this.refreshCheckout();
      }
    });
    this.subscription.add(sub);
  }

  /**
   * Submit express checkout
   */
  submitExpressCheckout(): void {

    let expressOrder = new ExpressOrder();
    expressOrder.formFields = this.checkoutForm.expressFormFields;

    expressOrder.origin = window.location.origin;
    expressOrder.items = this.checkoutForm.cartForm.items;
    expressOrder.discountCode = this.checkoutForm.discountCode;

    // Browser only - no private api call
    let url = `${environment.pubicOrdersApiUrl}/checkout/express`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.subscription.add(this.http.post(url, expressOrder, httpOptions).subscribe({
      next: (response: any) => {
        if (response.cart) {
          this.basicToastr.error(this.translate.instant("CHECKOUT.MESSAGE.CART_CHANGED"));
          //TODO show items with errors
          this.basicHelperService.refreshCart(response.cart);
          this.basicHelperService.synchronizeConnectedCart(response.cart);
          this.checkoutLoading = false;
          return;
        }
        this.basicToastr.success(this.translate.instant("CHECKOUT.MESSAGE.SUCCESS"));
        this.storageService.removeData('express_cart');
        this.router.navigateByUrl(`/thank-you?order=${response.orderCode}`).then();
      },
      error: (error: any) => {
        this.checkoutLoading = false;
        this.basicToastr.error(extractErrorMessagesFromResponse(error));
        this.refreshCheckout();
      }
    }));

  }

  urlProductMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.PRODUCT, MediaSize.medium, media);
  }

  urlVariantMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.VARIANT, MediaSize.medium, media);
  }


}
