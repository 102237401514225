import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {OrderDetail} from "@app/_shared";

@Injectable({
  providedIn: 'root'
})
export class ConversionService {


  // Browser only - no private api call
  constructor(private http: HttpClient) {
  }

  public sendPageViewEvent(): Observable<any> {
    let url = `${environment.pubicStoreApiUrl}/conversion-api/page-view`;
    return this.sendConversionEvent(url);
  }

  public sendSearchEvent(): Observable<any> {
    let url = `${environment.pubicStoreApiUrl}/conversion-api/search`;
    return this.sendConversionEvent(url);
  }

  public sendViewContentEvent(): Observable<any> {
    let url = `${environment.pubicStoreApiUrl}/conversion-api/view-content`;
    return this.sendConversionEvent(url);
  }

  public sendAddToCartEvent(): Observable<any> {
    let url = `${environment.pubicStoreApiUrl}/conversion-api/add-to-cart`;
    return this.sendConversionEvent(url);
  }

  public sendAddToWishListEvent(): Observable<any> {
    let url = `${environment.pubicStoreApiUrl}/conversion-api/add-to-wishlist`;
    return this.sendConversionEvent(url);
  }

  public sendBuyEvent(order: OrderDetail): Observable<any> {
    let url = `${environment.pubicStoreApiUrl}/conversion-api/buy`;
    let params = new HttpParams()
    params = params.append('origin', window.location.href);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    const customData = {
      'order_id': order.ref,
      'content_ids': order.orderItems.map(item => item.product.code),
      'content_name': order.orderItems.map(item => item.product.name).join(', '),
      'content_type': 'product',
      'currency': order.currency,
      'value': order.total,
      'num_items': order.orderItems.length
    }

    return this.http
      .post(url, customData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private sendConversionEvent(url: string): Observable<any> {
    let params = new HttpParams()
    params = params.append('origin', window.location.href);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Handler request error
   * @param error
   */
  public handleError(error: any) {
    return throwError(error);
  }

}
