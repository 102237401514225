import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {
  ComponentLookup,
  OrderService,
  ShopyanCartHelperService,
  ShopyanOrderSummaryComponent,
  ShopyanToastrService
} from '@app/_shared';
import {DomSanitizer, TransferState} from "@angular/platform-browser";
import {Router} from "@angular/router";

@Component({
  selector: 'lib-neo-order-summary',
  templateUrl: './neo-order-summary.component.html',
  styleUrls: ['./neo-order-summary.component.scss']
})
@ComponentLookup('neo-order-summary')
export class NeoOrderSummaryComponent extends ShopyanOrderSummaryComponent implements OnInit, OnDestroy {

  constructor(_sanitizer: DomSanitizer,
              orderService: OrderService,
              basicToastr: ShopyanToastrService,
              router: Router,
              basicHelperService: ShopyanCartHelperService,
              transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any) {
    super(_sanitizer, orderService, basicToastr, router, basicHelperService, transferState, platformId);
  }

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
