import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, ComponentLookup, ShopyanLoginComponent, StorageService} from '@app/_shared';
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lib-neo-login',
  templateUrl: './neo-login.component.html',
  styleUrls: ['./neo-login.component.scss']
})
@ComponentLookup('neo-login')
export class NeoLoginComponent extends ShopyanLoginComponent implements OnInit, OnDestroy {

  constructor(translate: TranslateService,
              formBuilder: FormBuilder,
              authService: AuthService,
              storageService: StorageService,
              router: Router) {
    super(translate, formBuilder, authService, storageService, router);
  }

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
