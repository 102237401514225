import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  Collection,
  CollectionService,
  constructLink,
  GraphBlock,
  Link,
  MediaSize,
  MediaType,
  Product,
  ProductService,
  urlMedia
} from "@app/_shared";
import {Subscription} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'lib-neo-collage-item',
  templateUrl: './neo-collage-item.component.html',
  styleUrls: ['./neo-collage-item.component.scss']
})
export class NeoCollageItemComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  @Input()
  data: GraphBlock | null;

  @Input()
  storeId: string;

  product: Product;
  collection: Collection;
  youtubeSafeURL: any;
  imgURL: string;


  currentClass: string;

  constructor(private productService: ProductService,
              private _sanitizer: DomSanitizer,
              private collectionService: CollectionService) {
  }

  ngOnInit(): void {
    if (this.data && this.data.values && this.data.values.length > 0 && this.data.values[0].value) {
      switch (this.data.blockCode) {
        case 'product-item':
          this.initProduct();
          break;
        case 'collection-item':
          this.initCollection();
          break;
        case 'video-item':
          if (this.data.values[0].value) {
            const tmpURL = 'https://youtube.com/embed/' + this.getYoutubeVideoId(this.data.values[0].value);
            this.youtubeSafeURL = this._sanitizer.bypassSecurityTrustResourceUrl(tmpURL);
          }
          break;
        case 'image-item':
          if (this.data.values[0].value) {
            this.imgURL = urlMedia(environment.mediaURL + "/", this.storeId, MediaType.STORE, MediaSize.original, this.data.values[0].value);
          }
          break;
      }
    }

    const classes = ['a', 'b', 'c', 'd'];
    this.currentClass = classes[Math.floor(Math.random() * 4)];
  }

  private initProduct(): void {
    let sub = this.productService.findProduct(this.data!.values[0].value, true).subscribe((result: any) => {
      this.product = result;
    });

    this.subscription.add(sub)
  }

  private initCollection(): void {
    let sub = this.collectionService.findCollection(this.data!.values[0].value).subscribe((result: any) => {
      this.collection = result;
    });

    this.subscription.add(sub)
  }

  private getYoutubeVideoId(url: string): string | null {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  get imgFit(): string | undefined {
    if (this.data && this.data.values) {
      return this.data.values.find((item: any) => item.fieldCode === 'img-fit')?.value;
    }
    return undefined;
  }

  urlCollectionMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.COLLECTION, MediaSize.medium, media);
  }

  urlProductMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.PRODUCT, MediaSize.medium, media);
  }

  get noImage(): boolean {
    return !this.youtubeSafeURL && !this.imgURL && !this.product && !this.collection;
  }

  public getFieldValue(fieldCode: string): any {
    return this.data?.values?.find((item: any) => item.fieldCode === fieldCode)?.value;
  }

  getLink(link: Link): any {
    let l = constructLink(link);
    return l ? this._sanitizer.bypassSecurityTrustResourceUrl(l) : undefined;
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
