import {Component, OnInit} from '@angular/core';
import {ComponentLookup, GraphBlock} from '@app/_shared';
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  selector: 'lib-neo-collage',
  templateUrl: './neo-collage.component.html',
  styleUrls: ['./neo-collage.component.scss']
})
@ComponentLookup('neo-collage')
export class NeoCollageComponent extends ShopyanSectionComponent implements OnInit {

  blocks: GraphBlock[][] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    let arr: GraphBlock[] = [];
    this.data.blocks?.filter(b => !b.hidden).forEach(item => {
      arr.push(item);
      if (arr.length == 3) {
        this.blocks.push([...arr]);
        arr = [];
      }
    });

    if (arr.length > 0) {
      this.blocks.push([...arr]);
    }
  }

}


