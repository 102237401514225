import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {StoreService} from "@app/_shared/service/store.service";
import {AuthService, ShopyanToastrService} from "@app/_shared";
import {TranslateService} from "@ngx-translate/core";
import {isPlatformBrowser} from "@angular/common";


@Injectable()
export class StoreHttpInterceptor implements HttpInterceptor {


  constructor(
    private router: Router,
    private storeService: StoreService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: any,
    private toastrService: ShopyanToastrService,
    private translateService: TranslateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.storeService.storeId) {
      request = request.clone({
        headers: request.headers.set('storeId', this.storeService.storeId)
      });
    }

    const idToken = this.authService.getToken();
    if (idToken) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + idToken)
      });
    }

    const acceptLanguage = this.translateService.currentLang || 'fr';
    request = request.clone({
      setHeaders: {
        'Accept-Language': acceptLanguage
      }
    });

    const ignoreError = request.headers.get('ignoreError');
    return next.handle(request).pipe(catchError(error => this.handleError(error, ignoreError)));
  }

  private handleError(response: HttpErrorResponse, ignoreError: any) {

    if (ignoreError) return throwError(() => response);

    if (isPlatformBrowser(this.platformId) && !navigator.onLine) {
      this.toastrService.error(this.translateService.instant("COMMON.ERROR.NO_INTERNET"));
      return EMPTY;
    }

    let status = response.status;
    if (response.status === undefined) {
      status = 404;
    }

    switch (status) {
      case 400:
        return throwError(() => response);
      case 401:
        this.authService.logOut();
        return throwError(() => response);
      case 404:
        this.router.navigateByUrl('/not-found').then();
        return throwError(() => response);
      case 503:
        this.router.navigateByUrl('/maintenance').then();
        return throwError(() => response);
      case 403:
        this.router.navigateByUrl('/access-denied').then();
        return throwError(() => response);
      default:
        this.router.navigateByUrl('/oops').then();
        return throwError(() => response);
    }

  }

}
