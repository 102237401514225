import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Product, ProductService, ShopyanCartHelperService, StoreService,} from "@app/_shared";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {isPlatformServer} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanYouMayProductsComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();
  products: Product[] = [];
  loading: boolean;

  protected constructor(private productService: ProductService,
                        private basicHelperService: ShopyanCartHelperService,
                        private router: Router,
                        private transferState: TransferState,
                        private storeService: StoreService,
                        @Inject(PLATFORM_ID) protected platformId: any) {
    super();
  }

  /**
   * Filter products
   */
  filterRelatedProducts(): void {
    if (isPlatformServer(this.platformId) || this.buildMode) {
      this.loading = true;
      this.subscription.add(this.productService.findRelatedProducts(this.storeService.currentPath).subscribe((response: any) => {
        this.loading = false;
        if (response) {
          this.products = response;
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(makeStateKey(this.data.code + '-products'), this.products as any);
          } else if (this.products) {
            this.basicHelperService.initDiscounts(this.products);
          }
        }
      }));
    } else {
      this.products = this.transferState.get(makeStateKey(this.data.code + '-products'), null as any);
      if (this.products) {
        this.basicHelperService.initDiscounts(this.products);
      }
    }
  }

}
