import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {
  AuthService,
  CommonService,
  extractErrorMessagesFromResponse,
  Pageable, ProductService,
  Review,
  ShopyanToastrService,
  StorageService, StoreService
} from "@app/_shared";
import {Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DomSanitizer, makeStateKey, TransferState} from "@angular/platform-browser";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";
import {isPlatformServer} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanProductReviewsComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  reviews: Review[] = [];
  total: number;
  pageable: Pageable = new Pageable();
  showMore = false;

  reviewFG: FormGroup;
  reviewFormLoading = false;
  displayReviewForm = false;
  isAuthenticated = false;

  productId: string;

  loading: boolean;

  protected constructor(private translate: TranslateService,
                        private _sanitizer: DomSanitizer,
                        private activatedRoute: ActivatedRoute,
                        private http: HttpClient,
                        private formBuilder: FormBuilder,
                        private commonService: CommonService,
                        private productService: ProductService,
                        private storeService: StoreService,
                        private router: Router,
                        private basicToastrService: ShopyanToastrService,
                        private authService: AuthService,
                        private storageService: StorageService,
                        @Inject(PLATFORM_ID) protected platformId: any,
                        private transferState: TransferState) {
    super();
  }

  init(): void {
    this.initProduct();
    this.isAuthenticated = this.authService.isAuthenticated();
    let guest = null;
    if (this.isAuthenticated) {
      const user = this.authService.getConnectedUser();
      guest = user.firstName + ' ' + user.lastName || '';
    }

    this.reviewFG = this.formBuilder.group({
      guest: [guest, Validators.required],
      rating: [5, [Validators.min(1), Validators.max(5)]],
      comment: [null, Validators.required]
    });

  }

  initProduct(): void {
    if (!this.buildMode) {
      if (isPlatformServer(this.platformId)) {
        let slug = encodeURI(this.storeService.currentPath.split('/')[this.storeService.currentPath.split('/').length - 1]);
        if (this.storeService.currentPath.indexOf('/product/') >= 0) {
          this.initProductBySlug(slug);
        } else {
          this.initProductByLandingSlug(slug);
        }
      } else {
        this.productId = this.transferState.get(makeStateKey(this.data.code + '-product-id'), null as any);
        this.reviews = this.transferState.get(makeStateKey(this.data.code + '-reviews'), null as any);
        this.total = this.transferState.get(makeStateKey(this.data.code + '-total'), null as any);
        this.showMore = this.transferState.get(makeStateKey(this.data.code + '-showMore'), null as any);
      }
    } else {
      let landingPageSlug = this.storageService.getData('landing_page_slug');
      if (landingPageSlug) {
        this.initProductByLandingSlug(JSON.parse(landingPageSlug));
      }
    }
  }

  initProductBySlug(slug: String) {
    this.subscription.add(this.productService.getProductIdBySlug(slug).subscribe((response: any) => {
      this.productId = response;
      this.transferState.set(makeStateKey(this.data.code + '-product-id'), this.productId as any);
      this.filterReviews();
    }));
  }

  initProductByLandingSlug(slug: String) {
    this.subscription.add(this.storeService.getProductIdByLandingSlug(slug).subscribe((response: any) => {
      this.productId = response;
      this.transferState.set(makeStateKey(this.data.code + '-product-id'), this.productId as any);
      this.filterReviews();
    }));
  }

  /**
   * Filter reviews
   */
  filterReviews(isShowMore?: boolean): void {

    if (this.productId) {

      this.loading = true;

      if (isShowMore) {
        this.pageable.page++;
      }
      this.subscription.add(this.productService.findProductReviews(this.productId, this.pageable).subscribe((response: any) => {
        this.loading = false;
        if (response) {
          if (isShowMore) {
            this.reviews = [...this.reviews, ...response.data];
          } else {
            this.reviews = response.data;
          }
          this.total = response.total;
          this.showMore = response.data.length === this.pageable.size;

          if (isPlatformServer(this.platformId)) {
            this.transferState.set(makeStateKey(this.data.code + '-reviews'), this.reviews as any);
            this.transferState.set(makeStateKey(this.data.code + '-total'), this.total as any);
            this.transferState.set(makeStateKey(this.data.code + '-showMore'), this.showMore as any);
          }
        }
      }));
    }
  }

  submitReview(): void {
    this.reviewFG.markAllAsTouched();
    if (!this.reviewFG.valid) {
      this.basicToastrService.error(this.translate.instant("COMMON.MESSAGE.FILL_REQUIRED_FIELDS"));
      return;
    }

    let newReview: any = {};
    Object.assign(newReview, this.reviewFG.value);
    newReview.product = this.productId;
    this.reviewFormLoading = true;

    // no private api call it's a submit browser only
    const url = this.isAuthenticated ? `${environment.pubicProductApiUrl}/api/reviews` : `${environment.pubicProductApiUrl}/reviews`;

    this.subscription.add(this.commonService.create(url, newReview, true).subscribe({
      next: response => {
        this.basicToastrService.success(this.translate.instant("COMMON.MESSAGE.DONE"));
        this.reviewFormLoading = false;
        this.reviewFG.reset();
        this.displayReviewForm = false;
      }, error: err => {
        this.basicToastrService.error(extractErrorMessagesFromResponse(err));
      }
    }));
  }

}

