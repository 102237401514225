import {Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ComponentLookup, ShopyanCartHelperService} from '@app/_shared';
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'lib-neo-store-reviews',
  templateUrl: './neo-store-reviews.component.html',
  styleUrls: ['./neo-store-reviews.component.scss']
})

@ComponentLookup('neo-store-reviews')
export class NeoStoreReviewsComponent extends ShopyanSectionComponent implements OnInit, OnDestroy {

  subscription = new Subscription();

  slideConfig: any = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    autoplay: true,
    "dots": true
  };

  displayCarousel = true;

  constructor(private translate: TranslateService,
              private shopyanHelper: ShopyanCartHelperService,
              @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  ngOnInit(): void {
    this.initLTR();
    this.subscription.add(this.shopyanHelper.langSubject.subscribe((next: any) => {
      this.displayCarousel = false;
      this.initLTR();
      setTimeout(() => {
        this.displayCarousel = true;
      }, 300);
    }));

    if (isPlatformBrowser(this.platformId)) {
      this.onResize();
    }
  }

  initLTR(): void {
    this.slideConfig.rtl = this.translate.currentLang === 'ar';
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let total = 3;
    if (window.innerWidth < 500) {
      total = 1;
    } else if (window.innerWidth < 900) {
      total = 2;
    }
    if (this.slideConfig.slidesToShow !== total) {
      this.displayCarousel = false;
      this.slideConfig.slidesToShow = total;
      setTimeout(() => {
        this.displayCarousel = true;
      }, 300);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
