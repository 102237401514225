import {Component, OnInit} from '@angular/core';
import {ComponentLookup, Link, MediaSize, MediaType, urlMedia} from '@app/_shared';
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'lib-neo-image-banner',
  templateUrl: './neo-image-banner.component.html',
  styleUrls: ['./neo-image-banner.component.scss']
})
@ComponentLookup('neo-image-banner')
export class NeoImageBannerComponent extends ShopyanSectionComponent implements OnInit {


  constructor(private _sanitizer: DomSanitizer,
              private deviceDetectorService: DeviceDetectorService) {
    super();
  }

  ngOnInit(): void {
  }

  get mobile(): boolean{
    return this.deviceDetectorService.isMobile() || window.innerWidth < 600;
  }

  getLink(link: Link): any {
    let l = this.constructLink(link);
    return l ? this._sanitizer.bypassSecurityTrustResourceUrl(l) : undefined;
  }

  urlStoreMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.STORE, MediaSize.original, media);
  }

}
