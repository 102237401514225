import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {
  ComponentLookup,
  ProductService,
  ShopyanCartHelperService,
  ShopyanYouMayProductsComponent,
  StoreService
} from '@app/_shared';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {TransferState} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'lib-neo-you-may-products',
  templateUrl: './neo-you-may-products.component.html',
  styleUrls: ['./neo-you-may-products.component.scss']
})
@ComponentLookup('neo-you-may-products')
export class NeoYouMayProductsComponent extends ShopyanYouMayProductsComponent implements OnInit, OnDestroy {

  @ViewChild('productLayout', {static: false})
  productLayout: ElementRef;

  slideConfig: any = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "infinite": false
  };

  displayCarousel = true;
  productHeight: number;

  constructor(private translate: TranslateService,
              private shopyanHelper: ShopyanCartHelperService,
              productService: ProductService,
              router: Router,
              storeService: StoreService,
              helperService: ShopyanCartHelperService, transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any) {
    super(productService, helperService, router, transferState, storeService, platformId);
  }

  ngOnInit(): void {
    this.filterRelatedProducts();
    this.initLTR();
    this.subscription.add(this.shopyanHelper.langSubject.subscribe((next: any) => {
      this.displayCarousel = false;
      this.initLTR();
      setTimeout(() => {
        this.displayCarousel = true;
        this.initProductHeight();
      }, 300);
    }));

    if (isPlatformBrowser(this.platformId)) {
      this.onResize();
      this.initProductHeight();
    }
  }

  initLTR(): void {
    this.slideConfig.rtl = this.translate.currentLang === 'ar';
  }

  initProductHeight() {
    setTimeout(() => {
      this.productHeight = this.productLayout.nativeElement.offsetHeight || 0;
    }, 300);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let total = 4;
    if (window.innerWidth < 900) {
      total = 2;
    } else if (window.innerWidth < 1200) {
      total = 3;
    }


    if (this.slideConfig.slidesToShow !== total) {
      this.displayCarousel = false;
      this.slideConfig.slidesToShow = total;
      setTimeout(() => {
        this.displayCarousel = true;
        this.initProductHeight();
      }, 300);
    }
  }


  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
