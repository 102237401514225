import {Component, OnInit} from '@angular/core';
import {
  ShopyanCartHelperService,
  ShopyanProductCardComponent,
  ShopyanToastrService,
  StorageService
} from '@app/_shared';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lib-neo-product-card',
  templateUrl: './neo-product-card.component.html',
  styleUrls: ['./neo-product-card.component.scss']
})
export class NeoProductCardComponent extends ShopyanProductCardComponent implements OnInit {

  constructor(
    translate: TranslateService,
    public helperService: ShopyanCartHelperService,
    storageService: StorageService,
    router: Router,
    private toastrService: ShopyanToastrService) {
    super(translate, helperService, storageService, router, toastrService)
  }

  ngOnInit(): void {
    this.init();
  }


}
