import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Collection, CollectionService, MediaSize, MediaType, Pageable, urlMedia} from "@app/_shared";
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {isPlatformServer} from "@angular/common";
import {makeStateKey, TransferState} from "@angular/platform-browser";

@Component({
  template: ''
})
export abstract class ShopyanCollectionListComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  collections: Collection[] = [];

  pageable: Pageable = new Pageable();
  showMore = false;

  loading: boolean;

  protected constructor(private collectionService: CollectionService,
                        protected transferState: TransferState,
                        @Inject(PLATFORM_ID) protected platformId: any) {
    super();
  }


  /**
   * Find collections
   * @param isShowMore
   */
  findCollections(isShowMore?: boolean): void {
    if (isShowMore) {
      this.pageable.page++;
    }
    this.loading = true;
    this.subscription.add(this.collectionService.filterCollections(this.pageable).subscribe((response: any) => {
      this.loading = false;
      if (response) {
        if (isShowMore) {
          this.collections = [...this.collections, ...response.data];
        } else {
          this.collections = response.data;
        }
        this.showMore = response.data.length === this.pageable.size;

        if (isPlatformServer(this.platformId)) {
          this.transferState.set(makeStateKey(this.data.code + '-collections'), this.collections as any);
          this.transferState.set(makeStateKey(this.data.code + '-showMore'), this.showMore as any);
        }

      }
    }));
  }

  urlCollectionMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.COLLECTION, MediaSize.medium, media);
  }


}
