<div id="not-found" [dir]="direction">
  <div class="page-container">
    <div class="lp">
    </div>
    <div class="rp">
      <div class="oops">{{'ERROR_PAGE.NOT_FOUND.OOPS' | translate}}</div>
      <div class="error-ttl">{{'ERROR_PAGE.NOT_FOUND.TITLE' | translate}}</div>
      <div class="error-desc">
        {{'ERROR_PAGE.NOT_FOUND.DESC' | translate}}
      </div>
      <div class="actions">
        <a href="#" class="neo-btn">{{'COMMON.GO_BACK' | translate}}</a>
      </div>
    </div>
  </div>
</div>
