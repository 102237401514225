<div [id]="data.code" class="neo-product-list shopyan-section"
     [class.build-mode]="buildMode">
  <div class="neo-section-layout v-padding"
       [class.large]="getFieldsGroupValue('layout','layout-width') == 'large'">

    <lib-neo-section-header
      *ngIf="getFieldsGroup('header') && !getFieldsGroup('header').hidden"
      [data]="getFieldsGroup('header')"></lib-neo-section-header>

    <div class="result-header">
      <div class="rh-form">
        <input type="text" class="neo-input" placeholder="{{ 'PRODUCT_LIST.SEARCH_PLACEHOLDER' | translate }}"
               [(ngModel)]="filter"/>
        <div class="neo-btn" (click)="filterProducts()">{{ 'PRODUCT_LIST.SEARCH' | translate }}</div>
      </div>
      <div class="result-filter">
        <div class="collections-select">
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="collectionCode" (selectionChange)="filterByCollection()">
              <mat-option [value]="''">{{ 'PRODUCT_LIST.ALL_COLLECTIONS' | translate }}</mat-option>
              <ng-container *ngFor="let collection of collections">
                <mat-option [value]="collection.id">{{collection.name}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="select-area sort-area">
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="sort" (selectionChange)="changeSort()">
              <mat-option [value]="''">{{ 'PRODUCT_LIST.SORT.DEFAULT' | translate }}</mat-option>
              <mat-option [value]="'price,asc'">{{ 'PRODUCT_LIST.SORT.PRICE.ASC' | translate }}</mat-option>
              <mat-option [value]="'price,desc'">{{ 'PRODUCT_LIST.SORT.PRICE.DESC' | translate }}</mat-option>
              <mat-option [value]="'createdDate,desc'">{{ 'PRODUCT_LIST.SORT.DATE.DESC' | translate }}</mat-option>
              <mat-option [value]="'createdDate,asc'">{{ 'PRODUCT_LIST.SORT.DATE.ASC' | translate }}</mat-option>
              <mat-option [value]="'name,asc'">{{ 'PRODUCT_LIST.SORT.NAME.ASC' | translate }}</mat-option>
              <mat-option [value]="'name,desc'">{{ 'PRODUCT_LIST.SORT.NAME.DESC' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="result-area" #layout>
      <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
      <ng-container *ngIf="products && products.length > 0">
        <div class="products"
             [style.grid-template-columns]="'repeat('+productsPerRow+', minmax(0, 1fr))'">
          <lib-neo-product-card
            *ngFor="let item of products;"
            [storeId]="storeID"
            [product]="item"
            [fields]="getFieldsGroup('layout').values || []"
          ></lib-neo-product-card>
        </div>
        <div class="show-more" *ngIf="showMore">
          <div class="neo-btn" (click)="filterProducts(true)">
            {{ 'PRODUCT_LIST.SHOW_MORE' | translate }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading && (!products || products.length == 0)">
        <lib-neo-no-result></lib-neo-no-result>
      </ng-container>
    </div>

  </div>
</div>
