import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {ComponentLookup, getNeoIndicatorPerRow} from "@app/_shared";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TransferState} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-neo-indicator',
  templateUrl: './neo-indicator.component.html',
  styleUrls: ['./neo-indicator.component.scss']
})
@ComponentLookup('neo-indicator')
export class NeoIndicatorComponent extends ShopyanSectionComponent implements OnInit, AfterViewInit {

  itemsPerRow: number = 1;

  @ViewChild("layout")
  layout: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.onResize();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let total = this.getFieldsGroupValue('layout', 'columns-per-row') || 3;
    this.itemsPerRow = getNeoIndicatorPerRow(this.layout.nativeElement.offsetWidth, total);
  }

}
