<div [id]="data.code" class="neo-footer shopyan-section" [class.build-mode]="buildMode"
     [style.background]="getFieldsGroupValue('footer-layout','background')">
  <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
  <div class="neo-section-layout footer-wrapper">
    <div class="footer-body"
         [style.grid-template-columns]="'repeat('+footerItemPerRow+', 1fr)'">

      <ng-container *ngIf="staticMenus && (!menus || menus.length == 0)">
        <div class="footer-item footer-desc" *ngIf="getBlock('footer-desc')">
          <div class="footer-logo"
               [style.justify-content]="getBlockFieldValue('footer-desc', 'justify-content')">
            <div class="logo-container"
                 [class.default-logo]="!getBlockFieldValue('footer-desc', 'image-url')"
                 [style.height]="getBlockFieldValue('footer-desc', 'image-height')+'px'"
                 [style.width]="getBlockFieldValue('footer-desc', 'image-width')+'px'">
              <img alt="store"  fill="true" alt="store" priority="true"
                   [src]="urlStoreMedia(getBlockFieldValue('footer-desc', 'image-url'))"
                   *ngIf="getBlockFieldValue('footer-desc', 'image-url')"
                   [style.object-fit]="getBlockFieldValue('footer-desc', 'object-fit')"/>
            </div>
          </div>
          <div class="about-us" [style.color]="getFieldsGroupValue('footer-layout','color')"
               *ngIf="getBlockFieldValue('footer-desc', 'about-us')">
            {{getBlockFieldValue('footer-desc', 'about-us')}}
          </div>
          <div class="social-networks">
            <a
              *ngIf="getBlockFieldValue('footer-desc', 'facebook')"
              [href]="getBlockFieldValue('footer-desc', 'facebook')"
              target="_blank" class="facebook"><span>Facebook</span></a>
            <a
              *ngIf="getBlockFieldValue('footer-desc', 'instagram')"
              [href]="getBlockFieldValue('footer-desc', 'instagram')"
              target="_blank" class="instagram"><span>Instagram</span></a>
            <a
              *ngIf="getBlockFieldValue('footer-desc', 'pinterest')"
              [href]="getBlockFieldValue('footer-desc', 'pinterest')"
              target="_blank" class="pinterest"><span>Pinterest</span></a>
            <a
              *ngIf="getBlockFieldValue('footer-desc', 'snapchat')"
              [href]="getBlockFieldValue('footer-desc', 'snapchat')"
              target="_blank" class="snapchat"><span>Snapchat</span></a>
            <a
              *ngIf="getBlockFieldValue('footer-desc', 'youtube')"
              [href]="getBlockFieldValue('footer-desc', 'youtube')"
              target="_blank" class="youtube"><span>Youtube</span></a>
            <a
              *ngIf="getBlockFieldValue('footer-desc', 'tiktok')"
              [href]="getBlockFieldValue('footer-desc', 'tiktok')"
              target="_blank" class="tiktok"><span>Tiktok</span></a>
            <a
              *ngIf="getBlockFieldValue('footer-desc', 'twitter')"
              [href]="getBlockFieldValue('footer-desc', 'twitter')"
              target="_blank" class="twitter"><span>X</span></a>
            <a
              *ngIf="getBlockFieldValue('footer-desc', 'link')"
              [href]="getBlockFieldValue('footer-desc', 'link')"
              target="_blank" class="link"><span>Link</span></a>
          </div>
        </div>
        <div class="footer-item footer-menu" *ngFor="let menu of staticMenus">
          <div class="fi-container">
            <div class="fi-title" [style.color]="getFieldsGroupValue('footer-layout','color')">{{menu.name}}</div>
            <div class="fi-links">
              <div class="link-item" *ngFor="let menuItem of menu.menuItems">
                <a [style.color]="getFieldsGroupValue('footer-layout','color')"
                   [href]="getLink(menuItem)">{{menuItem.label}}</a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="menus && menus.length > 0">
        <ng-container *ngFor="let block of data.blocks; let index = index;">
          <ng-container *ngIf="!block.hidden">
            <ng-container [ngSwitch]="block.blockCode">

              <ng-container *ngSwitchCase="'footer-desc'">
                <div class="footer-item footer-desc">
                  <div class="footer-logo"
                       [style.justify-content]="getBlockFieldValueByIndex(index, 'justify-content')">
                    <div class="logo-container"
                         [class.default-logo]="!getBlockFieldValueByIndex(index, 'image-url')"
                         [style.height]="getBlockFieldValueByIndex(index, 'image-height')+'px'"
                         [style.width]="getBlockFieldValueByIndex(index, 'image-width')+'px'">
                      <img fill="true" alt="store" priority="true"
                           [src]="urlStoreMedia(getBlockFieldValueByIndex(index, 'image-url'))"
                           *ngIf="getBlockFieldValueByIndex(index, 'image-url')"
                           [style.object-fit]="getBlockFieldValueByIndex(index, 'object-fit')"/>
                    </div>
                  </div>
                  <div class="about-us" [style.color]="getFieldsGroupValue('footer-layout','color')"
                       *ngIf="getBlockFieldValueByIndex(index, 'about-us')">
                    {{getBlockFieldValueByIndex(index, 'about-us')}}
                  </div>
                  <div class="social-networks">
                    <a
                      *ngIf="getBlockFieldValueByIndex(index, 'facebook')"
                      [href]="getBlockFieldValueByIndex(index, 'facebook')"
                      target="_blank" class="facebook"><span>Facebook</span></a>
                    <a
                      *ngIf="getBlockFieldValueByIndex(index, 'instagram')"
                      [href]="getBlockFieldValueByIndex(index, 'instagram')"
                      target="_blank" class="instagram"><span>Instagram</span></a>
                    <a
                      *ngIf="getBlockFieldValueByIndex(index, 'pinterest')"
                      [href]="getBlockFieldValueByIndex(index, 'pinterest')"
                      target="_blank" class="pinterest"><span>Pinterest</span></a>
                    <a
                      *ngIf="getBlockFieldValueByIndex(index, 'snapchat')"
                      [href]="getBlockFieldValueByIndex(index, 'snapchat')"
                      target="_blank" class="snapchat"><span>Snapchat</span></a>
                    <a
                      *ngIf="getBlockFieldValueByIndex(index, 'youtube')"
                      [href]="getBlockFieldValueByIndex(index, 'youtube')"
                      target="_blank" class="youtube"><span>Youtube</span></a>
                    <a
                      *ngIf="getBlockFieldValueByIndex(index, 'tiktok')"
                      [href]="getBlockFieldValueByIndex(index, 'tiktok')"
                      target="_blank" class="tiktok"><span>Tiktok</span></a>
                    <a
                      *ngIf="getBlockFieldValueByIndex(index, 'twitter')"
                      [href]="getBlockFieldValueByIndex(index, 'twitter')"
                      target="_blank" class="twitter"><span>X</span></a>
                    <a
                      *ngIf="getBlockFieldValueByIndex(index, 'link')"
                      [href]="getBlockFieldValueByIndex(index, 'link')"
                      target="_blank" class="link"><span>Link</span></a>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'footer-menu'">
                <ng-container *ngFor="let menu of menus">
                  <ng-container *ngIf="menu.id === getBlockFieldValueByIndex(index, 'menu-code')">
                    <div class="footer-item footer-menu">
                      <div class="fi-container">
                        <div class="fi-title"
                             [style.color]="getFieldsGroupValue('footer-layout','color')">{{menu.name}}</div>
                        <div class="fi-links">
                          <div class="link-item" *ngFor="let menuItem of menu.menuItems">
                            <a [style.color]="getFieldsGroupValue('footer-layout','color')"
                               [href]="getLink(menuItem)">{{menuItem.label}}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

    </div>
  </div>
  <div class="copyright"
       [style.background-color]="getFieldsGroupValue('footer-copyright', 'background-color')"
       *ngIf="getFieldsGroup('footer-copyright') && !getFieldsGroup('footer-copyright').hidden">
    <div class="neo-section-layout copyright-content">
      <div class="txt"
      [style.color]="getFieldsGroupValue('footer-copyright', 'color')"> {{getFieldsGroupValue('footer-copyright', 'copyright')}}</div>
      <div class="coordinates">
        <div class="c-item tel" *ngIf="getFieldsGroupValue('footer-copyright', 'phone')">
          <svg
            [style.color]="getFieldsGroupValue('footer-copyright', 'icon-color')"
            xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
            <path
              d="M23.407 30.394c-2.431 0-8.341-3.109-13.303-9.783-4.641-6.242-6.898-10.751-6.898-13.785 0-2.389 1.65-3.529 2.536-4.142l0.219-0.153c0.979-0.7 2.502-0.927 3.086-0.927 1.024 0 1.455 0.599 1.716 1.121 0.222 0.442 2.061 4.39 2.247 4.881 0.286 0.755 0.192 1.855-0.692 2.488l-0.155 0.108c-0.439 0.304-1.255 0.869-1.368 1.557-0.055 0.334 0.057 0.684 0.342 1.068 1.423 1.918 5.968 7.55 6.787 8.314 0.642 0.6 1.455 0.685 2.009 0.218 0.573-0.483 0.828-0.768 0.83-0.772l0.059-0.057c0.048-0.041 0.496-0.396 1.228-0.396 0.528 0 1.065 0.182 1.596 0.541 1.378 0.931 4.487 3.011 4.487 3.011l0.050 0.038c0.398 0.341 0.973 1.323 0.302 2.601-0.695 1.327-2.85 4.066-5.079 4.066zM9.046 2.672c-0.505 0-1.746 0.213-2.466 0.728l-0.232 0.162c-0.827 0.572-2.076 1.435-2.076 3.265 0 2.797 2.188 7.098 6.687 13.149 4.914 6.609 10.532 9.353 12.447 9.353 1.629 0 3.497-2.276 4.135-3.494 0.392-0.748 0.071-1.17-0.040-1.284-0.36-0.241-3.164-2.117-4.453-2.988-0.351-0.238-0.688-0.358-0.999-0.358-0.283 0-0.469 0.1-0.532 0.14-0.104 0.111-0.39 0.405-0.899 0.833-0.951 0.801-2.398 0.704-3.424-0.254-0.923-0.862-5.585-6.666-6.916-8.459-0.46-0.62-0.641-1.252-0.538-1.877 0.187-1.133 1.245-1.866 1.813-2.26l0.142-0.099c0.508-0.363 0.4-1.020 0.316-1.242-0.157-0.414-1.973-4.322-2.203-4.781-0.188-0.376-0.336-0.533-0.764-0.533z"
              fill="currentColor"></path>
          </svg>
          <a href="tel:{{getFieldsGroupValue('footer-copyright', 'phone')}}">
            <span [style.color]="getFieldsGroupValue('footer-copyright', 'color')">{{getFieldsGroupValue('footer-copyright', 'phone')}}</span>
          </a>
        </div>
        <div class="c-item mail" *ngIf="getFieldsGroupValue('footer-copyright', 'mail')">
          <svg xmlns="http://www.w3.org/2000/svg"
               [style.color]="getFieldsGroupValue('footer-copyright', 'icon-color')"
               viewBox="0 0 32 32" height="16" width="16">
            <path
              d="M28.244 7.47h-25.572v17.060h26.656v-17.060h-1.084zM27.177 8.536l-10.298 10.298c-0.47 0.47-1.289 0.47-1.759 0l-10.3-10.298h22.356zM3.738 8.961l6.923 6.922-6.923 6.923v-13.846zM4.589 23.464l6.827-6.826 2.951 2.95c0.436 0.436 1.016 0.677 1.633 0.677s1.197-0.241 1.633-0.677l2.951-2.951 6.826 6.826h-22.822zM28.262 22.807l-6.923-6.924 6.923-6.924v13.848z"
              fill="currentColor"></path>
          </svg>
          <a href="mailto:{{getFieldsGroupValue('footer-copyright', 'mail')}}">
            <span [style.color]="getFieldsGroupValue('footer-copyright', 'color')">{{getFieldsGroupValue('footer-copyright', 'mail')}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
