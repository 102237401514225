import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {isPlatformServer} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  storeId: string;
  currentPath: string;

  constructor(@Inject(PLATFORM_ID) private platformId: any, private http: HttpClient) {
  }


  getPageData(originHostname: string, path: string, withMeta: boolean): Observable<any> {
    let url = `${this.getStoreUrl()}/graphs`;
    let params = new HttpParams();
    params = params.append('origin', originHostname || window.location.hostname);
    params = params.append('path', path);
    params = params.append('withMeta', withMeta);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
      params: params
    };
    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }


  getBlockedPageData(originHostname: string): Observable<any> {
    let url = `${this.getStoreUrl()}/graphs/blocked-content`;
    let params = new HttpParams();
    params = params.append('origin', originHostname || window.location.hostname);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getProductIdByLandingSlug(slug: String) {
    let url = `${this.getStoreUrl()}/landing-pages/get-product-id/${slug}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };
    return this.http.get(url, httpOptions);
  }

  getCheckoutFields() {
    let url = `${this.getStoreUrl()}/express-checkout`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  /**
   * Handler request error
   * @param error
   */
  public handleError(error: any) {
    return throwError(error);
  }

  private getStoreUrl() {
    return isPlatformServer(this.platformId) ? environment.privateStoreApiUrl : environment.pubicStoreApiUrl;
  }

}
