import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {
  ComponentLookup,
  getNeoProductsPerRow,
  ProductService,
  ShopyanCartHelperService,
  ShopyanMyProductsComponent
} from '@app/_shared';
import {TransferState} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'lib-neo-my-products',
  templateUrl: './neo-my-products.component.html',
  styleUrls: ['./neo-my-products.component.scss']
})
@ComponentLookup('neo-my-products')
export class NeoMyProductsComponent extends ShopyanMyProductsComponent implements OnInit, OnDestroy, AfterViewInit {

  productsPerRow: number = 2;

  @ViewChild("layout")
  layout: ElementRef;

  constructor(productService: ProductService,
              shopyanCartHelperService: ShopyanCartHelperService,
              transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any) {
    super(productService, shopyanCartHelperService, transferState, platformId);
  }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.onResize();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let total = this.getFieldsGroupValue('products-layout', 'products-per-row') || 4;
    this.productsPerRow = getNeoProductsPerRow(this.layout.nativeElement.offsetWidth, total);
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
