import {Component, OnInit} from '@angular/core';
import {ComponentLookup, Link, MediaSize, MediaType, urlMedia} from '@app/_shared';
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  selector: 'lib-neo-image-text',
  templateUrl: './neo-image-text.component.html',
  styleUrls: ['./neo-image-text.component.scss']
})
@ComponentLookup('neo-image-text')
export class NeoImageTextComponent extends ShopyanSectionComponent implements OnInit {

  constructor(private _sanitizer: DomSanitizer,
              private http: HttpClient) {
    super();
  }

  ngOnInit(): void {
  }

  getLink(link: Link): any {
    let l = this.constructLink(link);
    return l ? this._sanitizer.bypassSecurityTrustResourceUrl(l) : undefined;
  }

  urlStoreMedia(media: string): string {
    if (media) {
      return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.STORE, MediaSize.original, media);
    }
    return "";
  }

}
