import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-neo-no-result',
  templateUrl: './neo-no-result.component.html',
  styleUrls: ['./neo-no-result.component.scss']
})
export class NeoNoResultComponent implements OnInit {
  show = false;

  @Input('text')
  text: string;

  constructor() {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.show = true;
    }, 200);
  }
}
