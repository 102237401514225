<div *ngIf="customer" [class.build-mode]="buildMode"
     [id]="data.code"
     class="neo-account shopyan-section">

  <div class="account-menu">
    <div class="welcome">{{'ACCOUNT.WELCOME' | translate}} {{customer.firstName}}</div>
    <div class="menu-items">
      <div (click)="currentTab = 1" [class.current]="currentTab == 1"
           class="menu-item">{{'ACCOUNT.MY_ACCOUNT' | translate}}</div>
      <div (click)="currentTab = 2" [class.current]="currentTab == 2"
           class="menu-item">{{'ACCOUNT.MY_ORDERS' | translate}}</div>
      <div (click)="logout()" class="menu-item">{{'ACCOUNT.LOG_OUT' | translate}}</div>
    </div>
  </div>

  <div class="account-form-area">

    <div class="form-container">
      <div class="mobile-welcome">{{'ACCOUNT.WELCOME' | translate}} {{customer.firstName}}</div>
      <div class="mobile-account-menu">
        <div (click)="currentTab = 1" [class.current]="currentTab == 1"
             class="mam-item">{{'ACCOUNT.MY_ACCOUNT' | translate}}</div>
        <div (click)="currentTab = 2" [class.current]="currentTab == 2"
             class="mam-item">{{'ACCOUNT.MY_ORDERS' | translate}}</div>
      </div>
      <div *ngIf="currentTab == 1" class="animated tdFadeInUp">
        <div class="form-ttl">{{'ACCOUNT.MY_ACCOUNT' | translate}}</div>
        <lib-neo-account-form></lib-neo-account-form>
      </div>

      <div *ngIf="currentTab == 2" class="animated tdFadeInUp">
        <div class="form-ttl">{{'ACCOUNT.MY_ORDERS' | translate}}</div>
        <lib-neo-orders [storeId]="storeID"></lib-neo-orders>
      </div>
    </div>
  </div>

</div>

<div *ngIf="buildMode" id="build-mode">
  <div class="ic"></div>
  <span class="big-ttl">{{'ACCOUNT.BUILD_MODE_TITLE' | translate}}</span>
  <span>{{'ACCOUNT.BUILD_MODE_DESC' | translate}}</span>
</div>


