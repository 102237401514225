<div [id]="data.code" class="neo-banner shopyan-section" [class.build-mode]="buildMode">

  <div class="neo-section-layout large"
       [style.margin-left]="getFieldsGroupValue('img-banner-layout','margin-left')+'px'"
       [style.margin-right]="getFieldsGroupValue('img-banner-layout','margin-right')+'px'">

    <div class="banner-container"
         [class.default-bg]="(!mobile && !getFieldsGroupValue('img-banner-layout','background-image'))
         || (mobile && !getFieldsGroupValue('img-banner-layout','mobile-background-image')
              && !getFieldsGroupValue('img-banner-layout','background-image'))"
         [style.height]="getFieldsGroupValue('img-banner-layout','banner-height')+'rem'"
         [style.border-radius]="getFieldsGroupValue('img-banner-layout','border-radius')+'px'"
         [style.background-position]="getFieldsGroupValue('img-banner-layout','background-position')">

      <img alt="store" *ngIf="getFieldsGroupValue('img-banner-layout','background-image') &&
                    (!mobile || !getFieldsGroupValue('img-banner-layout','mobile-background-image'))"
           [src]="urlStoreMedia(getFieldsGroupValue('img-banner-layout','background-image'))"
           [style.object-fit]="getFieldsGroupValue('img-banner-layout','img-fit')"/>

      <img alt="store" *ngIf="mobile && getFieldsGroupValue('img-banner-layout','mobile-background-image')"
           [src]="urlStoreMedia(getFieldsGroupValue('img-banner-layout','mobile-background-image'))"
           [style.object-fit]="getFieldsGroupValue('img-banner-layout','mobile-img-fit')"/>

      <div class="banner-opacity"
           [style.opacity]="getFieldsGroupValue('img-banner-layout','opacity')+'%'"
      ></div>

      <a class="bc"
         *ngIf="getLink(getFieldsGroupValue('img-banner-layout','url')); else noLink"
         [href]="getLink(getFieldsGroupValue('img-banner-layout','url'))"
         [style.justify-content]="getFieldsGroupValue('img-banner-layout','justify-content')"
         [style.align-items]="getFieldsGroupValue('img-banner-layout','align-items')">

        <div class="banner-content animated tdShrinkIn"
             (click)="$event.stopPropagation()"
             *ngIf="getFieldsGroupValue('img-banner-layout','display-content')"
             [style.text-align]="getFieldsGroupValue('img-banner-layout','text-align')"
             [style.width]="getFieldsGroupValue('img-banner-layout','content-width')+'px'"
             [style.background-color]="getFieldsGroupValue('img-banner-layout','content-background-color')"
             [class.transparent]="getFieldsGroupValue('img-banner-layout','content-background-transparent') == true">

          <div class="b-title" *ngIf="getBlock('banner-header-block') && !getBlock('banner-header-block')?.hidden"
               [style.font-size]="getBlockFieldValue('banner-header-block', 'font-size')+'px'"
               [style.color]="getBlockFieldValue('banner-header-block', 'font-color')"
          >
          <span
            *ngIf="getBlockFieldValue('banner-header-block', 'header-content')">{{getBlockFieldValue('banner-header-block', 'header-content')}}</span>
          </div>

          <div class="b-sub-title" *ngIf="getBlock('banner-desc-block') && !getBlock('banner-desc-block')?.hidden"
               [style.margin-top]="getBlockFieldValue('banner-desc-block', 'margin-top')+'px'">
            <p
              [style.font-size]="getBlockFieldValue('banner-desc-block', 'font-size')+'px'"
              [style.color]="getBlockFieldValue('banner-desc-block', 'font-color')"
            >
              <ng-container *ngIf="getBlockFieldValue('banner-desc-block', 'desc-content')">
                {{getBlockFieldValue('banner-desc-block', 'desc-content')}}
              </ng-container>
            </p>
          </div>

          <a [href]="getLink(getBlockFieldValue('banner-btn-block', 'link'))" class="neo-btn"
             *ngIf="getBlock('banner-btn-block') && !getBlock('banner-btn-block')?.hidden"
             [style.margin-top]="getBlockFieldValue('banner-btn-block', 'margin-top')+'px'"
          >
          <span
            *ngIf="getBlockFieldValue('banner-btn-block', 'content')">{{getBlockFieldValue('banner-btn-block', 'content')}}</span>
          </a>

        </div>
      </a>

      <ng-template #noLink>
        <div class="bc"
             [style.justify-content]="getFieldsGroupValue('img-banner-layout','justify-content')"
             [style.align-items]="getFieldsGroupValue('img-banner-layout','align-items')">

          <div class="banner-content animated tdShrinkIn"
               (click)="$event.stopPropagation()"
               *ngIf="getFieldsGroupValue('img-banner-layout','display-content')"
               [style.text-align]="getFieldsGroupValue('img-banner-layout','text-align')"
               [style.width]="getFieldsGroupValue('img-banner-layout','content-width')+'px'"
               [style.background-color]="getFieldsGroupValue('img-banner-layout','content-background-color')"
               [class.transparent]="getFieldsGroupValue('img-banner-layout','content-background-transparent') == true">

            <div class="b-title" *ngIf="getBlock('banner-header-block') && !getBlock('banner-header-block')?.hidden"
                 [style.font-size]="getBlockFieldValue('banner-header-block', 'font-size')+'px'"
                 [style.color]="getBlockFieldValue('banner-header-block', 'font-color')"
            >
          <span
            *ngIf="getBlockFieldValue('banner-header-block', 'header-content')">{{getBlockFieldValue('banner-header-block', 'header-content')}}</span>
            </div>

            <div class="b-sub-title" *ngIf="getBlock('banner-desc-block') && !getBlock('banner-desc-block')?.hidden"
                 [style.margin-top]="getBlockFieldValue('banner-desc-block', 'margin-top')+'px'">
              <p
                [style.font-size]="getBlockFieldValue('banner-desc-block', 'font-size')+'px'"
                [style.color]="getBlockFieldValue('banner-desc-block', 'font-color')"
              >
                <ng-container *ngIf="getBlockFieldValue('banner-desc-block', 'desc-content')">
                  {{getBlockFieldValue('banner-desc-block', 'desc-content')}}
                </ng-container>
              </p>
            </div>

            <a [href]="getLink(getBlockFieldValue('banner-btn-block', 'link'))" class="neo-btn"
               *ngIf="getBlock('banner-btn-block') && !getBlock('banner-btn-block')?.hidden"
               [style.margin-top]="getBlockFieldValue('banner-btn-block', 'margin-top')+'px'"
            >
          <span
            *ngIf="getBlockFieldValue('banner-btn-block', 'content')">{{getBlockFieldValue('banner-btn-block', 'content')}}</span>
            </a>

          </div>
        </div>
      </ng-template>

    </div>

  </div>

</div>
