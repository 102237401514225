import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {isPlatformServer} from "@angular/common";

@Injectable({providedIn: 'root'})
export class AppsService {

  constructor(@Inject(PLATFORM_ID) private platformId: any, private http: HttpClient) {
  }

  /**
   * Get count down data
   */
  public getCountDownData(): Observable<any> {
    let url = `${this.getStoreUrl()}/applications/application-data/COUNTDOWN`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Handler request error
   * @param error
   */
  public handleError(error: any) {
    return throwError(error);
  }

  private getStoreUrl() {
    return isPlatformServer(this.platformId) ? environment.privateStoreApiUrl : environment.pubicStoreApiUrl;
  }

}
