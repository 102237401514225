import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {
  AuthService,
  CheckoutFieldSubType,
  CheckoutFieldType,
  ComponentLookup,
  ShopyanCartHelperService,
  ShopyanCheckoutComponent,
  ShopyanToastrService,
  StorageService,
  UpsellPage,
  UpsellService
} from '@app/_shared';
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lib-neo-checkout',
  templateUrl: './neo-checkout.component.html',
  styleUrls: ['./neo-checkout.component.scss']
})
@ComponentLookup('neo-checkout')
export class NeoCheckoutComponent extends ShopyanCheckoutComponent implements OnInit, OnDestroy {


  constructor(translate: TranslateService,
              formBuilder: FormBuilder,
              router: Router,
              storageService: StorageService,
              basicToastr: ShopyanToastrService,
              authService: AuthService,
              basicHelperService: ShopyanCartHelperService,
              http: HttpClient,
              private upsellService: UpsellService,
              @Inject(PLATFORM_ID) platformId: any) {
    super(translate, formBuilder, router, storageService, basicToastr, authService, basicHelperService, http, platformId);
  }

  ngOnInit(): void {
    this.init();
  }

  checkUpsells(): void {
    this.upsellService.checkUpSells(UpsellPage.CHECKOUT, this.storeID);
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  protected readonly CheckoutFieldSubType = CheckoutFieldSubType;
  protected readonly CheckoutFieldType = CheckoutFieldType;
}
