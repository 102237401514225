<div class="neo-modal yan-modal">
  <div class="yan-modal-title">{{'COMMON.CONFIRMATION_TITLE' | translate}}</div>
  <div class="yan-modal-content">
    <div class="confirmation-message">{{message}}</div>
  </div>
  <div class="yan-modal-btns">
    <button [mat-dialog-close]="true" class="neo-btn submit">{{'COMMON.CONFIRM' | translate}}</button>
    <button [mat-dialog-close]="false" class="neo-btn secondary">{{'COMMON.CANCEL' | translate}}</button>
  </div>
</div>

