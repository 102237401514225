import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {ComponentLookup, getNeoColumnPerRow, Link, MediaSize, MediaType, urlMedia} from '@app/_shared';
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'lib-neo-multi-columns',
  templateUrl: './neo-multi-columns.component.html',
  styleUrls: ['./neo-multi-columns.component.scss']
})
@ComponentLookup('neo-multi-columns')
export class NeoMultiColumnsComponent extends ShopyanSectionComponent implements OnInit, AfterViewInit {

  itemsPerRow: number = 1;

  @ViewChild("layout")
  layout: ElementRef;

  constructor(private _sanitizer: DomSanitizer,
              @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.onResize();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let total = this.getFieldsGroupValue('layout', 'columns-per-row') || 3;
    this.itemsPerRow = getNeoColumnPerRow(this.layout.nativeElement.offsetWidth, total);
  }

  getLink(link: Link): any {
    let l = this.constructLink(link);
    return l ? this._sanitizer.bypassSecurityTrustResourceUrl(l) : undefined;
  }

  urlStoreMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.STORE, MediaSize.original, media);
  }
}
