export class CheckoutField {
  id: string;
  type: CheckoutFieldType;
  subType: CheckoutFieldSubType
  label: string;
  options: string[];
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
}

export class CheckoutFieldValue {
  id: string;
  value: string;
}

export enum CheckoutFieldType {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  FULL_NAME = 'FULL_NAME',
  MAIL = 'MAIL',
  PHONE = 'PHONE',
  ADDRESS_1 = 'ADDRESS_1',
  ADDRESS_2 = 'ADDRESS_2',
  CITY = 'CITY',
  ZIP_CODE = 'ZIP_CODE',
  COUNTRY = 'COUNTRY',
  PROVINCE = 'PROVINCE',
  COMMENT = 'COMMENT',
  CUSTOM_FIELD = 'CUSTOM_FIELD'
}

export enum CheckoutFieldSubType {
  INPUT = 'INPUT',
  TEXTAREA = 'TEXTAREA',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  HIDDEN = 'HIDDEN'
}
