<div class="indicator-item">
  <div class="ic" *ngIf="getFieldValue( 'image')"
       [style.width]="getLayoutFieldValue('image-width')+'px'"
       [style.height]="getLayoutFieldValue('image-height')+'px'"
       [style.margin-bottom]="getLayoutFieldValue('image-margin-bottom')+'px'"
       [style.border-radius]="getLayoutFieldValue('image-border-radius')+'px'">
    <img alt="store"  [src]="urlStoreMedia(getFieldValue('image'))"
         [style.object-fit]="getFieldValue('img-fit')"/>
  </div>
  <div class="value animated tdFadeInUp"
       [class.bold]="getLayoutFieldValue('indicator-bold')"
       [style.font-size]="getLayoutFieldValue('indicator-font-size')+'px'"
       [style.color]="getLayoutFieldValue('indicator-color')">
    {{counter}}
  </div>
  <div class="label"
       [style.font-size]="getLayoutFieldValue('label-font-size')+'px'"
       [style.margin-top]="getLayoutFieldValue('label-margin-top')+'px'"
       [style.color]="getLayoutFieldValue('label-color')">
    {{getFieldValue("label")}}
  </div>
</div>
