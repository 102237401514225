<div class="count-down-timer countdown-template " *ngIf="countDown && (timer > 0 || !countDown.hideWhenEnds)"
     [ngClass]="countDown.template">
  <div class="titles" *ngIf="countDown.title || countDown.subTitle">
    <div class="big-t" *ngIf="countDown.title">{{countDown.title}}</div>
    <div class="sub-t" *ngIf="countDown.subTitle">{{countDown.subTitle}}</div>
  </div>

  <div class="count-down-content">
    <div class="timer-item">
      <p>{{days}}</p>
      <span>{{'COUNTDOWN.DAYS' | translate}}</span>
    </div>
    <div class="sep">:</div>
    <div class="timer-item">
      <p>{{hours}}</p>
      <span>{{'COUNTDOWN.HOURS' | translate}}</span>
    </div>
    <div class="sep">:</div>
    <div class="timer-item">
      <p>{{minutes}}</p>
      <span>{{'COUNTDOWN.MINUTES' | translate}}</span>
    </div>
    <div class="sep">:</div>
    <div class="timer-item">
      <p>{{seconds}}</p>
      <span>{{'COUNTDOWN.SECONDS' | translate}}</span>
    </div>
  </div>

  <div class="watching" *ngIf="totalPeople">
    <div class="total-watching">{{totalPeople}}</div>
    <div class="total-watching-msg"
         *ngIf="countDown.visitorsMessage">{{countDown.visitorsMessage}}</div>
  </div>
</div>
