<div [id]="data.code" class="neo-indicator shopyan-section animated tdFadeInUp"
     [class.build-mode]="buildMode"
     [style.background-color]="getFieldsGroupValue('layout','background-color')">

  <div class="neo-section-layout large" #layout>
    <div class="indicators-container" [style.grid-template-columns]="'repeat('+itemsPerRow+', 1fr)'">
      <ng-container *ngFor="let block of data.blocks; let index = index;">
      <div class="indicator-item-container" *ngIf="!block.hidden">
         <app-neo-indicator-item
           [storeId]="storeID"
           [fields]="block.values || []"
           [layoutFields]="getFieldsGroup('layout')?.values || []">
         </app-neo-indicator-item>
      </div>
      </ng-container>
    </div>

  </div>

</div>
