<div [id]="data.code" class="neo-image-text shopyan-section" [class.build-mode]="buildMode">

  <div class="neo-section-layout large">

    <div class="image-text-content"
         [style.flex-direction]="getFieldsGroupValue('neo-image-text-layout','direction')"
         [style.background-color]="getFieldsGroupValue('neo-image-text-layout','background-color')"
         [style.margin-right]="getFieldsGroupValue('neo-image-text-layout','margin-right')+'px'"
         [style.margin-left]="getFieldsGroupValue('neo-image-text-layout','margin-left')+'px'"
         [style.border-radius]="getFieldsGroupValue('neo-image-text-layout','border-radius')+'px'">

      <img alt="store"  class="bg-img"
           *ngIf="getFieldsGroupValue('neo-image-text-layout', 'image')"
           [src]="urlStoreMedia(getFieldsGroupValue('neo-image-text-layout', 'image'))"
           [style.object-fit]="getFieldsGroupValue('neo-image-text-layout','image-fill')"/>

      <div class="image-container"
           [class.default-bg]="!getFieldsGroupValue('image-block', 'image')"
           [style.height]="getFieldsGroupValue('image-block', 'image-height')+'px'"
           [style.border-radius]="getFieldsGroupValue('image-block', 'border-radius')+'px'">
        <img alt="store"  *ngIf="getFieldsGroupValue('image-block', 'image')"
             [src]="urlStoreMedia(getFieldsGroupValue('image-block', 'image'))"
             [style.object-fit]="getFieldsGroupValue('image-block','img-fit')"/>
      </div>

      <div class="text-container animated tdShrinkIn"
           [style.text-align]="getFieldsGroupValue('text-block', 'text-align')">

        <div class="it-header"
             [style.font-size]="getFieldsGroupValue('text-block', 'title-size')+'px'"
             [style.color]="getFieldsGroupValue('text-block', 'title-color')">
          {{getFieldsGroupValue('text-block', 'title')}}
        </div>

        <div class="it-desc"
             *ngIf="getFieldsGroupValue('text-block', 'desc')"
             [style.color]="getFieldsGroupValue('text-block', 'desc-color')"
             [style.margin-top]="getFieldsGroupValue('text-block', 'desc-margin-top')+'px'">
          <span>{{getFieldsGroupValue('text-block', 'desc')}}</span>
        </div>

        <a class="neo-btn" [href]="getLink(getFieldsGroupValue('text-block', 'btn-url'))"
           [style.margin-top]="getFieldsGroupValue('text-block', 'btn-margin-top')+'px'"
           *ngIf="getFieldsGroupValue('text-block', 'btn-label')">
          {{getFieldsGroupValue('text-block', 'btn-label')}}
        </a>

      </div>
    </div>

  </div>
</div>
