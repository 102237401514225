<div [id]="data.code" class="neo-video shopyan-section" [class.build-mode]="buildMode"
     [style.background-color]="getFieldValue( 'background-color')">

  <div class="neo-section-layout"
       [class.large]="getFieldValue('layout-width') == 'large'">

    <div class="player"
         [class.default-bg]="!safeURL"
         [style.height]="getFieldValue( 'height')+'px'">
      <iframe *ngIf="safeURL" [src]='safeURL' [frameBorder]="0" allowfullscreen></iframe>
    </div>

  </div>
</div>
