import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ComponentLookup, Link} from "@app/_shared";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {DomSanitizer} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-neo-text-banner',
  templateUrl: './neo-text-banner.component.html',
  styleUrls: ['./neo-text-banner.component.scss']
})
@ComponentLookup('neo-text-banner')
export class NeoTextBannerComponent extends ShopyanSectionComponent implements OnInit, OnDestroy {

  selectedBlockIndex: number;
  intervalId: any;

  constructor(private _sanitizer: DomSanitizer,
              @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  ngOnInit(): void {
    if (this.data.blocks) {
      this.data.blocks = [...this.data.blocks.filter(block => !block.hidden)];
      if (this.data.blocks?.length > 0) {
        this.selectedBlockIndex = 0;
        if (isPlatformBrowser(this.platformId)) {
          this.intervalId = setInterval(() => {
            if (this.data.blocks!.length > (this.selectedBlockIndex + 1)) {
              this.selectedBlockIndex++;
            } else {
              this.selectedBlockIndex = 0;
            }
          }, 5000);
        }
      }
    }
  }


  getLink(link: Link): any {
    let l = this.constructLink(link);
    return l ? this._sanitizer.bypassSecurityTrustResourceUrl(l) : undefined;
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      clearInterval(this.intervalId);
    }
  }

}
