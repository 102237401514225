import { Directive, Self, SkipSelf, Host } from '@angular/core';
import { NgControl, FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[InputValidator]',
  host: {
    '[class.is-invalid]': 'isInvalid'
  }
})
export class HasErrorsDirective {

  constructor(@Host() @SkipSelf() private formGroupDirective: FormGroupDirective,
              @Self() private control: NgControl) {
  }

  public get isInvalid() {
    return this.control.errors && (this.control.dirty || this.control.touched || this.formGroupDirective.submitted);
  }

}
