<div id="page-anchor"></div>
<div id="app-container" [dir]="direction">
  <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
  <div class="to-top" (click)="scrollToTop()" [class.show-scroll-top]="windowScrolled">
  </div>
  <ng-container #viewContainerRef></ng-container>
  <ng-container *ngIf="storeService.storeId && !buildMode">
    <app-sales-pop></app-sales-pop>
    <app-whatsapp></app-whatsapp>
  </ng-container>
</div>
