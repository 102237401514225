import {ErrorHandler, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {StoreService} from "@app/_shared";
import {isPlatformServer} from "@angular/common";

@Injectable({providedIn: 'root'})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private storeService: StoreService,
              @Inject(PLATFORM_ID) protected platformId: any) {
  }

  handleError(error: any) {
    if(isPlatformServer(this.platformId)) {
      const timestamp = new Date().toISOString();
      console.error(` ==== Error occurred at ${timestamp} ====> STORE ID : ` + this.storeService.storeId );
    }
    console.error(error?.message);
  }

}
