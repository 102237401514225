import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {CollectionService, ComponentLookup, ShopyanMyCollectionsComponent} from '@app/_shared';
import {TransferState} from "@angular/platform-browser";

@Component({
  selector: 'lib-neo-my-collections',
  templateUrl: './neo-my-collections.component.html',
  styleUrls: ['./neo-my-collections.component.scss']
})
@ComponentLookup('neo-my-collections')
export class NeoMyCollectionsComponent extends ShopyanMyCollectionsComponent implements OnInit, OnDestroy {

  constructor(collectionService: CollectionService,
              transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any) {
    super(collectionService, transferState, platformId);
  }

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
