<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <a class="option-item" [href]="getLink(node.menuItem)" (click)="menuAction(node.menuItem.targetValue)">
      <div class="oi-lbl">{{node.menuItem.label}}</div>
      <div class="oi-arrow"></div>
    </a>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
    <div class="option-item" matTreeNodeToggle>
      <div class="oi-lbl">{{node.menuItem.label}}</div>
      <div class="oi-total">{{node.menuItem.children.length}}</div>
    </div>
  </mat-tree-node>
</mat-tree>
