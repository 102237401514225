import {Component, Input, OnInit} from '@angular/core';
import {GraphBlock} from '@app/_shared';

@Component({
  selector: 'lib-neo-section-header',
  templateUrl: './neo-section-header.component.html',
  styleUrls: ['./neo-section-header.component.scss']
})
export class NeoSectionHeaderComponent implements OnInit {

  @Input()
  data: GraphBlock;

  constructor() {
  }

  ngOnInit(): void {
  }

  getFieldValue(fieldCode: string): any {
    return this.data.values?.find(item => item.fieldCode === fieldCode)?.value;
  }

}
