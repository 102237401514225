import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {
  AuthService,
  CommonService,
  ComponentLookup,
  getNeoReviewsPerRow, MediaSize, MediaType, ProductService,
  ShopyanProductReviewsComponent,
  ShopyanToastrService,
  StorageService, StoreService, urlMedia
} from '@app/_shared';
import {FormBuilder} from "@angular/forms";
import {DomSanitizer, TransferState} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {isPlatformBrowser} from "@angular/common";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'lib-neo-product-reviews',
  templateUrl: './neo-product-reviews.component.html',
  styleUrls: ['./neo-product-reviews.component.scss']
})
@ComponentLookup('neo-reviews')
export class NeoProductReviewsComponent extends ShopyanProductReviewsComponent implements OnInit, OnDestroy, AfterViewInit {

  reviewsPerRow: number = 1;

  @ViewChild("layout")
  layout: ElementRef;

  constructor(translate: TranslateService,
              _sanitizer: DomSanitizer,
              activatedRoute: ActivatedRoute,
              http: HttpClient,
              formBuilder: FormBuilder,
              commonService: CommonService,
              productService: ProductService,
              storeService: StoreService,
              router: Router,
              basicToastrService: ShopyanToastrService,
              authService: AuthService,
              storageService: StorageService,
              @Inject(PLATFORM_ID) platformId: any,
              transferState: TransferState) {
    super(translate, _sanitizer, activatedRoute, http, formBuilder, commonService, productService,
      storeService, router, basicToastrService, authService, storageService, platformId, transferState);
  }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.onResize();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let total = this.getFieldValue('max-per-row') || 3;
    this.reviewsPerRow = getNeoReviewsPerRow(this.layout.nativeElement.offsetWidth, total);
  }

  urlReviewMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.REVIEW, MediaSize.medium, media);
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
