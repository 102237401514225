<div [id]="data.code" class="neo-rich-text shopyan-section" [class.build-mode]="buildMode">

  <div class="neo-section-layout v-padding">

    <div class="rich-text-wrapper"
         [style.background]="getFieldsGroupValue('rich-text-layout','background')">

      <div class="rich-text-block">
        <ng-container *ngFor="let block of data.blocks; let index = index;">
          <ng-container [ngSwitch]="block.blockCode">
            <ng-container *ngIf="!block.hidden">

              <div class="rich-text-title" *ngSwitchCase="'rich-text-header-block'"
                   [style.font-size]="getBlockFieldValueByIndex(index,'text-size')+'px'"
                   [style.color]="getBlockFieldValueByIndex(index,'text-color')">
                {{getBlockFieldValueByIndex(index, 'content')}}
              </div>

              <div class="rich-text-desc" *ngSwitchCase="'rich-text-desc-block'"
                   [style.margin-top]="getBlockFieldValueByIndex(index,'margin-top')+'px'">
                <p
                  [style.font-size]="getBlockFieldValueByIndex(index,'text-size')+'px'"
                  [style.color]="getBlockFieldValueByIndex(index,'text-color')">
                  {{getBlockFieldValueByIndex(index, 'content')}}
                </p>
              </div>

              <div *ngSwitchCase="'rich-text-btn-block'">
                <a class="neo-btn"
                   [href]="getLink(getBlockFieldValueByIndex(index,'url'))"
                   [style.margin-top]="getBlockFieldValueByIndex(index,'margin-top')+'px'">
                  {{getBlockFieldValueByIndex(index, 'label')}}</a>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

    </div>
  </div>

</div>
