<div [id]="data.code" class="neo-forgot shopyan-section" [class.build-mode]="buildMode">

  <div class="form-container">

    <div class="form-label">
      <div>{{'AUTH.FORGOT_PASSWORD.TITLE' | translate}}</div>
      <span>{{'AUTH.FORGOT_PASSWORD.INTRO' | translate}}</span>
    </div>
    <div class="neo-alert ERROR" *ngIf="errorMsg">
      {{errorMsg}}.
    </div>
    <form [formGroup]="formGroup">
      <div class="neo-field">
        <input InputValidator type="text" class="neo-input" placeholder="{{'AUTH.FORGOT_PASSWORD.EMAIL' | translate}}"
               formControlName="email"/>
        <app-field-error controlName="email" errorKey="required">
          {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
        </app-field-error>
        <app-field-error controlName="email" errorKey="pattern">
          {{'COMMON.ERROR.INVALID_MAIL' | translate}}
        </app-field-error>
        <app-field-error controlName="email" errorKey="maxlength">
          {{'COMMON.ERROR.TOO_LONG' | translate}}
        </app-field-error>
      </div>

    </form>
    <button loadingBtn [style.background-color]="getFieldValue('btn-color')"
            [loading]="loading" (click)="submit()" type="submit" class="neo-btn submit">{{'COMMON.RESET' | translate}}
    </button>

    <div class="switch-page">
      {{'AUTH.FORGOT_PASSWORD.NO_ACCOUNT' | translate}}
      <a [routerLink]="['/register']">{{'AUTH.FORGOT_PASSWORD.REGISTER' | translate}}</a>
    </div>

  </div>

</div>
