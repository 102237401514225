import { PaymentMethod } from "../../enum/payment-method.enum";
import { CartForm } from "./cart.model";
import { CheckoutFieldValue } from "./checkout-field.model";
import { Address } from "./address.model";

export class CheckoutForm {
  cartForm: CartForm;
  expressFormFields: CheckoutFieldValue[];
  discountCode: string;
  origin: string;
  shipping: string;
  paymentMethod: PaymentMethod;
  shippingAddress?: Address;
  existingShippingAddress: string;
  billingAddress?: Address;
  existingBillingAddress: string;
}
