import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {Customer, StorageService, StoreAccountMode, User, UserForm} from "@app/_shared";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {isPlatformServer} from "@angular/common";

@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(public jwtHelper: JwtHelperService,
              private storageService: StorageService,
              @Inject(PLATFORM_ID) private platformId: any,
              private router: Router,
              private http: HttpClient) {
  }


  getCart(): Observable<any> {
    let url = `${isPlatformServer(this.platformId) ? environment.privateOrdersApiUrl : environment.pubicOrdersApiUrl}/api/cart`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }


  getWishList(): Observable<any> {
    let url = `${isPlatformServer(this.platformId) ? environment.privateStoreApiUrl : environment.pubicStoreApiUrl}/api/customers/wishList`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(url, httpOptions);
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }


  public setToken(token: string) {
    localStorage.setItem('token', token)
  }

  logIn(user: User): Observable<any> {
    // Browser only - no private api call
    let url = `${environment.pubicStoreApiUrl}/auth/login`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http
      .post(url, user, httpOptions);
  }

  signup(user: UserForm): Observable<any> {
    // Browser only - no private api call
    let url = `${environment.pubicStoreApiUrl}/auth/register`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http
      .post(url, user, httpOptions);
  }

  forgot(user: User): Observable<any> {
    // Browser only - no private api call
    let url = `${environment.pubicStoreApiUrl}/auth/forgot`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http
      .post(url, user, httpOptions);
  }

  public updateConnectedUser(user: Customer) {
    this.storageService.saveData('user', JSON.stringify(user));
  }

  public getConnectedUser(): any {
    if (this.isAuthenticated()) {
      return JSON.parse(this.storageService.getData('user') as string);
    } else {
      // To empty the localstorage & redirect
      this.logOut();
    }
  }

  public isAuthEnabled(): boolean {
    // Check if account mode is not disabled for store
    let mode = this.storageService.getData('mode');
    return !mode || JSON.parse(mode) != StoreAccountMode.DISABLED;
  }

  public isAuthenticated(): boolean {
    // Check whether the token is expired and return
    // true or false
    return this.isAuthEnabled() && !this.jwtHelper.isTokenExpired(this.getToken());
  }

  public logOut() {
    if (!this.isAuthEnabled()) return;
    this.cleanSession();
    this.router.navigate(['/login']).then();
  }

  public cleanSession() {
    this.storageService.removeData('token');
    this.storageService.removeData('user');
  }

}
