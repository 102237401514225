import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {PixelType} from "@app/_shared";
import {DOCUMENT, isPlatformServer} from "@angular/common";

declare var gtag: Function;
declare var fbq: Function;
declare var snaptr: Function;
declare var ttq: any;

@Injectable({
  providedIn: 'root'
})
export class PixelService {

  facebookConversionType: string;

  constructor(@Inject(PLATFORM_ID) private platformId: any, private http: HttpClient,
              @Inject(DOCUMENT) private document: Document) {
  }

  public getPixels(): Observable<any> {
    let url = `${isPlatformServer(this.platformId) ? environment.privateStoreApiUrl : environment.pubicStoreApiUrl}/applications/pixels`;
    let params = new HttpParams()
    params = params.append('origin', window.location.hostname);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http
      .get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * Handler request error
   * @param error
   */
  public handleError(error: any) {
    return throwError(error);
  }

  initPixels(): void {
    this.getPixels().subscribe(response => {
      if (response && response.length > 0) {
        response.forEach((item: any) => {
          switch (item.type) {
            case PixelType.FACEBOOK:
              if (item.pixels && item.pixels.length > 0) {
                this.addFacebookPixel();
                item.pixels.forEach((pixel: any) => {
                  fbq('init', pixel.value);
                });
                fbq('track', 'PageView');
                this.facebookConversionType = item.conversionType && item.conversionType === "LEAD" ? "Lead" : "Purchase";
              }
              break;
            case PixelType.SNAPCHAT:
              if (item.pixels && item.pixels.length > 0) {
                this.addSnapchatPixel();
                item.pixels.forEach((pixel: any) => {
                  snaptr('init', pixel.value);
                });
                snaptr('track', 'PAGE_VIEW');
              }
              break;
            case PixelType.TIKTOK:
              if (item.pixels && item.pixels.length > 0) {
                this.addTikTokPixel();
                item.pixels.forEach((pixel: any) => {
                  ttq.load(pixel.value);
                });
                ttq.track('PageView');
              }
              break;
            case PixelType.GOOGLE_ANALYTICS:
              if (item.pixels && item.pixels.length > 0) {
                this.addGooglePixel();
                item.pixels.forEach((pixel: any) => {
                  gtag('config', pixel.value);
                });
              }
              break;
            case PixelType.GOOGLE_TAG_MANAGER:
              if (item.pixels && item.pixels.length > 0) {
                item.pixels.forEach((pixel: any) => {
                  this.addGoogleTagManager(pixel.value);
                });
              }
              break;
          }
        });
      }
    });
  }

  addGooglePixel(): void {
    const scriptGtag = this.document.createElement('script') as HTMLScriptElement;
    scriptGtag.src = `https://www.googletagmanager.com/gtag/js`;
    scriptGtag.async = true;
    this.document.head.appendChild(scriptGtag);

    const scriptInit = this.document.createElement('script') as HTMLScriptElement;
    const scriptBody = this.document.createTextNode(`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
      `);

    scriptInit.appendChild(scriptBody);
    this.document.head.appendChild(scriptInit);
  }


  addGoogleTagManager(id: string): void {
    const scriptGtag = this.document.createElement('script') as HTMLScriptElement;
    scriptGtag.src = `https://www.googletagmanager.com/gtag/js`;
    scriptGtag.async = true;
    this.document.head.appendChild(scriptGtag);

    const scriptInit = this.document.createElement('script') as HTMLScriptElement;
    const scriptBody = this.document.createTextNode(`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
      `);

    scriptInit.appendChild(scriptBody);
    this.document.head.appendChild(scriptInit);

    const noScriptInit = this.document.createElement('noscript') as HTMLScriptElement;
    const noScriptBody = this.document.createTextNode(`
              <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `);

    noScriptInit.appendChild(noScriptBody);
    this.document.body.appendChild(noScriptInit);
  }


  addSnapchatPixel(): void {
    const scriptInit = this.document.createElement('script') as HTMLScriptElement;
    const scriptBody = this.document.createTextNode(`
        (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
    {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
      a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
      r.src=n;var u=t.getElementsByTagName(s)[0];
      u.parentNode.insertBefore(r,u);})(window,document,
      'https://sc-static.net/scevent.min.js');
      `);

    scriptInit.appendChild(scriptBody);
    this.document.head.appendChild(scriptInit);
  }

  addFacebookPixel(): void {
    const scriptInit = this.document.createElement('script') as HTMLScriptElement;
    const scriptBody = this.document.createTextNode(`
        !function (f, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      `);

    scriptInit.appendChild(scriptBody);
    this.document.head.appendChild(scriptInit);
  }

  addTikTokPixel(): void {
    const scriptInit = this.document.createElement('script') as HTMLScriptElement;
    const scriptBody = this.document.createTextNode(`
        !function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
      )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
    }(window, document, 'ttq');
      `);

    scriptInit.appendChild(scriptBody);
    this.document.head.appendChild(scriptInit);
  }

}
