<div [id]="data.code" class="neo-collage shopyan-section animated tdFadeInUp"
     [class.build-mode]="buildMode">
  <div class="neo-section-layout large">

    <ng-container *ngIf="blocks.length > 0; else noBlock">
      <div class="collage-content">
        <ng-container *ngFor="let item of blocks">
          <div class="cc-element">
            <div class="cc-sub-element">
              <div class="neo-collage-item">
                <lib-neo-collage-item [data]="item[0]" [storeId]="storeID"></lib-neo-collage-item>
              </div>
              <div class="neo-collage-item" *ngIf="item.length > 1">
                <lib-neo-collage-item [data]="item[1]" [storeId]="storeID"></lib-neo-collage-item>
              </div>
            </div>
            <div class="neo-collage-item" *ngIf="item.length > 2">
              <lib-neo-collage-item [data]="item[2]" [storeId]="storeID"></lib-neo-collage-item>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noBlock>
      <lib-neo-no-result [text]="'COLLAGE.ADD_ITEM' | translate"></lib-neo-no-result>
    </ng-template>

  </div>
</div>
