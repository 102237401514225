import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, OrderService, ShopyanOrdersComponent} from '@app/_shared';
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'lib-neo-orders',
  templateUrl: './neo-orders.component.html',
  styleUrls: ['./neo-orders.component.scss']
})
export class NeoOrdersComponent extends ShopyanOrdersComponent implements OnInit, OnDestroy {

  constructor(formBuilder: FormBuilder,
              router: Router,
              orderService: OrderService,
              authService: AuthService) {
    super(formBuilder, router, orderService, authService);
  }

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
