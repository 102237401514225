<div [id]="data.code" class="neo-store-reviews shopyan-section" [class.build-mode]="buildMode">

  <div class="neo-section-layout large"
       [style.background]="getFieldsGroupValue('layout','background')">

    <lib-neo-section-header
      *ngIf="getFieldsGroup('header') && !getFieldsGroup('header').hidden"
      [data]="getFieldsGroup('header')"></lib-neo-section-header>

    <ngx-slick-carousel [config]="slideConfig" *ngIf="displayCarousel">
      <ng-container *ngFor="let block of data.blocks; let index = index;">
        <ng-container ngxSlickItem *ngIf="!block.hidden">
          <div class="review-item">
            <div class="review-rate neo-rate">
              <bar-rating [rate]="getBlockFieldValueByIndex(index, 'rate')" [max]="5" [readOnly]="true"></bar-rating>
            </div>
            <div class="review-comment">
              {{getBlockFieldValueByIndex(index, 'comment')}}
            </div>
            <div class="review-user">
              {{getBlockFieldValueByIndex(index, 'reviewer')}}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ngx-slick-carousel>

  </div>
</div>
