import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {
  ComponentLookup,
  getNeoProductsPerRow,
  ProductService,
  ShopyanCartHelperService,
  ShopyanFavoriteProductsComponent,
  StorageService
} from '@app/_shared';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'lib-neo-favorites',
  templateUrl: './neo-favorites.component.html',
  styleUrls: ['./neo-favorites.component.scss']
})
@ComponentLookup('neo-favorite-products')
export class NeoFavoritesComponent extends ShopyanFavoriteProductsComponent implements OnInit, OnDestroy, AfterViewInit {

  productsPerRow: number = 2;

  @ViewChild("layout")
  layout: ElementRef;

  constructor(storageService: StorageService,
              basicHelperService: ShopyanCartHelperService,
              productService: ProductService,
              @Inject(PLATFORM_ID) private platformId: any) {
    super(storageService, productService, basicHelperService);
  }

  ngOnInit(): void {
    this.filterProducts();
  }


  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.onResize();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let total = this.getFieldsGroupValue('favorites-layout', 'products-per-row') || 4;
    this.productsPerRow = getNeoProductsPerRow(this.layout.nativeElement.offsetWidth, total);
  }


  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
