import {Component} from '@angular/core';
import {Address, CommonService, extractErrorMessagesFromResponse, ShopyanToastrService} from "@app/_shared";
import {Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../environments/environment";

@Component({
  template: ''
})
export abstract class ShopyanAddressFormComponent {

  subscription: Subscription = new Subscription();

  formGroup: FormGroup;

  loading = false;
  countries: any[] = [];
  address: Address;

  protected constructor(protected formBuilder: FormBuilder,
                        protected toastrService: ShopyanToastrService,
                        protected commonService: CommonService) {
  }

  init(): void {
    // Browser only - no private api call
    const url = `${environment.pubicStoreApiUrl}/refData/countries`
    this.commonService.get(url).subscribe(data => this.countries = data);

    this.formGroup = this.formBuilder.group({
      id: [this.address?.id],
      firstName: [this.address?.firstName, Validators.required],
      lastName: [this.address?.lastName],
      phoneNumber: [this.address?.phoneNumber],
      line1: [this.address?.line1, Validators.required],
      line2: [this.address?.line2],
      city: [this.address?.city, Validators.required],
      state: [this.address?.state],
      zipCode: [this.address?.zipCode],
      country: [this.address?.country, Validators.required]
    });
  }

  /**
   * Confirm form
   */
  confirmForm(): void {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      return;
    }
    let address = new Address();
    Object.assign(address, this.formGroup.value);

    if (this.address) {
      this.doUpdate(address);
    } else {
      this.doSave(address);
    }

  }

  /**
   * Update
   * @param data
   */
  doUpdate(data: Address) {
    this.loading = true;
    // Browser only - no private api call
    const url = `${environment.pubicStoreApiUrl}/api/addresses`;
    const sub = this.commonService.update(url, data, true).subscribe({
      next: (response: any) => {
        this.closeModal(data);
      }, error: (error: any) => {
        this.toastrService.error(extractErrorMessagesFromResponse(error));
        this.loading = false;
      }
    });
    this.subscription.add(sub);
  }

  /**
   * save
   * @param data
   */
  doSave(data: Address) {
    this.loading = true;
    // Browser only - no private api call
    const url = `${environment.pubicStoreApiUrl}/api/addresses`;
    const sub = this.commonService.create(url, data, true).subscribe({
      next: (response: any) => {
        data.id = response;
        this.closeModal(data);
      }, error: (error: any) => {
        this.toastrService.error(extractErrorMessagesFromResponse(error));
        this.loading = false;
      }
    });
    this.subscription.add(sub);
  }

  abstract closeModal(data: any): void;
}
