import {Component, Inject, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import {StorageService, StoreService} from "@app/_shared";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  title: string;
  message: string;

  constructor(public storeService: StoreService,
              private translate: TranslateService,
              @Inject(PLATFORM_ID) private platformId: any,
              private storageService: StorageService,
              @Optional() @Inject('ORIGIN_HOSTNAME') public originHostname: string) {
  }

  ngOnInit(): void {

    const selectedLang = this.storageService.getData('my_lang') || 'fr';
    this.translate.use(selectedLang);

    this.title = this.translate.instant("ERROR_PAGE.ACCESS_DENIED.TITLE");
    this.message = this.translate.instant("ERROR_PAGE.ACCESS_DENIED.DESC");

    this.storeService.getBlockedPageData(this.originHostname).subscribe({
      next:
        (response: any) => {
          if (response?.title) {
            this.title = response.title;
          }
          if (response?.message) {
            this.message = response.message;
          }
        }
    });
  }
}
