import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {getFieldValue, GraphFieldValue, MediaSize, MediaType, urlMedia} from "@app/_shared";
import {interval, Subscription} from "rxjs";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-neo-indicator-item',
  templateUrl: './neo-indicator-item.component.html',
  styleUrls: ['./neo-indicator-item.component.scss']
})
export class NeoIndicatorItemComponent implements OnInit, OnDestroy {

  @Input()
  fields: GraphFieldValue[];

  @Input()
  layoutFields: GraphFieldValue[];

  @Input()
  storeId: string;

  numbers$ = interval(100);
  subNumbers: Subscription;
  counter = 0;

  private isComponentVisible = false;

  constructor() {
  }

  ngOnInit(): void {
    this.onComponentVisible();
  }

  // @HostListener('window:scroll', [])
  // onScroll() {
  //   if (!this.isComponentVisible && this.isElementInViewport()) {
  //     this.onComponentVisible();
  //     this.isComponentVisible = true;
  //   }
  // }
  //
  // isElementInViewport(): boolean {
  //   const rect = this.elementRef.nativeElement.getBoundingClientRect();
  //   return (
  //     rect.top >= 0 &&
  //     rect.left >= 0 &&
  //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  //   );
  // }
  //
  onComponentVisible() {
    let value = Number(this.getFieldValue('indicator-value')) || 100;
    let step = Math.floor(value / 10);
    if (step === 0) {
      step = 1;
    }
    this.subNumbers = this.numbers$.subscribe(x => {
      this.counter += step;
      if (this.counter + step > value) {
        this.counter = value;
        this.unsubscribe();
      }
    });

  }

  unsubscribe(): void {
    this.subNumbers.unsubscribe();
  }

  getFieldValue(fieldCode: string): any {
    return getFieldValue(this.fields, fieldCode);
  }

  getLayoutFieldValue(fieldCode: string): any {
    return getFieldValue(this.layoutFields, fieldCode);
  }

  urlStoreMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.STORE, MediaSize.original, media);
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

}
