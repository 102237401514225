<div id="sales-pop" *ngIf="salesPop && product" [class.show-on]="displayPop"  [dir]="direction">
  <div class="close" (click)="close()"></div>

  <div class="product-media" *ngIf="(product.medias && product.medias.length > 0)
  || (product.imageLinks && product.imageLinks.length > 0); else noMedia">
    <img alt="store"  [src]="urlMedia(product.medias[0])"
         *ngIf="product.medias && product.medias.length > 0"/>
    <img alt="store"  [src]="product.imageLinks[0]"
         *ngIf="!product.medias || product.medias.length == 0"/>
  </div>
  <ng-template #noMedia>
    <div class="ic">
      <div class="ic-content">
      </div>
    </div>
  </ng-template>
  <div class="np-content">
    <div class="np-message">{{salesPop.message}}</div>
    <a class="np-target" href="/product/{{product.slug}}">{{product.name}}</a>
    <div class="since">{{'COUNTDOWN.SINCE' | translate:{min:since} }} </div>
  </div>
</div>
