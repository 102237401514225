<div id="classic-checkout">

  <ng-container *ngIf="customer">
    <div class="current-user">
      <div class="user-avatar">
        {{avatar}}
      </div>
      <div>
        <div class="username">{{customer.firstName}} {{customer.lastName}}</div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!customer && storeAccountMode == storeAccountModeEnum.OPTIONAL">
    <div class="already-have-account">
      {{'CHECKOUT.FORM.ALREADY' | translate}} <a href="/login">{{'CHECKOUT.FORM.LOGIN' | translate}}</a>
    </div>
  </ng-container>

  <div class="checkout-section">
    <div class="ttl">{{'CHECKOUT.FORM.DELIVERY_ADDRESS' | translate}}</div>
    <div class="neo-field" *ngIf="customer && customer.addresses && customer.addresses.length > 0">
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="checkoutForm.existingShippingAddress">
          <mat-option [value]="''">{{'CHECKOUT.FORM.ADD_ADDRESS' | translate}}</mat-option>
          <ng-container *ngFor="let item of customer.addresses">
            <mat-option [value]="item.id"> {{item.firstName}} {{item.lastName}}
              : {{item.line1}} {{item.city}} {{item.country}} {{item.phoneNumber}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-container [formGroup]="shippingAddressFG"
                  *ngIf="shippingAddressFG &&  checkoutForm.existingShippingAddress == ''">
      <div class="neo-flex">
        <div class="neo-field">
          <input class="neo-input"
                 InputValidator formControlName="firstName"
                 type="text" placeholder="{{'CHECKOUT.FORM.FIRSTNAME' | translate}}"/>
          <app-field-error controlName="firstName" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
          <app-field-error controlName="firstName" errorKey="maxlength">
            {{'COMMON.ERROR.TOO_LONG' | translate}}
          </app-field-error>
        </div>
        <div class="neo-field">
          <input class="neo-input"
                 InputValidator formControlName="lastName"
                 type="text" placeholder="{{'CHECKOUT.FORM.LAST_NAME' | translate}}"/>
          <app-field-error controlName="lastName" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
          <app-field-error controlName="lastName" errorKey="maxlength">
            {{'COMMON.ERROR.TOO_LONG' | translate}}
          </app-field-error>
        </div>
      </div>
      <div class="neo-flex">
        <div class="neo-field">
          <input class="neo-input"
                 InputValidator formControlName="phoneNumber"
                 type="text" placeholder="{{'CHECKOUT.FORM.PHONE_NUMBER' | translate}}"/>
          <app-field-error controlName="phoneNumber" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
          <app-field-error controlName="phoneNumber" errorKey="pattern">
            {{'COMMON.ERROR.INVALID_VALUE' | translate}}
          </app-field-error>
        </div>
        <div class="neo-field">
          <input class="neo-input"
                 InputValidator formControlName="city"
                 type="text" placeholder="{{'CHECKOUT.FORM.CITY' | translate}}"/>
          <app-field-error controlName="city" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
        </div>
      </div>
      <div class="neo-field">
        <input class="neo-input"
               InputValidator formControlName="line1"
               type="text" placeholder="{{'CHECKOUT.FORM.ADDRESS' | translate}}"/>
      </div>
      <div class="neo-field">
        <input class="neo-input"
               formControlName="line2"
               type="text" placeholder="{{'CHECKOUT.FORM.ADDRESS_2' | translate}}"/>
      </div>
      <div class="neo-flex">
        <div class="neo-field">
          <input class="neo-input"
                 formControlName="zipCode"
                 type="text" placeholder="{{'CHECKOUT.FORM.ZIP_CODE' | translate}}"/>
          <app-field-error controlName="zipCode" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
          <app-field-error controlName="zipCode" errorKey="maxLength">
            {{'COMMON.ERROR.INVALID_VALUE' | translate}}
          </app-field-error>
        </div>
        <div class="neo-field">
          <mat-form-field appearance="outline">
            <mat-label>{{'ACCOUNT.PROFILE.COUNTRY' | translate}}</mat-label>
            <mat-select formControlName="country">
              <ng-container *ngFor="let item of countries">
                <mat-option [value]="item.code">
                  {{item.name}}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <app-field-error controlName="country" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
        </div>

      </div>
    </ng-container>
  </div>

  <div class="checkout-section">
    <div class="ttl">{{'CHECKOUT.FORM.BILLING_ADDRESS' | translate}}</div>
    <div class="neo-field">

      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="checkoutForm.existingBillingAddress">
          <mat-option [value]="''">{{'CHECKOUT.FORM.SAME_ADDRESS' | translate}}</mat-option>
          <ng-container *ngIf="customer && customer.addresses && customer.addresses.length > 0">
            <ng-container *ngFor="let item of customer.addresses">
              <mat-option [value]="item.id"> {{item.firstName}} {{item.lastName}}
                : {{item.line1}} {{item.city}} {{item.country}} {{item.phoneNumber}}</mat-option>
            </ng-container>
          </ng-container>
          <mat-option [value]="'-1'">{{'CHECKOUT.FORM.ADD_ADDRESS' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <ng-container [formGroup]="billingAddressFG"
                  *ngIf="billingAddressFG &&  checkoutForm.existingBillingAddress == '-1'">
      <div class="neo-flex">
        <div class="neo-field">
          <input class="neo-input"
                 InputValidator formControlName="firstName"
                 type="text" placeholder="{{'CHECKOUT.FORM.FIRSTNAME' | translate}}"/>
          <app-field-error controlName="firstName" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
          <app-field-error controlName="firstName" errorKey="maxlength">
            {{'COMMON.ERROR.TOO_LONG' | translate}}
          </app-field-error>
        </div>
        <div class="neo-field">
          <input class="neo-input"
                 InputValidator formControlName="lastName"
                 type="text" placeholder="{{'CHECKOUT.FORM.LAST_NAME' | translate}}"/>
          <app-field-error controlName="lastName" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
          <app-field-error controlName="lastName" errorKey="maxlength">
            {{'COMMON.ERROR.TOO_LONG' | translate}}
          </app-field-error>
        </div>
      </div>
      <div class="neo-flex">
        <div class="neo-field">
          <input class="neo-input"
                 InputValidator formControlName="phoneNumber"
                 type="text" placeholder="{{'CHECKOUT.FORM.PHONE_NUMBER' | translate}}"/>
          <app-field-error controlName="phoneNumber" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
          <app-field-error controlName="phoneNumber" errorKey="pattern">
            {{'COMMON.ERROR.INVALID_VALUE' | translate}}
          </app-field-error>
        </div>
        <div class="neo-field">
          <input class="neo-input"
                 InputValidator formControlName="city"
                 type="text" placeholder="{{'CHECKOUT.FORM.CITY' | translate}}"/>
          <app-field-error controlName="city" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
        </div>
      </div>
      <div class="neo-field">
        <input class="neo-input"
               InputValidator formControlName="line1"
               type="text" placeholder="{{'CHECKOUT.FORM.ADDRESS' | translate}}"/>
      </div>
      <div class="neo-field">
        <input class="neo-input"
               formControlName="line2"
               type="text" placeholder="{{'CHECKOUT.FORM.ADDRESS_2' | translate}}"/>
      </div>
      <div class="neo-flex">
        <div class="neo-field">
          <input class="neo-input"
                 formControlName="zipCode"
                 type="text" placeholder="{{'CHECKOUT.FORM.ZIP_CODE' | translate}}"/>
          <app-field-error controlName="zipCode" errorKey="maxLength">
            {{'COMMON.ERROR.INVALID_VALUE' | translate}}
          </app-field-error>
        </div>
        <div class="neo-field">
          <mat-form-field appearance="outline">
            <mat-label>{{'ACCOUNT.PROFILE.COUNTRY' | translate}}</mat-label>
            <mat-select formControlName="country">
              <ng-container *ngFor="let item of countries">
                <mat-option [value]="item.code">
                  {{item.name}}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <app-field-error controlName="country" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
        </div>

      </div>
    </ng-container>
  </div>
</div>

