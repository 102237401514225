import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'button[loadingBtn]',
    templateUrl: './loading-button.component.html',
    styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent {

    @HostBinding('class.loading')
    @HostBinding('attr.aria-disabled')
    @Input()
    loading = false;

    constructor() {
    }
}
