<div [id]="data.code" class="neo-collection shopyan-section animated tdFadeInUp"
     [class.build-mode]="buildMode">
  <div class="neo-section-layout large"
       [style.margin-left]="getFieldsGroupValue('collection-layout','margin-left')+'px'"
       [style.margin-right]="getFieldsGroupValue('collection-layout','margin-right')+'px'">

    <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
    <div class="collection-container"
         [style.background]="getFieldsGroupValue('collection-layout','background')"
         [style.border-radius]="getFieldsGroupValue('collection-layout','border-radius')+'px'">
      <div class="collection-data" *ngIf="collection">
        <a href="/search?collection={{collection.id}}" class="collection"
           [style.border-radius]="getFieldsGroupValue('collection-layout','image-border-radius')+'px'">
          <div class="col-image" [class.default-bg]="!collection.media">
            <img alt="store"  [src]="urlCollectionMediumMedia(collection.media)" *ngIf="collection.media"/>
          </div>
          <div class="col-label">
            <span>{{collection.name}}</span>
          </div>
        </a>
      </div>

      <div class="products-area">

        <ng-container *ngIf="products && products.length > 0">
          <div class="products">
            <div class="product-item" *ngFor="let item of products;">
              <lib-neo-product-card
                [storeId]="storeID"
                [product]="item"
                [fields]="getFieldsGroup('products-layout')?.values || []"
              ></lib-neo-product-card>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!products || products.length == 0">
          <lib-neo-no-result></lib-neo-no-result>
        </ng-container>

      </div>

    </div>

  </div>
</div>
