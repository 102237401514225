import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lib-neo-quantity',
  templateUrl: './neo-quantity.component.html',
  styleUrls: ['./neo-quantity.component.scss']
})
export class NeoQuantityComponent implements OnInit {
  @Input()
  value: number = 1;

  @Input()
  min: number;

  @Input()
  max: number;

  @Output() valueChange = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit(): void {
    if (!this.min) {
      this.min = 1;
    }
    if (!this.value) {
      this.value = 1;
    }

    if (this.min && this.value < this.min) {
      this.value = this.min;
    }
  }

  inc(): void {
    if (!this.max || this.value < this.max) {
      this.value++;
      this.valueChange.emit(this.value);
    }
  }

  dec(): void {
    if (!this.min || this.value > this.min) {
      this.value--;
      this.valueChange.emit(this.value);
    }
  }

  change(): void {
    this.valueChange.emit(this.value);
  }

}
