import {Injectable} from "@angular/core";
import {TranslateLoader} from "@ngx-translate/core";
import {Observable, of} from "rxjs";

@Injectable()
export class TranslationLoaderService implements TranslateLoader {
  constructor() {
  }

  getTranslation(lang: string): Observable<any> {
    const translations = require(`../assets/i18n/${lang}.json`);
    return of(translations);
  }
}
