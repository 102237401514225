import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {
  Collection,
  CollectionService,
  Pageable,
  Product,
  ProductService,
  ShopyanCartHelperService,
} from "@app/_shared";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {ConversionService} from "@app/_shared/service/conversion.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanProductListComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  products: Product[] = [];
  collections: Collection[] = [];
  total: number;
  filter?: string;
  collectionCode: string = '';
  pageable: Pageable = new Pageable();
  showMore = false;
  sort: string = '';

  loading: boolean;


  protected constructor(private activatedRoute: ActivatedRoute,
                        private collectionService: CollectionService,
                        private productService: ProductService,
                        @Inject(PLATFORM_ID) protected platformId: any,
                        private basicHelperService: ShopyanCartHelperService,
                        private conversionService: ConversionService) {
    super();
  }

  init(): void {
      this.subscription
        .add(this.activatedRoute.queryParams.subscribe((params: any) => {
            this.filter = params['q'];
          if (params['collection']) {
            this.collectionCode = params['collection'];
          }
          this.filterCollections();
          this.filterProducts();
        }));

      if (!this.buildMode && isPlatformBrowser(this.platformId)) {
        this.subscription.add(this.conversionService.sendSearchEvent().subscribe());
      }
    }

  /**
   * Change sort
   */
  changeSort(): void {
    this.pageable.page = 1;
    this.pageable.sort = [];
    this.pageable.sort.push(this.sort);
    this.filterProducts();
  }


  /**
   * Filter collections
   */
  filterCollections(): void {
    this.subscription.add(this.collectionService.filterCollections().subscribe((response: any) => {
      if (response) {
        this.collections = response.data;
      }
    }));
  }

  /**
   * Filter products
   */
  filterProducts(isShowMore?: boolean): void {
    this.loading = true;
    if (isShowMore) {
      this.pageable.page++;
    }

    this.subscription.add(this.productService.filterProducts(this.pageable, this.collectionCode, this.filter).subscribe((response: any) => {
      this.loading = false;
      if (response) {
        if (isShowMore) {
          this.products = [...this.products, ...response.data];
        } else {
          this.products = response.data;
        }
        this.basicHelperService.initDiscounts(this.products);
        this.total = response.total;
        this.showMore = response.data.length === this.pageable.size;
      }
    }));
  }

  clearFilter(): void {
    delete this.filter;
    this.filterProducts();
  }

  filterByCollection(): void {
    this.pageable.page = 1;
    this.filterProducts();
  }


}
