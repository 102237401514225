import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {constructMenuLink, MenuItem} from "@app/_shared";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-neo-menu-item',
  templateUrl: './neo-menu-item.component.html',
  styleUrls: ['./neo-menu-item.component.scss']
})
export class NeoMenuItemComponent implements OnInit {

  @Input()
  items: MenuItem[];

  @ViewChild('childMenu') public childMenu: any;

  constructor(private _sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
  }

  getLink(menuItem: MenuItem): any {
    return this._sanitizer.bypassSecurityTrustResourceUrl(constructMenuLink(menuItem));
  }

}
