import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StorePageComponent} from "@app/store-page/store-page.component";
import {NotFoundComponent} from "@app/error-page/not-found/not-found.component";
import {InternalErrorComponent} from "@app/error-page/internal-error/internal-error.component";
import {MaintenanceComponent} from "@app/error-page/maintenance/maintenance.component";
import {AccessDeniedComponent} from "@app/error-page/access-denied/access-denied.component";

const routes: Routes = [
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'oops',
    component: InternalErrorComponent
  },
  {
    path: '**',
    component: StorePageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
