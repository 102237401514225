import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Collection, CollectionService, MediaSize, MediaType, urlMedia} from "@app/_shared";
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {isPlatformServer} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanMyCollectionsComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  collections: Collection[] = [];
  loading: boolean;

  protected constructor(private collectionService: CollectionService,
                        private transferState: TransferState,
                        @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  init(): void {
    if (isPlatformServer(this.platformId) || this.buildMode) {
      if (this.data && this.data.blocks && this.data.blocks.length > 0) {
        let collectionIds: string[] = this.data.blocks?.filter((block: any) => block.values?.length > 0 && !block.hidden).map((block: any) => block.values[0].value);
        if (collectionIds && collectionIds.length > 0) {
          this.loading = true;
          let sub = this.collectionService.findCollectionsByIds(collectionIds).subscribe(response => {
            if (response) {
              this.loading = false;
              collectionIds.forEach(id => {
                (response as Collection[]).forEach(c => {
                  if (c.id === id) {
                    this.collections.push(c);
                  }
                });
              });
            }
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(makeStateKey(this.data.code + '-collections'), this.collections as any);
            }
          });
          this.subscription.add(sub);
        }
      }
    } else {
      this.collections = this.transferState.get(makeStateKey(this.data.code + '-collections'), null as any);
    }

  }

  urlCollectionMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.COLLECTION, MediaSize.medium, media);
  }


}
