import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, Subscription} from "rxjs";
import {Contact} from "@app/_shared";
import {environment} from "../../../environments/environment";


@Injectable({providedIn: 'root'})
export class ContactService {

  subscription: Subscription = new Subscription();

  constructor(private http: HttpClient) {
  }

  public subscribeToNewsletter(mail: string): Observable<any> {
    // Browser only - no private api call
    let url = `${environment.pubicStoreApiUrl}/news-letter`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(url, mail, httpOptions);
  }


  public submitContactForm(contact: Contact): Observable<any> {
    // Browser only - no private api call
    let url = `${environment.pubicStoreApiUrl}/contact-us/store`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(url, contact, httpOptions);
  }


}
