<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <ng-container *ngFor="let child of items">
    <ng-container *ngIf="child.children && child.children.length > 0; else leafMenuItem">
      <button mat-menu-item [matMenuTriggerFor]="menu.childMenu">
        {{child.label}}
      </button>
      <app-neo-menu-item #menu [items]="child.children"></app-neo-menu-item>
    </ng-container>

    <ng-template #leafMenuItem>
      <a [href]="getLink(child)" mat-menu-item >
        {{child.label}}
      </a>
    </ng-template>
  </ng-container>
</mat-menu>
