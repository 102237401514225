import {Component, OnInit} from '@angular/core';
import {ComponentLookup} from "@app/_shared";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  selector: 'app-neo-express-btn',
  templateUrl: './neo-express-btn.component.html',
  styleUrls: ['./neo-express-btn.component.scss']
})
@ComponentLookup('neo-express-button')
export class NeoExpressBtnComponent extends ShopyanSectionComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }


  goToCheckout():void{
    document.getElementById('cod-form-anchor')?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

}
