import {Component, Input, OnInit} from '@angular/core';
import {ShopyanCartHelperService, ShopyanSummaryComponent} from '@app/_shared';

@Component({
  selector: 'lib-neo-order-summary-shared',
  templateUrl: './neo-order-summary-shared.component.html',
  styleUrls: ['./neo-order-summary-shared.component.scss']
})
export class NeoOrderSummarySharedComponent extends ShopyanSummaryComponent implements OnInit {

  constructor(helperService: ShopyanCartHelperService) {
    super(helperService);
  }

  ngOnInit(): void {
  }

}
