<div [id]="data.code" class="neo-contact-form shopyan-section" [class.build-mode]="buildMode"
     [style.background]="getFieldsGroupValue('contact-form-layout','background')">

  <div class="neo-section-layout v-padding" >

    <lib-neo-section-header
      *ngIf="getFieldsGroup('contact-form-header') && !getFieldsGroup('contact-form-header').hidden"
      [data]="getFieldsGroup('contact-form-header')"></lib-neo-section-header>

    <div class="form-area" [formGroup]="formGroup" *ngIf="formGroup">
      <div class="neo-flex">
        <div class="neo-field">
          <input type="text" InputValidator class="neo-input" placeholder="{{'CONTACT_FORM.NAME' | translate}}" formControlName="name"/>
          <app-field-error controlName="name" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
        </div>
        <div class="neo-field">
          <input type="text" InputValidator class="neo-input" placeholder="{{'CONTACT_FORM.EMAIL' | translate}}" formControlName="mail"/>
          <app-field-error controlName="mail" errorKey="required">
            {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
          </app-field-error>
          <app-field-error controlName="mail" errorKey="email">
            {{'COMMON.ERROR.INVALID_MAIL' | translate}}
          </app-field-error>
        </div>
      </div>
      <div class="neo-field">
        <input type="text" class="neo-input" placeholder="{{'CONTACT_FORM.PHONE' | translate}}" formControlName="phone"/>
      </div>
      <div class="neo-field">
        <textarea class="neo-input" InputValidator placeholder="{{'CONTACT_FORM.COMMENT' | translate}}" formControlName="comment"></textarea>
        <app-field-error controlName="comment" errorKey="required">
          {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
        </app-field-error>
      </div>

      <button loadingBtn
              [loading]="loader" class="neo-btn" (click)="submit()">
        {{'COMMON.SUBMIT' | translate}}
      </button>
    </div>

  </div>
</div>
