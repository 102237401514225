import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Collection, CollectionService, MediaSize, MediaType, Pageable, urlMedia,} from "@app/_shared";
import {Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {isPlatformServer} from "@angular/common";

@Component({
  template: ''
})
export abstract class ShopyanFeaturedCollectionsComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  collections: Collection[] = [];
  pageable: Pageable = new Pageable();
  loading: boolean;

  protected constructor(private collectionService: CollectionService,
                        private transferState: TransferState,
                        @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  init(): void {
    if (isPlatformServer(this.platformId) || this.buildMode) {
      let maxCollections = this.getMaxCollections();
      if (!maxCollections) {
        maxCollections = 3;
      }
      this.pageable.size = maxCollections;
      this.filterCollections();
    } else {
      this.collections = this.transferState.get(makeStateKey(this.data.code+'-neo-featured-collections'), null as any);
    }
  }

  protected abstract getMaxCollections(): number;

  /**
   * Filter collections
   */
  filterCollections(): void {
    this.loading = true;
    this.subscription.add(this.collectionService.findFeaturedCollections(this.pageable).subscribe((response: any) => {
      this.loading = false;
      this.collections = response.data;
      if (isPlatformServer(this.platformId)) {
        this.transferState.set(makeStateKey(this.data.code + '-neo-featured-collections'), this.collections as any);
      }
    }));
  }

  urlCollectionMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.COLLECTION, MediaSize.medium, media);
  }

}
