import {Component, OnInit} from '@angular/core';
import {StorageService} from "@app/_shared";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor(private translate: TranslateService,
              private storageService: StorageService) {
  }

  ngOnInit(): void {
    const selectedLang = this.storageService.getData('my_lang') || 'fr';
    if (selectedLang) {
      this.translate.use(selectedLang);
    }
  }

  get direction(): any {
    return this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
  }
}
