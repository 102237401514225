import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shopyan-placeholder',
  templateUrl: './shopyan-placeholder.component.html'
})
export class ShopyanPlaceholderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
