import {Component, Host, Input, SkipSelf} from '@angular/core';
import {ControlContainer, FormGroupDirective} from '@angular/forms';

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss']
})
export class FieldErrorComponent {

  @Input() controlName: string;
  @Input() errorKey: string;

  constructor(@Host() @SkipSelf() private formGroupDirective: FormGroupDirective,
              @Host() @SkipSelf() private controlContainer: ControlContainer) {
  }

  public get isInvalid() {
    if (this.controlName && this.errorKey) {
      const control = this.controlContainer?.control?.get(this.controlName);
      return control && true && (control.hasError(this.errorKey)
        && (this.errorKey === 'custom' || control.dirty || control.touched || this.formGroupDirective.submitted));
    }
    return true;
  }

}
