import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {isPlatformBrowser} from "@angular/common";


const SECRET_KEY = 'shopyan-secret-local-storage-key';

@Injectable({providedIn: 'root'})
export class StorageService {

  private localStorageAvailable: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.localStorageAvailable = isPlatformBrowser(this.platformId);
  }

  public saveData(key: string, value: string) {
    if (this.localStorageAvailable) {
      localStorage.setItem(key, this.encrypt(value));
    }
  }

  public getData(key: string) {
    if (this.localStorageAvailable) {
      let data = localStorage.getItem(key) || "";
      return this.decrypt(data);
    }
    return null;
  }

  public removeData(key: string) {
    if (this.localStorageAvailable) {
      localStorage.removeItem(key);
    }
  }

  public clearData() {
    if (this.localStorageAvailable) {
      localStorage.clear();
    }
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, SECRET_KEY).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  }

}
