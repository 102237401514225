<div [id]="data.code" class="neo-login shopyan-section" [class.build-mode]="buildMode">
  <form class="form-container" [formGroup]="loginFormGroup" *ngIf="loginFormGroup">
    <div class="form-label">
      <div>{{'AUTH.LOGIN.TITLE' | translate}}</div>
      <span>{{'AUTH.LOGIN.INTRO' | translate}}</span>
    </div>
    <div class="neo-alert ERROR" *ngIf="errorMsg">
      {{errorMsg}}.
    </div>
    <div class="switch-page">
      {{'AUTH.LOGIN.NO_ACCOUNT' | translate}}
      <a [routerLink]="['/register']">{{'AUTH.LOGIN.REGISTER' | translate}}</a>
    </div>
    <div class="neo-field">
      <input InputValidator type="text" class="neo-input" placeholder="{{'AUTH.LOGIN.EMAIL' | translate}}"
             formControlName="email"/>
      <app-field-error controlName="email" errorKey="required">
        {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
      </app-field-error>
      <app-field-error controlName="email" errorKey="pattern">
        {{'COMMON.ERROR.INVALID_MAIL' | translate}}
      </app-field-error>
      <app-field-error controlName="email" errorKey="maxlength">
        {{'COMMON.ERROR.TOO_LONG' | translate}}
      </app-field-error>
    </div>

    <div class="neo-field password-field">
      <div class="password-toggle" (click)="togglePassword()" [class.to-hide]="passwordVisible"></div>
      <input InputValidator [type]="passwordVisible ? 'text' : 'password'" class="neo-input"
             placeholder="{{'AUTH.LOGIN.PASSWORD' | translate}}" formControlName="password"/>
      <app-field-error controlName="password" errorKey="required">
        {{'COMMON.ERROR.REQUIRED_FIELD' | translate}}
      </app-field-error>
      <app-field-error controlName="password" errorKey="minlength">
        {{'COMMON.ERROR.TOO_SHORT' | translate}}
      </app-field-error>
      <app-field-error controlName="password" errorKey="maxlength">
        {{'COMMON.ERROR.TOO_LONG' | translate}}
      </app-field-error>
    </div>

    <button loadingBtn [style.background-color]="getFieldValue('btn-color')"
            [loading]="loading" (click)="submitLoginForm()" type="submit" class="neo-btn submit">{{'AUTH.LOGIN.LOGIN' | translate}}
    </button>

    <div class="actions">
      <div class="remember-me">
        <mat-checkbox formControlName="rememberMe">{{'AUTH.LOGIN.REMEMBER_ME' | translate}}</mat-checkbox>
      </div>
      <div class="forgot-password">
        <a [routerLink]="['/forgot-password']">{{'AUTH.LOGIN.FORGOT' | translate}}</a>
      </div>
    </div>

  </form>
</div>
