import {GraphFieldValue, Link, MediaSize, MediaType, MenuItem, MenuTargetType} from "@app/_shared";

/**
 *
 * @param mediaURL
 * @param storeId
 * @param mediaType
 * @param mediaSize
 * @param media
 */
export function urlMedia(mediaURL: string, storeId: string, mediaType: MediaType, mediaSize: MediaSize, media: string): string {
  return mediaURL + storeId + '/' + mediaType.toLowerCase() + '/' + mediaSize + '/' + media + '.webp';
}

/**
 * Get error message from the back response
 * @param response
 * @param defaultMessage
 */
export function extractErrorMessagesFromResponse(response: any, defaultMessage?: string) {
  let messages = '';
  let error;
  try {
    error = JSON.parse(response.error);
  } catch (e) {
    error = response.error;
  }
  const errorMessages = error?.errors;
  if (errorMessages && errorMessages.length > 0) {
    try {
      if(errorMessages.length === 1) {
        messages += '- ' + errorMessages[0];
      } else {
        errorMessages.forEach((item: any) => {
          messages += '- ' + item + '<br/>';
        });
      }
    } catch {
      messages += errorMessages;
    }
  } else if (defaultMessage) {
    messages = defaultMessage;
  }
  return messages;
}

/**
 *
 * @param link
 */
export function constructLink(link: Link): any {
  let url = '';
  if (link && link.target) {
    switch (link.target) {
      case MenuTargetType.COLLECTION:
        url = 'search?collection=' + link.value;
        break;
      case MenuTargetType.PRODUCT:
        url = 'product/' + link.value;
        break;
      case MenuTargetType.PAGE:
        url = link.value || '';
        break;
      case MenuTargetType.EXTERNAL:
        if (link.value && link.value.indexOf('https://') < 0) {
          url = url = 'https://' + link.value;
        } else {
          url = link.value || '';
        }
        break;
    }
  }
  return url;
}

export function getFieldValue(fields: GraphFieldValue[], fieldCode: string): any {
  return fields?.find(item => item.fieldCode === fieldCode)?.value;
}

export function constructMenuLink(menuItem: MenuItem): any {
  const link = new Link();
  if (menuItem) {
    link.target = menuItem.targetType;
    link.value = menuItem.targetValue;
  }
  return constructLink(link);
}
