import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {PixelService} from "@app/_shared/service/pixel.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private pixelService: PixelService,
              @Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId) && window.self === window.top) {
      this.pixelService.initPixels();
    }
  }


}
