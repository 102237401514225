import {Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ComponentLookup, getNeoFooterItemPerRow, Menu, MenuService, ShopyanFooterComponent} from '@app/_shared';
import {Router} from "@angular/router";
import {DomSanitizer, TransferState} from "@angular/platform-browser";
import {neoDefaultFooterMenu} from "./neo-default-footer-menu";

@Component({
  selector: 'lib-neo-footer',
  templateUrl: './neo-footer.component.html',
  styleUrls: ['./neo-footer.component.scss']
})
@ComponentLookup('neo-footer')
export class NeoFooterComponent extends ShopyanFooterComponent implements OnInit, OnDestroy {

  footerItemPerRow: number;

  constructor(router: Router,
              _sanitizer: DomSanitizer,
              menuService: MenuService,
              transferState: TransferState,
              @Inject(PLATFORM_ID) platformId: any) {
    super(router, _sanitizer, menuService, transferState, platformId);
  }

  ngOnInit(): void {
    this.init();
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let total = this.getFieldsGroupValue('footer-layout', 'columns-per-row') || 4;
    this.footerItemPerRow = getNeoFooterItemPerRow(total);
  }

  protected getDefaultMenus(): Menu[] {
    return neoDefaultFooterMenu;
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}



