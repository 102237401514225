<div [id]="data.code" class="neo-my-products shopyan-section animated tdFadeInUp"
     [class.build-mode]="buildMode"
     [style.background]="getFieldsGroupValue('products-layout','background')">
  <div class="neo-section-layout v-padding"
       [class.large]="getFieldsGroupValue('products-layout','layout-width') == 'large'">

    <lib-neo-section-header
      *ngIf="getFieldsGroup('products-header') && !getFieldsGroup('products-header').hidden"
      [data]="getFieldsGroup('products-header')"></lib-neo-section-header>

    <div class="result-area" #layout>
      <app-shopyan-placeholder *ngIf="loading"></app-shopyan-placeholder>
      <ng-container *ngIf="products && products.length > 0">
        <div class="products"
             [style.grid-template-columns]="'repeat('+productsPerRow+',minmax(0, 1fr))'">
          <ng-container *ngFor="let item of products">
            <lib-neo-product-card
              [storeId]="storeID"
              [product]="item"
              [fields]="getFieldsGroup('products-layout')?.values || []"
            ></lib-neo-product-card>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading && (!products || products.length == 0)">
        <lib-neo-no-result></lib-neo-no-result>
      </ng-container>
    </div>
  </div>
</div>
