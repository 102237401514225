import {Component, Input} from '@angular/core';
import {
  Cart,
  CartItem,
  Discount,
  GraphFieldValue,
  MediaSize,
  MediaType,
  Product,
  ShopyanCartHelperService,
  ShopyanToastrService,
  StorageService,
  urlMedia, VariationOptionType
} from "@app/_shared";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {ConversionService} from "@app/_shared/service/conversion.service";

@Component({
  template: ''
})
export abstract class ShopyanProductCardComponent {

  @Input()
  product: Product;

  @Input()
  fields: GraphFieldValue[];

  @Input()
  storeId: string;

  selectedVariation?: string = '';

  price: number;
  basicPrice: number;
  discounts?: Discount[];

  selectedOptions: string[] = [];

  variationOptionType = VariationOptionType;

  protected constructor(
    private translate: TranslateService,
    public basicHelperService: ShopyanCartHelperService,
    private storageService: StorageService,
    private router: Router,
    private basicToastr: ShopyanToastrService) {
  }

  init(): void {
    if (this.product.options) {
      this.selectedOptions = new Array(this.product.options.length).fill('');
    }
    if (this.product.variations?.length > 0) {
      const availableVariations = this.product.variations.filter(variation => !variation.disabled && (this.product.disableOutOfStock || variation.quantity > 0));
      if(!availableVariations || availableVariations.length === 0) {
        this.price = this.product.pricing.price;
        this.basicPrice = this.product.pricing.basicPrice;
      } else {
        let variation = availableVariations?.find(v => v.asDefault);
        if(!variation && availableVariations && availableVariations.length > 0) {
          variation = availableVariations?.reduce((minVariation, variation) => {
            return variation.price < minVariation.price ? variation : minVariation;
          });
        }
        if (variation) {
          this.selectedVariation = variation.id;
          this.price = variation.price;
          this.basicPrice = variation.basicPrice;
          this.discounts = variation.discounts;
          variation.optionValues.forEach((ov:string, index:number) => {
            this.selectedOptions[index] = ov;
          });
        }
      }
    } else if (this.product.pricing) {
      this.price = this.product.pricing.price;
      this.basicPrice = this.product.pricing.basicPrice;
    }
    this.discounts = this.discounts?.filter(discount => !discount.minimumRequirementQuantity || discount.minimumRequirementQuantity <= 1);
  }

  public getFieldValue(fieldCode: string): any {
    return this.fields?.find(item => item.fieldCode === fieldCode)?.value;
  }

  /**
   * Select option (tag mode)
   * @param optionValue
   * @param index
   */
  selectOption(optionValue: string, index: number): void {
    if (this.selectedOptions[index] === optionValue) {
      this.selectedOptions[index] = '';
    } else {
      this.selectedOptions[index] = optionValue;
    }

    let variation = this.product.variations
      .find(variation => !variation.optionValues.some((item, index) => this.selectedOptions[index] !== item));

    if (variation) {
      this.selectedVariation = variation.id;
      this.changeVariation();
    } else {
      this.selectedVariation = '';
    }
  }

  /**
   * Is option disabled/ Not available
   * @param optionValue
   * @param index
   */
  isOptionDisabled(optionValue: string, index: number): boolean {
    let selected = [...this.selectedOptions];
    selected[index] = optionValue;

    return !this.product.variations
      .filter(variation => !variation.disabled && (this.product.disableOutOfStock || variation.quantity > 0))
      .some(v => !v.optionValues.some((item, i) => selected[i] !== '' && selected[i] !== item));

  }

  /**
   * Get discount percent
   * @param product
   */
  getDiscountPercent(product: Product): number {
    if (product.pricing?.basicPrice > 0 && product.pricing?.price > 0) {
      return Math.floor(100 * (product.pricing.basicPrice - product.pricing.price) / product.pricing.basicPrice);
    }
    return 0;
  }

  changeVariation() {
    const variation = this.product.variations.find(v => v.id === this.selectedVariation);
    if (variation) {
      this.price = variation.price;
      this.basicPrice = variation.basicPrice;
      this.discounts = variation.discounts
      this.discounts = this.discounts?.filter(discount => !discount.minimumRequirementQuantity || discount.minimumRequirementQuantity <= 1);
    } else {
      this.price = this.product.pricing?.price;
      this.basicPrice = this.product.pricing?.basicPrice;
      delete this.discounts;
    }
  }

  isProductChecked(productId: string) {
    return this.basicHelperService.isProductWishListChecked(productId);
  }

  toggleToWishList(productId: string): void {
    this.basicHelperService.toggleToWishList(productId);
  }

  addToCart(): void {
    let variation = this.selectedVariation;
    if (variation || !this.product.variations || this.product.variations.length === 0) {
      this.basicHelperService.addItemToCart(this.product, 1, variation);
    } else {
      this.basicToastr.warn(this.translate.instant("PRODUCT.MESSAGE.OPTION"));
    }
  }

  urlProductMediumMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.PRODUCT, MediaSize.medium, media);
  }

  buyNow(): void {
    let variation: any = this.selectedVariation;
    if (variation || !this.product.variations || this.product.variations.length == 0) {
      let cartItem = new CartItem();
      cartItem.product = this.product;
      let price = cartItem.product.pricing?.price || 0;
      if (variation) {
        cartItem.variation = this.product.variations.find(v => v.id === variation);
        price = cartItem.variation?.price || 0;
      }
      cartItem.quantity = 1;
      cartItem.total = +price;

      let cart: Cart = new Cart();
      cart.items = [cartItem];
      cart.total = cartItem.total;

      this.storageService.saveData('express_cart', JSON.stringify(cart));
      this.router.navigateByUrl(`/checkout`).then();
    } else {
      this.basicToastr.warn(this.translate.instant("PRODUCT.MESSAGE.OPTION"));
    }
  }


  urlVariantSmallMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeId, MediaType.VARIANT, MediaSize.small, media);
  }

}

