export class Event {
  eventId?: string;
  productId?: string;
  eventType: EventTypeEnum;
  createdDate: Date;
}



export enum EventTypeEnum {
  VISIT_PRODUCT = 'VISIT_PRODUCT',
  CHECKOUT = 'CHECKOUT',
  THANK_YOU = 'THANK_YOU',
  ADD_CART = 'ADD_CART',
}



