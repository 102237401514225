import {Pipe, PipeTransform} from "@angular/core";
import {StorageService} from "@app/_shared";

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  constructor(private storageService: StorageService) {
  }

  transform(price: number, rateParam?: number, currencyParam?: string): string {

    if (price == null) {
      return '0';
    }

    let currency;
    if (currencyParam) {
      currency = currencyParam;
    } else {
      const currencyData = this.storageService.getData('cur');
      if (currencyData) {
        currency = JSON.parse(currencyData);
      } else {
        currency = '';
      }
    }

    let rate = 1;
    if (rateParam) {
      rate = rateParam;
    } else {
      const rateData = this.storageService.getData('rate');
      if (rateData) {
        rate = JSON.parse(rateData);
      }
    }

    const rounding = this.storageService.getData('rounding');
    if (rounding && JSON.parse(rounding)) {
      price = +(price * rate).toFixed(0) + 0.99
    } else {
      price = +(price * rate).toFixed(2)
    }

    return `${price} ${currency}`;
  }

}
