import {Component, OnInit} from '@angular/core';
import {ComponentLookup, ShopyanVideoComponent} from '@app/_shared';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'lib-neo-video',
  templateUrl: './neo-video.component.html',
  styleUrls: ['./neo-video.component.scss']
})
@ComponentLookup('neo-video')
export class NeoVideoComponent extends ShopyanVideoComponent implements OnInit {


  constructor(_sanitizer: DomSanitizer,
              private  sanitizer: DomSanitizer) {
    super(_sanitizer);
  }

  ngOnInit(): void {
    if(this.getFieldValue('url')) {
      const tmpURL = 'https://youtube.com/embed/' + super.getYoutubeVideoId(this.getFieldValue('url'));
      this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(tmpURL);
    }
  }

}

