<div class="product-card">

  <div class="product-image"
       [style.height]="getFieldValue('image-height')+'px'"
       [style.border-radius]="getFieldValue('border-radius')+'px'">
    <a href="/product/{{product.slug}}" class="product-link">product</a>
    <div class="product-discounts"
         *ngIf="(discounts && discounts.length > 0) || (product.discounts && product.discounts.length > 0)">
      <div class="discount" *ngIf="getFieldValue('hide-percent') != true && getDiscountPercent(product) > 0">
        <div class="ic"></div>
        <span>{{getDiscountPercent(product)}}%</span>
      </div>
      <ng-container *ngFor="let discount of (discounts || product.discounts); let index = index">
        <div class="discount">
          <div class="ic"></div>
          <span *ngIf="discount.value">{{discount.value | price}}</span>
          <span *ngIf="discount.percent">{{discount.percent}}%</span>
          <span class="discount-label">({{discount.label}})</span>
        </div>
      </ng-container>
    </div>
    <img alt="store" [src]="urlProductMediumMedia(product.medias[0])"
         *ngIf="product.medias && product.medias.length > 0; else mediaLink"/>
    <ng-template #mediaLink>
      <img alt="store" [src]="product.imageLinks[0]"
           *ngIf="product.imageLinks && product.imageLinks.length > 0; else noMedia"/>
      <ng-template #noMedia>
        <div class="default-bg"></div>
      </ng-template>
    </ng-template>

    <div class="product-actions">
      <div class="percent"
           *ngIf="getFieldValue('hide-percent') != true && getDiscountPercent(product) > 0
                  && (!discounts || discounts.length == 0) && (!product.discounts || product.discounts.length == 0)">
        -{{getDiscountPercent(product)}}%
      </div>
      <div class="wishlist" [class.checked]="isProductChecked(product.id)" (click)="toggleToWishList(product.id)"></div>
    </div>
  </div>
  <div class="product-details">
    <div class="rate neo-rate" *ngIf="getFieldValue('hide-rate') != true">
      <bar-rating [(rate)]="product.rate" [max]="5" [readOnly]="true"></bar-rating>
    </div>
    <div class="prices">
      <div class="sell-price" *ngIf="!price else priceArea"
           [class.with-shadow]="getFieldValue('price-with-shadow')">0
      </div>
      <ng-template #priceArea>
        <div
          class="sell-price"
          [class.with-shadow]="getFieldValue('price-with-shadow')"
          [style.color]="getFieldValue('price-color')"
          [style.font-size]="getFieldValue('price-size')+'px'">
          {{price - helperService.getDiscountValue(discounts || product.discounts, price) | price}}
        </div>
        <div class="init-price"
             [class.with-shadow]="getFieldValue('basic-with-shadow')"
             [style.color]="getFieldValue('basic-price-color')"
             [style.font-size]="getFieldValue('basic-price-size')+'px'"
             *ngIf="basicPrice > 0 ">{{basicPrice | price}}</div>
      </ng-template>
    </div>
    <a href="/product/{{product.slug}}" class="product-name"
       [style.color]="getFieldValue('product-label-color')"
       [style.font-size]="getFieldValue('product-label-size')+'px'">
      {{product.name}}
    </a>
  </div>
  <div class="product-btns" *ngIf="getFieldValue('hide-buttons') != true">
    <div class="product-options" [class.chips]="getFieldValue('variation-mode') != 'select'"
         *ngIf="product.variations && product.variations.length > 0">
      <ng-container *ngIf="getFieldValue('variation-mode') != 'select'; else selectVariation">
        <div class="option-area" *ngFor="let option of product.options; let index = index">
          <div class="oa-items">
            <ng-container *ngIf="option.type == variationOptionType.COLOR">
              <div class="option-color"
                   [class.disabled]="isOptionDisabled(optionValue.value, index)"
                   [class.checked]="selectedOptions[index] == optionValue.value"
                   (click)="isOptionDisabled(optionValue.value, index) ? $event.stopPropagation() : selectOption(optionValue.value, index)"
                   *ngFor="let optionValue of option.optionValues">
                <div class="color-content" [style.background-color]="optionValue.color"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="option.type == variationOptionType.IMAGE">
              <div class="option-image"
                   [class.disabled]="isOptionDisabled(optionValue.value, index)"
                   [class.checked]="selectedOptions[index] == optionValue.value"
                   (click)="isOptionDisabled(optionValue.value, index) ? $event.stopPropagation() : selectOption(optionValue.value, index)"
                   *ngFor="let optionValue of option.optionValues">
                <img *ngIf="optionValue.media" [src]="urlVariantSmallMedia(optionValue.media)"/>
                <img *ngIf="!optionValue.media" src="/assets/img/neo-picture.svg"/>
              </div>
            </ng-container>
            <ng-container *ngIf="!option.type || option.type == variationOptionType.TEXT">
              <div class="option-value"
                   [class.disabled]="isOptionDisabled(optionValue.value, index)"
                   [class.checked]="selectedOptions[index] == optionValue.value"
                   (click)="isOptionDisabled(optionValue.value, index) ? $event.stopPropagation() : selectOption(optionValue.value, index)"
                   *ngFor="let optionValue of option.optionValues">{{optionValue.value}}</div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-template #selectVariation>
        <mat-form-field appearance="outline">
          <mat-label>{{'PRODUCT.CHOOSE_OPTION' | translate}}</mat-label>
          <mat-select [(ngModel)]="selectedVariation">
            <ng-container *ngFor="let variation of product.variations">
              <mat-option [value]="variation.id"
                          [disabled]="variation.disabled || (!product.disableOutOfStock && (!variation.quantity || variation.quantity <= 0))">
                <span>{{variation.optionValues.join(' - ') + ' - '}} {{variation.price - helperService.getDiscountValue(variation.discounts, variation.price) | price}}</span>
                <span
                  *ngIf="!product.disableOutOfStock && (!variation.quantity || variation.quantity <= 0)">{{'PRODUCT.OUT_OF_STOCK_TITLE' | translate}}</span>
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ng-template>
    </div>
    <ng-container
      *ngIf="(product.variations && product.variations.length > 0) || product.stock > 0 || product.disableOutOfStock">
      <div class="neo-btn reverse add-to-cart" *addToCart (click)="addToCart()">
        <span class="ic"></span>
        <span>{{'PRODUCT.ADD_TO_CART' | translate}}</span>
      </div>
      <div class="buy-options">
        <div class="neo-btn buy-option buy-now" (click)="buyNow()">
          <span class="ic"></span>
          <span>{{'PRODUCT.BUY_NOW' | translate}}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
