import {Component, OnInit} from '@angular/core';
import {ComponentLookup, GraphBlock} from '@app/_shared';
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";

@Component({
  selector: 'lib-neo-collapsible-content',
  templateUrl: './neo-collapsible-content.component.html',
  styleUrls: ['./neo-collapsible-content.component.scss']
})

@ComponentLookup('neo-collapsible-content')
export class NeoCollapsibleContentComponent extends ShopyanSectionComponent implements OnInit {

  openedBlocks: GraphBlock[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {

  }

  clickBlock(graphBlock: GraphBlock): void {
    if (this.openedBlocks.indexOf(graphBlock) >= 0) {
      this.openedBlocks.splice(this.openedBlocks.indexOf(graphBlock), 1);
    } else {
      this.openedBlocks.push(graphBlock);
    }
  }

  isBlockOpened(graphBlock: GraphBlock): boolean {
    return this.openedBlocks.indexOf(graphBlock) >= 0;
  }

}
