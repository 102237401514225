import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class MetaDataService {

  constructor(private meta: Meta, private title: Title) {
  }

  private urlMeta: string = "og:url";
  private titleMeta: string = "og:title";
  private descriptionMeta: string = "og:description";
  private imageMeta: string = "og:image";

  /**
   * Update meta data
   * @param url
   * @param title
   * @param description
   * @param imageUrl
   */
  updateMeta(url: string, title: string, description: string, imageUrl: string): void {

    this.meta.updateTag({name: this.urlMeta, content: url});
    this.meta.updateTag({name: this.titleMeta, content: title});
    this.meta.updateTag({name: this.descriptionMeta, content: description});
    this.meta.updateTag({name: this.imageMeta, content: imageUrl});
    this.meta.updateTag({name: "description", content: description});
    this.meta.updateTag({name: "type", content: "website"});
    this.setTitle(title)
  }

  /**
   * Set the title of the current HTML document
   * @param newTitle
   */
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

}
